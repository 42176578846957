import { APARTMENT_DETAIL } from './apartment-detail.types';

export const getApartment = ({ idBuilding, idApartment, refreshState }) => ({
  type: APARTMENT_DETAIL.GET.REQUEST,
  payload: { idBuilding, idApartment, refreshState },
});

export const refreshApartmentDetailPage = () => ({
  type: APARTMENT_DETAIL.REFRESH,
});

export const getResidents = ({ idBuilding, idApartment, refreshState }) => ({
  type: APARTMENT_DETAIL.RESIDENT.GETS.REQUEST,
  payload: { idBuilding, idApartment, refreshState },
});

export const addResident = ({ idBuilding, idApartment, resident, refreshState }) => ({
  type: APARTMENT_DETAIL.RESIDENT.ADD.REQUEST,
  payload: { idBuilding, idApartment, resident, refreshState },
});

export const updateResident = ({ idBuilding, idResident, resident, refreshState }) => ({
  type: APARTMENT_DETAIL.RESIDENT.UPDATE.REQUEST,
  payload: { idBuilding, idResident, resident, refreshState },
});

export const removeResident = ({ idBuilding, idResident, refreshState }) => ({
  type: APARTMENT_DETAIL.RESIDENT.REMOVE.REQUEST,
  payload: { idBuilding, idResident, refreshState },
});

export const getDocuments = ({ idBuilding, idApartment, refreshState }) => ({
  type: APARTMENT_DETAIL.DOCUMENT.GETS.REQUEST,
  payload: { idBuilding, idApartment, refreshState },
});

export const addDocument = ({ idBuilding, document, refreshState }) => ({
  type: APARTMENT_DETAIL.DOCUMENT.ADD.REQUEST,
  payload: { idBuilding, document, refreshState },
});

export const removeDocument = ({ idBuilding, idDocument, refreshState }) => ({
  type: APARTMENT_DETAIL.DOCUMENT.REMOVE.REQUEST,
  payload: { idBuilding, idDocument, refreshState },
});

export const getServices = ({ idBuilding, idApartment, filter }) => ({
  type: APARTMENT_DETAIL.SERVICE.REQUEST,
  payload: {
    idBuilding,
    idApartment,
    filter,
  },
});

export const getTransactions = ({ idBuilding, idApartment, filter }) => ({
  type: APARTMENT_DETAIL.TRANSACTION.REQUEST,
  payload: {
    idBuilding,
    idApartment,
    filter,
  },
});
