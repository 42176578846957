import isString from 'lodash/isString';
import { SERVICE_FORMULA_TYPE_ACCUMULATED, SERVICE_FORMULA_TYPE_NUMBER_USE, SERVICE_FORMULA_TYPE_PERCENT } from '../../constants/service';

export default function calculateSubTotal({ totalNumberUse = 0, formulaType = '', formula: formulaInp = '', ratio = 1, norm = 1 }) {
  let formula = formulaInp;

  if (!formula) {
    throw new Error('Formula is empty.');
  }

  if (!formulaType) {
    throw new Error('Formula type not empty.');
  }

  if (isString(formula)) {
    formula = JSON.parse(formula);
  }

  let totalNumberUseTmp = totalNumberUse;
  let result = 0;
  switch (formulaType) {
    case SERVICE_FORMULA_TYPE_NUMBER_USE:
      result = totalNumberUse * formula.price;
      result *= ratio;
      break;
    case SERVICE_FORMULA_TYPE_ACCUMULATED:
      formula.forEach((item, index) => {
        if (index < formula.length - 1) {
          let basicRate;
          if (index === 0) {
            basicRate = Number(item.to);
          } else {
            basicRate = Number(item.to) - Number(item.from) + 1;
          }
          const customerRate = Math.round(basicRate * ratio) * norm;
          let quantity = 0;
          if (totalNumberUseTmp > customerRate) {
            quantity = customerRate;
          } else {
            quantity = totalNumberUseTmp;
          }
          totalNumberUseTmp -= quantity;
          result += quantity * item.price;
        } else if (totalNumberUseTmp >= 0) {
          result += totalNumberUseTmp * item.price;
        }
      });
      break;
    case SERVICE_FORMULA_TYPE_PERCENT:
      formula.forEach((item) => {
        result += Math.round(Number((totalNumberUse * item.percent) / 100).toFixed(2) * item.price);
      });
      break;
    default:
      break;
  }
  return result;
}
