/* eslint-disable no-continue */
import compareArray from './compareArray';
import isObject from './isObject';

const compareObject = (object1, object2) => {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);
  if (keys1.length !== keys2.length) {
    return false;
  }

  // eslint-disable-next-line no-restricted-syntax
  for (const key of keys1) {
    const value1 = object1[key];
    const value2 = object2[key];

    const areArrays = Array.isArray(value1) && Array.isArray(value2);
    if (areArrays) {
      const isCompare = compareArray(value1, value2);
      if (!isCompare) {
        return false;
      }
      continue;
    }

    const areObjects = isObject(value1) && isObject(value2);
    if (areObjects) {
      const isCompare = compareObject(value1, value2);
      if (!isCompare) {
        return false;
      }
      continue;
    }

    if (value1 !== value2) {
      return false;
    }
  }
  return true;
};

export default compareObject;
