import { call, put, all, takeLatest } from 'redux-saga/effects';
import {
  removeResidentService,
  getResidentsService,
  exportResidentService,
  activeAccountResidentService,
  inactiveAccountResidentService,
  importResidentService,
} from 'services/resident';
import { RESIDENT_STATUS_ACTIVE, RESIDENT_STATUS_ALL, RESIDENT_STATUS_DISABLED } from 'shared/constants';
import { RESIDENT_CHANGE_STATUS, RESIDENT_DELETE, RESIDENT_EXPORT, RESIDENT_GETS, RESIDENT_IMPORT } from './resident.types';

function* getResidents(action) {
  const { idBuilding, page, limit, filter, refreshState } = action.payload;
  try {
    const response = yield call(getResidentsService, {
      idBuilding,
      page,
      limit,
      filter: {
        fullName: filter.name ? filter.name.trim() : undefined,
        position: filter.role ? filter.role : undefined,
        phone: filter.phone ? filter.phone.trim() : undefined,
        secondaryPhone: filter.secondaryPhone ? filter.secondaryPhone.trim() : undefined,
        email: filter.email ? filter.email.trim() : undefined,
        secondaryEmail: filter.secondaryEmail ? filter.secondaryEmail.trim() : undefined,
        status: filter.status === RESIDENT_STATUS_ALL ? undefined : filter.status,
        gender: filter.gender ? filter.gender : undefined,
        age: filter.age ? filter.age : undefined,
        monthOfBirth: filter.monthOfBirth ? filter.monthOfBirth : undefined,
        unitId: filter.apartment ? filter.apartment : undefined,
      },
    });
    refreshState();
    yield put({
      type: RESIDENT_GETS.SUCCESS,
      payload: response,
    });
  } catch (error) {
    refreshState();
    yield put({
      type: RESIDENT_GETS.FAIL,
      payload: error,
    });
  }
}

function* changeStatusResident(action) {
  const { idResident, idBuilding, status, refreshState } = action.payload;
  try {
    let response;
    if (status === RESIDENT_STATUS_ACTIVE) {
      response = yield call(activeAccountResidentService, { idResident, idBuilding });
    } else if (status === RESIDENT_STATUS_DISABLED) {
      response = yield call(inactiveAccountResidentService, { idResident, idBuilding });
    }
    refreshState({
      code: response.code,
    });
    yield put({
      type: RESIDENT_CHANGE_STATUS.SUCCESS,
      payload: response,
    });
  } catch (error) {
    refreshState({
      code: error.code,
      codeLanguage: error.codeLanguage,
    });
    yield put({
      type: RESIDENT_CHANGE_STATUS.FAIL,
      payload: error,
    });
  }
}

function* importResidents(action) {
  const { idBuilding, file, refreshState } = action.payload;
  try {
    const response = yield call(importResidentService, { idBuilding, file });
    refreshState({
      code: response.code,
    });
    yield put({
      type: RESIDENT_IMPORT.SUCCESS,
      payload: response,
    });
  } catch (error) {
    refreshState({
      code: error.code,
      codeLanguage: error.codeLanguage,
    });
    yield put({
      type: RESIDENT_EXPORT.FAIL,
      payload: error,
    });
  }
}

function* exportResident(action) {
  const { idBuilding, filter, refreshState } = action.payload;
  try {
    const response = yield call(exportResidentService, {
      idBuilding,
      filter: {
        unitName: filter.apartment ? filter.apartment.trim() : undefined,
        fullName: filter.name ? filter.name.trim() : undefined,
        position: filter.role === 0 ? undefined : filter.role,
        phone: filter.phone ? filter.phone.trim() : undefined,
        secondaryPhone: filter.secondaryPhone ? filter.secondaryPhone.trim() : undefined,
        email: filter.email ? filter.email.trim() : undefined,
        secondaryEmail: filter.secondaryEmail ? filter.secondaryEmail.trim() : undefined,
        status: filter.status === RESIDENT_STATUS_ALL ? undefined : filter.status,
        gender: filter.gender === 0 ? undefined : filter.gender,
        age: filter.age === 0 ? undefined : filter.age,
        monthOfBirth: filter.monthOfBirth === -1 ? undefined : filter.monthOfBirth,
        customerIdentifier: filter.customerIdentifier ? filter.customerIdentifier.trim() : undefined,
      },
    });
    refreshState({
      code: response.code,
    });
    yield put({
      type: RESIDENT_EXPORT.SUCCESS,
      payload: response,
    });
    yield put({
      type: RESIDENT_EXPORT.REFRESH,
    });
  } catch (error) {
    refreshState({
      code: error.code,
      codeLanguage: error.codeLanguage,
    });
    yield put({
      type: RESIDENT_EXPORT.FAIL,
      payload: error,
    });
  }
}

function* deleteResident(action) {
  const { isAll, idResidents, filter, idBuilding, refreshState } = action.payload;
  try {
    const response = yield call(removeResidentService, { isAll, idResidents, filter, idBuilding });
    refreshState({
      code: response.code,
    });
    yield put({
      type: RESIDENT_DELETE.SUCCESS,
      payload: response,
    });
  } catch (error) {
    refreshState({
      code: error.code,
      codeLanguage: error.codeLanguage,
    });
    yield put({
      type: RESIDENT_DELETE.FAIL,
      payload: error,
    });
  }
}

export default function* watch() {
  yield all([
    takeLatest(RESIDENT_DELETE.REQUEST, deleteResident),
    takeLatest(RESIDENT_GETS.REQUEST, getResidents),
    takeLatest(RESIDENT_EXPORT.REQUEST, exportResident),
    takeLatest(RESIDENT_IMPORT.REQUEST, importResidents),
    takeLatest(RESIDENT_CHANGE_STATUS.REQUEST, changeStatusResident),
  ]);
}
