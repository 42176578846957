const mergeArr = (...args) => {
  let resultArr = [];
  for (let i = 0; i < args.length; i += 1) {
    if (i === 0) {
      resultArr = resultArr.concat(args[i]);
    } else {
      const resultArrCopy = [].concat(resultArr);
      resultArr = resultArr.concat(args[i].filter((arg) => !resultArrCopy.includes(arg)));
    }
  }
  return resultArr;
};

export default mergeArr;
