import { useContext } from 'react';
import { getFundService } from 'services/fund';
// eslint-disable-next-line import/no-cycle
import { Context as GlobalContext } from 'globalContext';
import { CASH, ONLINE, TRANSFER } from '../constants/payment-account';

const useFund = () => {
  const { funds, setFunds } = useContext(GlobalContext);

  const getFunds = async (idBuilding) => {
    const response = await getFundService({ idBuilding });
    setFunds(response.data);
  };

  const getFundsByMethod = (method) => {
    switch (method) {
      case CASH: {
        return funds.filter((fund) => fund.method === CASH);
      }
      case ONLINE: {
        return funds.filter((fund) => fund.method === ONLINE);
      }
      case TRANSFER: {
        return funds.filter((fund) => fund.method === TRANSFER);
      }
      default: {
        return [];
      }
    }
  };

  const getFundById = (id) => {
    return funds.find((fund) => fund.id === id);
  };

  const fundData = funds;

  const fundsTransfer = getFundsByMethod[TRANSFER];

  return { getFunds, fundData, getFundsByMethod, getFundById, fundsTransfer, setFunds };
};

export default useFund;
