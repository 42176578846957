import { createAction } from 'shared/utils';

export const CONFIG_APARTMENT_PAGE_REFRESH = 'CONFIG_APARTMENT_PAGE_REFRESH';
export const CONFIG_APARTMENT_APARTMENT_TYPE_GETS = createAction('CONFIG_APARTMENT_APARTMENT_TYPE_GETS');
export const CONFIG_APARTMENT_SUBDIVISION_GETS = createAction('CONFIG_APARTMENT_SUBDIVISION_GETS');
export const CONFIG_APARTMENT_BLOCK_GETS = createAction('CONFIG_APARTMENT_BLOCK_GETS');
export const CONFIG_APARTMENT_FLOOR_GETS = createAction('CONFIG_APARTMENT_FLOOR_GETS');
export const CONFIG_APARTMENT_GETS = createAction('CONFIG_APARTMENT_GETS');
export const CONFIG_APARTMENT_ADD = createAction('CONFIG_APARTMENT_ADD');
export const CONFIG_APARTMENT_UPDATE = createAction('CONFIG_APARTMENT_UPDATE');
export const CONFIG_APARTMENT_DELETE = createAction('CONFIG_APARTMENT_DELETE');
export const CONFIG_APARTMENT_DELETE_MULTI = createAction('CONFIG_APARTMENT_DELETE_MULTI');
export const CONFIG_APARTMENT_IMPORT = createAction('CONFIG_APARTMENT_IMPORT');
export const CONFIG_APARTMENT_EXPORT = createAction('CONFIG_APARTMENT_EXPORT');
