import moment from 'moment';
import { language } from 'shared/utils';

export const CONSUMPTION_DEFAULT_FILTER = {
  feeNotificationPeriod: moment(),
  closingPeriod: moment(),
  unitName: undefined,
  contractTemplateIds: [],
  customerIdentifier: undefined,
};

export const ACTION_FILL_FROM_WEB = 'FILL_FROM_WEB';
export const ACTION_UPLOAD_FROM_EXCEL = 'UPLOAD_FROM_EXCEL';

export const ACTION_CREATE_CONSUMPTION = [
  {
    label: language.fill_from_web,
    key: ACTION_FILL_FROM_WEB,
  },
  {
    label: language.upload_from_excel,
    key: ACTION_UPLOAD_FROM_EXCEL,
  },
];
