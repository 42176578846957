import { combineReducers } from 'redux';
import { AMENITIES } from './amenities.types';

export const initState = {
  get: {
    isLoading: false,
    code: undefined,
    codeLanguage: undefined,
    data: undefined,
  },
  getPriorityInAppSelected: {
    isLoading: false,
    code: undefined,
    codeLanguage: undefined,
    data: undefined,
  },
  gets: {
    isLoading: false,
    code: undefined,
    codeLanguage: undefined,
    totalRecord: 0,
    data: [],
  },
  add: {
    isLoading: false,
    code: undefined,
    codeLanguage: undefined,
  },
  update: {
    isLoading: false,
    code: undefined,
    codeLanguage: undefined,
  },
};

const reducer = combineReducers({
  get: (state = initState.get, action) => {
    switch (action.type) {
      case AMENITIES.GET.REQUEST:
        return {
          ...state,
          code: undefined,
          codeLanguage: undefined,
          isLoading: true,
        };
      case AMENITIES.GET.SUCCESS:
        return {
          ...state,
          code: action.payload.code,
          codeLanguage: action.payload.codeLanguage,
          isLoading: false,
          data: action.payload.data,
        };
      case AMENITIES.GET.FAIL:
        return {
          ...state,
          code: action.payload.code,
          codeLanguage: action.payload.codeLanguage,
          isLoading: false,
          data: undefined,
        };
      case AMENITIES.GET.REFRESH:
        return {
          ...initState.get,
        };
      default:
        return state;
    }
  },
  getPriorityInAppSelected: (state = initState.getPriorityInAppSelected, action) => {
    switch (action.type) {
      case AMENITIES.GET_PRIORITY_IN_APP_SELECTED.REQUEST:
        return {
          ...state,
          code: undefined,
          codeLanguage: undefined,
          isLoading: true,
        };
      case AMENITIES.GET_PRIORITY_IN_APP_SELECTED.SUCCESS:
        return {
          ...state,
          code: action.payload.code,
          codeLanguage: action.payload.codeLanguage,
          isLoading: false,
          data: action.payload.data,
        };
      case AMENITIES.GET_PRIORITY_IN_APP_SELECTED.FAIL:
        return {
          ...state,
          code: action.payload.code,
          codeLanguage: action.payload.codeLanguage,
          isLoading: false,
          data: undefined,
        };
      case AMENITIES.GET_PRIORITY_IN_APP_SELECTED.REFRESH:
        return {
          ...initState.getPriorityInAppSelected,
        };
      default:
        return state;
    }
  },
  gets: (state = initState.gets, action) => {
    switch (action.type) {
      case AMENITIES.GETS.REQUEST:
        return {
          ...state,
          code: undefined,
          codeLanguage: undefined,
          isLoading: true,
        };
      case AMENITIES.GETS.SUCCESS:
        return {
          ...state,
          code: action.payload.code,
          codeLanguage: action.payload.codeLanguage,
          isLoading: false,
          data: action.payload.data,
          totalRecord: action.payload.totalRecord,
        };
      case AMENITIES.GETS.FAIL:
        return {
          ...state,
          code: action.payload.code,
          codeLanguage: action.payload.codeLanguage,
          isLoading: false,
          data: [],
          totalRecord: 0,
        };
      case AMENITIES.GETS.REFRESH:
        return {
          ...initState.gets,
        };
      default:
        return state;
    }
  },
  add: (state = initState.add, action) => {
    switch (action.type) {
      case AMENITIES.ADD.REQUEST:
        return {
          ...state,
          code: undefined,
          codeLanguage: undefined,
          isLoading: true,
        };
      case AMENITIES.ADD.SUCCESS:
        return {
          ...state,
          code: action.payload.code,
          codeLanguage: action.payload.codeLanguage,
          isLoading: false,
        };
      case AMENITIES.ADD.FAIL:
        return {
          ...state,
          code: action.payload.code,
          codeLanguage: action.payload.codeLanguage,
          isLoading: false,
        };
      case AMENITIES.ADD.REFRESH:
        return {
          ...initState.add,
        };
      default:
        return state;
    }
  },
  update: (state = initState.update, action) => {
    switch (action.type) {
      case AMENITIES.UPDATE.REQUEST:
        return {
          ...state,
          code: undefined,
          codeLanguage: undefined,
          isLoading: true,
        };
      case AMENITIES.UPDATE.SUCCESS:
        return {
          ...state,
          code: action.payload.code,
          codeLanguage: action.payload.codeLanguage,
          isLoading: false,
        };
      case AMENITIES.UPDATE.FAIL:
        return {
          ...state,
          code: action.payload.code,
          codeLanguage: action.payload.codeLanguage,
          isLoading: false,
        };
      case AMENITIES.UPDATE.REFRESH:
        return {
          ...initState.update,
        };
      default:
        return state;
    }
  },
});

export default reducer;
