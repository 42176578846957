import { all } from 'redux-saga/effects';
import { watch as watchApartmentDetail } from 'containers/Apartment/Detail/ducks';
import { watch as watchResidentForm } from 'containers/Resident/ResidentForm/ducks';
import { watch as watchResident } from 'containers/Resident/ducks';
import { watch as watchConfigSubdivision } from 'containers/Config/ConfigBuilding/Subdivision/ducks';
import { watch as watchConfigBlock } from 'containers/Config/ConfigBuilding/Block/ducks';
import { watch as watchConfigFloor } from 'containers/Config/ConfigBuilding/FloorV2/ducks';
import { watch as watchConfigApartment } from 'containers/Config/ConfigBuilding/ApartmentV2/ducks';
import { watch as watchConfigService } from 'containers/Config/ConfigService/ducks';
import { watch as watchConfigAmenities } from 'containers/Config/ConfigAmenities/ducks';
import { watch as watchAmenitiesOrder } from 'containers/AmenitiesOrder/ducks';
import { watch as watchAmenitiesOrderDetail } from 'containers/AmenitiesOrder/AmenitiesDetail/ducks';
import { watch as watchAmenitiesOrderConfirmChangeStatus } from 'containers/AmenitiesOrder/AmenitiesOrderConfirmChangeStatus/ducks';

export default function* watch() {
  yield all([
    watchApartmentDetail(),
    watchResidentForm(),
    watchResident(),
    watchConfigSubdivision(),
    watchConfigBlock(),
    watchConfigFloor(),
    watchConfigApartment(),
    watchConfigService(),
    watchConfigAmenities(),
    watchAmenitiesOrder(),
    watchAmenitiesOrderDetail(),
    watchAmenitiesOrderConfirmChangeStatus(),
  ]);
}
