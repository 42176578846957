import {
  CONFIG_SUBDIVISION_ADD,
  CONFIG_SUBDIVISION_DELETE,
  CONFIG_SUBDIVISION_GETS,
  CONFIG_SUBDIVISION_PAGE_REFRESH,
  CONFIG_SUBDIVISION_UPDATE,
} from './subdivision.types';

export const getSubdivisions = ({ idBuilding, filter, refreshState }) => ({
  type: CONFIG_SUBDIVISION_GETS.REQUEST,
  payload: {
    idBuilding,
    filter,
    refreshState,
  },
});

export const addSubdivision = ({ idBuilding, subdivision, refreshState }) => ({
  type: CONFIG_SUBDIVISION_ADD.REQUEST,
  payload: {
    idBuilding,
    subdivision,
    refreshState,
  },
});

export const updateSubdivision = ({ idBuilding, idSubdivision, subdivision, refreshState }) => ({
  type: CONFIG_SUBDIVISION_UPDATE.REQUEST,
  payload: {
    idBuilding,
    idSubdivision,
    subdivision,
    refreshState,
  },
});

export const deleteSubdivision = ({ idBuilding, idSubdivision, refreshState }) => ({
  type: CONFIG_SUBDIVISION_DELETE.REQUEST,
  payload: {
    idBuilding,
    idSubdivision,
    refreshState,
  },
});

export const refreshSubdivisionPage = () => ({
  type: CONFIG_SUBDIVISION_PAGE_REFRESH,
});
