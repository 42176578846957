const getBase64 = (img, callback) => {
  const reader = new FileReader();
  const promise = new Promise((resolve, reject) => {
    reader.addEventListener('load', () => resolve(reader.result));
  });
  reader.readAsDataURL(img);
  return promise;
};

export default getBase64;
