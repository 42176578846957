const resizeImage = (url, size) => {
  if (!url) {
    return null;
  }
  if (url && size) {
    // const arr = url.split('/');
    // return `${arr[0]}//${arr[2]}/${size}/${arr[3]}`;
    const newUrl = new URL(url);
    if (newUrl.hostname === 'static.cyhome.vn') {
      return `${newUrl.origin}/${size}${newUrl.pathname}`;
    }
  }

  return url;
};

export default resizeImage;
