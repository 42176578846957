import {
  CONFIG_BLOCK_ADD,
  CONFIG_BLOCK_DELETE,
  CONFIG_BLOCK_GETS,
  CONFIG_BLOCK_PAGE_REFRESH,
  CONFIG_BLOCK_UPDATE,
  CONFIG_BLOCK_SUBDIVISION_GETS,
} from './block.types';

export const getSubdivisions = ({ idBuilding }) => ({
  type: CONFIG_BLOCK_SUBDIVISION_GETS.REQUEST,
  payload: {
    idBuilding,
  },
});

export const getBlocks = ({ idBuilding, filter, refreshState }) => ({
  type: CONFIG_BLOCK_GETS.REQUEST,
  payload: {
    idBuilding,
    filter,
    refreshState,
  },
});

export const addBlock = ({ idBuilding, block, refreshState }) => ({
  type: CONFIG_BLOCK_ADD.REQUEST,
  payload: {
    idBuilding,
    block,
    refreshState,
  },
});

export const updateBlock = ({ idBuilding, idBlock, block, refreshState }) => ({
  type: CONFIG_BLOCK_UPDATE.REQUEST,
  payload: {
    idBuilding,
    idBlock,
    block,
    refreshState,
  },
});

export const deleteBlock = ({ idBuilding, idBlock, refreshState }) => ({
  type: CONFIG_BLOCK_DELETE.REQUEST,
  payload: {
    idBuilding,
    idBlock,
    refreshState,
  },
});

export const refreshBlockPage = () => ({
  type: CONFIG_BLOCK_PAGE_REFRESH,
});
