import { createAction } from 'shared/utils';

export const RESIDENT_FORM_REFRESH = 'RESIDENT_FORM_REFRESH';
export const RESIDENT_FORM_GET_UNITS = createAction('RESIDENT_FORM_GET_UNITS');
export const RESIDENT_FORM_ADD = createAction('RESIDENT_FORM_ADD');
export const RESIDENT_FORM_UPDATE = createAction('RESIDENT_FORM_UPDATE');
export const RESIDENT_FORM_GET_ENTITY = createAction('RESIDENT_FORM_GET_ENTITY');
export const RESIDENT_FORM_GET_COUNTRY_CODE = createAction('RESIDENT_FORM_GET_COUNTRY_CODE');
export const RESIDENT_DELETE = createAction('RESIDENT_DELETE');

export const RESET_RESIDENT_FORM_GET_ENTITY = 'RESET_RESIDENT_FORM_GET_ENTITY';
