import { concat } from 'lodash';
import { language } from './language';

export default function combineOptionAll(options, label = 'name', value = 'id') {
  const ALL = {
    [label]: `----- ${language.label.all} -----`,
    [value]: '',
  };
  return concat([ALL], options);
}
