import _ from 'lodash';

import templateDataForImport from 'shared/constants/templateDataForImport';
import { getCacheLanguage } from './language';

const TEMPLATE_DATA_SIZE = 3;

const generateTemplateData = (headers) => {
  const generatedData = [];
  const cacheLanguage = getCacheLanguage();

  const templateDataSize = TEMPLATE_DATA_SIZE;

  for (let i = 0; i < templateDataSize; i += 1) {
    const rowData = {};

    headers.forEach((header) => {
      const { key, rules } = header;
      const { type } = rules;
      const languageData = templateDataForImport?.[key]?.[cacheLanguage];

      switch (type) {
        case 'enum': {
          const enumValues = rules?.enum;
          rowData[key] = enumValues && enumValues?.length ? _.sample(enumValues) : '';
          break;
        }
        case 'date':
        case 'string':
        case 'email':
        case 'number': {
          rowData[key] = languageData && languageData?.length ? _.sample(languageData) : '';
          break;
        }
        default: {
          rowData[key] = '';
          break;
        }
      }
    });

    generatedData.push({ ...rowData, index: i + 1 });
  }

  return generatedData;
};

export default generateTemplateData;
