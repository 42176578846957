import { SERVER, METHOD, API_TYPE_CALL_API, X_UNIT_GROUP_ID } from 'shared/constants';
import api from './api';

const DOCUMENT = `${SERVER}/document`;
const DOCUMENT_GET_LIST = `${DOCUMENT}/list`;

export const getDocumentsService = async (filter, idBuilding) => {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: DOCUMENT_GET_LIST,
      method: METHOD.GET,
      options: {
        params: {
          ...filter,
        },
        headers: {
          [X_UNIT_GROUP_ID]: idBuilding,
        },
      },
    },
  });
  return response;
};

export const addDocumentService = async (document, idBuilding) => {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: DOCUMENT,
      body: document,
      method: METHOD.POST,
      options: {
        headers: {
          [X_UNIT_GROUP_ID]: idBuilding,
        },
      },
    },
  });
  return response;
};

export const updateDocumentService = async (idDocument, document, idBuilding) => {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: `${DOCUMENT}/${idDocument}`,
      body: document,
      method: METHOD.PUT,
      options: {
        headers: {
          [X_UNIT_GROUP_ID]: idBuilding,
        },
      },
    },
  });
  return response;
};

export const removeDocumentService = async (idDocument, idBuilding) => {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: `${DOCUMENT}/${idDocument}`,
      method: METHOD.DELETE,
      options: {
        headers: {
          [X_UNIT_GROUP_ID]: idBuilding,
        },
      },
    },
  });
  return response;
};
