import { put, call, all, takeLatest } from 'redux-saga/effects';
import { addFloorService, getFloorsService, updateFloorService, deleteFloorService } from 'services/floor';
import { getSubdivisionsService } from 'services/subdivision';
import { getBlocksService } from 'services/block';
import {
  CONFIG_FLOOR_ADD,
  CONFIG_FLOOR_DELETE,
  CONFIG_FLOOR_GETS,
  CONFIG_FLOOR_UPDATE,
  CONFIG_FLOOR_SUBDIVISION_GETS,
  CONFIG_FLOOR_BLOCK_GETS,
} from './floor.types';

function* getSubdivisions(action) {
  const { idBuilding } = action.payload;
  try {
    const response = yield call(getSubdivisionsService, {
      idBuilding,
    });
    yield put({
      type: CONFIG_FLOOR_SUBDIVISION_GETS.SUCCESS,
      payload: response,
    });
  } catch (error) {
    yield put({
      type: CONFIG_FLOOR_SUBDIVISION_GETS.FAIL,
      payload: error,
    });
  }
}

function* getBlocks(action) {
  const { idBuilding } = action.payload;
  try {
    const response = yield call(getBlocksService, {
      idBuilding,
    });
    yield put({
      type: CONFIG_FLOOR_BLOCK_GETS.SUCCESS,
      payload: response,
    });
  } catch (error) {
    yield put({
      type: CONFIG_FLOOR_BLOCK_GETS.FAIL,
      payload: error,
    });
  }
}

function* getFloors(action) {
  const { idBuilding, filter, page, limit, refreshState } = action.payload;
  try {
    const response = yield call(getFloorsService, {
      idBuilding,
      filter,
      page,
      limit,
    });
    yield put({
      type: CONFIG_FLOOR_GETS.SUCCESS,
      payload: response,
    });
    refreshState();
  } catch (error) {
    yield put({
      type: CONFIG_FLOOR_GETS.FAIL,
      payload: error,
    });
    refreshState();
  }
}

function* addFloor(action) {
  const { idBuilding, floor, refreshState } = action.payload;
  try {
    const response = yield call(addFloorService, {
      idBuilding,
      floor,
    });
    yield put({
      type: CONFIG_FLOOR_ADD.SUCCESS,
      payload: response,
    });
    refreshState({
      code: response.code,
      codeLanguage: response.codeLanguage,
    });
  } catch (error) {
    yield put({
      type: CONFIG_FLOOR_ADD.FAIL,
      payload: error,
    });
    refreshState({
      code: error.code,
      codeLanguage: error.codeLanguage,
    });
  }
}

function* updateFloor(action) {
  const { idBuilding, idFloor, floor, refreshState } = action.payload;
  try {
    const response = yield call(updateFloorService, {
      idBuilding,
      idFloor,
      floor,
    });
    yield put({
      type: CONFIG_FLOOR_UPDATE.SUCCESS,
      payload: response,
    });
    refreshState({
      code: response.code,
      codeLanguage: response.codeLanguage,
    });
  } catch (error) {
    yield put({
      type: CONFIG_FLOOR_UPDATE.FAIL,
      payload: error,
    });
    refreshState({
      code: error.code,
      codeLanguage: error.codeLanguage,
    });
  }
}

function* deleteFloor(action) {
  const { idBuilding, idFloor, refreshState } = action.payload;
  try {
    const response = yield call(deleteFloorService, {
      idBuilding,
      idFloor,
    });
    yield put({
      type: CONFIG_FLOOR_DELETE.SUCCESS,
      payload: response,
    });
    refreshState({
      code: response.code,
      codeLanguage: response.codeLanguage,
    });
  } catch (error) {
    yield put({
      type: CONFIG_FLOOR_DELETE.FAIL,
      payload: error,
    });
    refreshState({
      code: error.code,
      codeLanguage: error.codeLanguage,
    });
  }
}

export default function* watch() {
  yield all([
    takeLatest(CONFIG_FLOOR_GETS.REQUEST, getFloors),
    takeLatest(CONFIG_FLOOR_SUBDIVISION_GETS.REQUEST, getSubdivisions),
    takeLatest(CONFIG_FLOOR_BLOCK_GETS.REQUEST, getBlocks),
    takeLatest(CONFIG_FLOOR_ADD.REQUEST, addFloor),
    takeLatest(CONFIG_FLOOR_UPDATE.REQUEST, updateFloor),
    takeLatest(CONFIG_FLOOR_DELETE.REQUEST, deleteFloor),
  ]);
}
