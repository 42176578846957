import { language } from '../utils/language';
import convertArrToObj from '../utils/convertArrToObj';

export const HOUSEHOLDER = 'HOUSEHOLDER';
export const ACCOUNTANT = 'ACCOUNTANT';
export const RENTER = 'RENTER';
export const MEMBER = 'MEMBER';
export const EMPOWER = 'EMPOWER';

export const RESIDENT_STATUS_ALL = 'ALL';
export const RESIDENT_STATUS_ACTIVE = 'ACTIVE';
export const RESIDENT_STATUS_DISABLED = 'DISABLED';

export const RESIDENT_ROLES = [
  { id: HOUSEHOLDER, name: language.admin },
  { id: ACCOUNTANT, name: language.accountant },
  // { id: RENTER, name: language.label.renter },
  { id: MEMBER, name: language.label.member },
  { id: EMPOWER, name: language.empower },
];

export const RESIDENT_ROLES_BY_NAME = RESIDENT_ROLES.map((role) => role.name);

export const RESIDENT_ROLE_BY_ID = convertArrToObj(RESIDENT_ROLES, 'id');

export const AGE_RANGE_OPTIONS = [
  {
    id: '1,6',
    name: language.label.from_1_to_6,
  },
  {
    id: '6,10',
    name: language.label.from_6_to_10,
  },
  {
    id: '10,20',
    name: language.label.from_10_to_20,
  },
  {
    id: '20,60',
    name: language.label.from_20_to_60,
  },
  {
    id: '60,1000',
    name: language.label.greater_than_60,
  },
];

export const RESIDENT_STATUS_OPTIONS = [
  {
    id: RESIDENT_STATUS_ALL,
    name: language.all,
  },
  {
    id: RESIDENT_STATUS_ACTIVE,
    name: language.active,
  },
  {
    id: RESIDENT_STATUS_DISABLED,
    name: language.deleted,
  },
];

export const RESIDENT_DEFAULT_FILTER = {
  role: '',
  status: RESIDENT_STATUS_ACTIVE,
  name: '',
  apartment: '',
  phone: '',
  secondaryPhone: '',
  email: '',
  secondaryEmail: '',
  gender: '',
  age: '',
  // monthOfBirth: '',
};
