import { API_TYPE_CALL_API, METHOD, SERVER, X_UNIT_GROUP_ID } from 'shared/constants';
import api from './api';

const LABEL = `${SERVER}/label`;
const LABEL_GETS = `${LABEL}/list`;
const LABEL_ADD = LABEL;
const LABEL_UPDATE = LABEL;
const LABEL_DELETE_LIST = `${LABEL}/delete`;
const LABEL_GROUP_LIST = `${LABEL}/group-list`;
const LABEL_TREE_LIST = `${LABEL}/list-tree`;

export const getLabelsService = async (idBuilding, filter = {}) => {
  const headers = {
    [X_UNIT_GROUP_ID]: idBuilding,
  };
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: LABEL_GETS,
      method: METHOD.GET,
      options: {
        headers,
        params: {
          ...filter,
        },
      },
    },
  });
  return response;
};

export const addLabelService = async (idBuilding, label) => {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: LABEL_ADD,
      body: label,
      method: METHOD.POST,
      options: {
        headers: {
          [X_UNIT_GROUP_ID]: idBuilding,
        },
      },
    },
  });
  return response;
};

export const updateLabelService = async (idBuilding, idLabel, label) => {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: `${LABEL_UPDATE}/${idLabel}`,
      body: label,
      method: METHOD.PUT,
      options: {
        headers: {
          [X_UNIT_GROUP_ID]: idBuilding,
        },
      },
    },
  });
  return response;
};

export const deleteLabelService = async (idBuilding, idLabel, isGroup) => {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: LABEL_DELETE_LIST,
      body: {
        labelId: idLabel,
        isGroup,
      },
      method: METHOD.POST,
      options: {
        headers: {
          [X_UNIT_GROUP_ID]: idBuilding,
        },
      },
    },
  });
  return response;
};

export const getLabelGroupsService = async (idBuilding) => {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: LABEL_GROUP_LIST,
      method: METHOD.GET,
      options: {
        headers: {
          [X_UNIT_GROUP_ID]: idBuilding,
        },
      },
    },
  });
  return response;
};

export const getLabelTreeService = async (idBuilding, filter) => {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: LABEL_TREE_LIST,
      method: METHOD.GET,
      options: {
        headers: {
          [X_UNIT_GROUP_ID]: idBuilding,
        },
        params: {
          ...filter,
        },
      },
    },
  });
  return response;
};
