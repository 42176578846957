import themeCyHome from '../styles/cyhome';
import themeLavida from '../styles/lavida';
import themeCyOffice from '../styles/cyoffice';
import themeVanPhuc from '../styles/vanphuc';
import themeBimGroup from '../styles/bimgroup';

const WHITE_LABEL_CONFIG = {
  CYHOME: {
    document: {
      mau_thong_tin_can_ho: '/documents/cyhome/mau-thong-tin-can-ho.xlsx',
      mau_thong_tin_cu_dan: '/documents/cyhome/mau-thong-tin-cu-dan.xlsx',
      mau_thong_tin_doi_tac: '/documents/cyhome/mau-thong-tin-doi-tac.xlsx',
      mau_thong_tin_nhan_vien: '/documents/cyhome/mau-thong-tin-nhan-vien.xlsx',
      mau_thong_tin_the: '/documents/cyhome/mau-thong-tin-the.xlsx',
      mau_thong_tin_trang_thiet_bi: '/documents/cyhome/mau-thong-tin-trang-thiet-bi.xlsx',
      mau_thong_tin_vat_tu: '/documents/cyhome/mau-thong-tin-vat-tu.xlsx',
      mau_thong_tin_phi_dich_vu: '/documents/cyhome/mau-thong-tin-phi-dich-vu.xlsx',
    },
    logo: '/assets/images/cyhome/logo.png',
    background: '/assets/images/cyhome/background.png',
    notification: '/assets/images/cyhome/notification.png',
    theme: themeCyHome,
    key: 'CYHOME',
  },
  CYOFFICE: {
    document: {
      mau_thong_tin_can_ho: '/documents/cyoffice/mau-thong-tin-can-ho.xlsx',
      mau_thong_tin_cu_dan: '/documents/cyoffice/mau-thong-tin-cu-dan.xlsx',
      mau_thong_tin_doi_tac: '/documents/cyoffice/mau-thong-tin-doi-tac.xlsx',
      mau_thong_tin_nhan_vien: '/documents/cyoffice/mau-thong-tin-nhan-vien.xlsx',
      mau_thong_tin_the: '/documents/cyoffice/mau-thong-tin-the.xlsx',
      mau_thong_tin_trang_thiet_bi: '/documents/cyoffice/mau-thong-tin-trang-thiet-bi.xlsx',
      mau_thong_tin_vat_tu: '/documents/cyoffice/mau-thong-tin-vat-tu.xlsx',
      mau_thong_tin_tang_vi_tri_thue: '/documents/cyoffice/mau-thong-tin-tang-vi-tri-thue.xlsx',
      mau_thong_tin_khoi_tang_vi_tri_thue: '/documents/cyoffice/mau-thong-tin-khoi-tang-vi-tri-thue.xlsx',
      mau_thong_tin_thanh_vien: '/documents/cyoffice/mau-thong-tin-thanh-vien.xlsx',
      mau_thong_tin_phi_dich_vu: '/documents/cyoffice/mau-thong-tin-phi-dich-vu.xlsx',
      mau_thong_tin_khu_vuc_thue: '/documents/cyoffice/mau-thong-tin-khu-vuc-thue.xlsx',
      mau_thong_tin_don_vi_thue: '/documents/cyoffice/mau-thong-tin-don-vi-thue.xlsx',
      mau_thong_tin_tang: '/documents/cyoffice/mau-thong-tin-tang.xlsx',
      mau_thong_tin_khoi_tang: '/documents/cyoffice/mau-thong-tin-khoi-tang.xlsx',
      mau_thong_tin_quan_li_phuong_tien: '/documents/cyoffice/mau-thong-tin-quan-li-phuong-tien.xlsx',
      mau_thong_tin_quan_li_phuong_tien_update: '/documents/cyoffice/mau-thong-tin-quan-li-phuong-tien-update.xlsx',
      mau_thong_tin_tai_khoan_don_vi_thue: '/documents/cyoffice/mau-thong-tin-tai-khoan-don-vi-thue.xlsx',
      mau_thong_tin_thu_truoc_dich_vu: '/documents/cyoffice/mau-thong-tin-thu-truoc-dich-vu.xlsx',
      mau_thong_tin_thiet_bi: '/documents/cyoffice/mau-thong-tin-thiet-bi.xlsx',
    },
    logo: '/assets/images/cyoffice/logo.png',
    background: '/assets/images/cyoffice/background.png',
    notification: '/assets/images/cyoffice/notification.png',
    theme: themeCyOffice,
    key: 'CYOFFICE',
  },
  BIMGROUP: {
    document: {
      mau_thong_tin_can_ho: '/documents/bimgroup/mau-thong-tin-can-ho.xlsx',
      mau_thong_tin_cu_dan: '/documents/bimgroup/mau-thong-tin-cu-dan.xlsx',
      mau_thong_tin_doi_tac: '/documents/bimgroup/mau-thong-tin-doi-tac.xlsx',
      mau_thong_tin_nhan_vien: '/documents/bimgroup/mau-thong-tin-nhan-vien.xlsx',
      mau_thong_tin_the: '/documents/bimgroup/mau-thong-tin-the.xlsx',
      mau_thong_tin_trang_thiet_bi: '/documents/bimgroup/mau-thong-tin-trang-thiet-bi.xlsx',
      mau_thong_tin_vat_tu: '/documents/bimgroup/mau-thong-tin-vat-tu.xlsx',
      mau_thong_tin_phi_dich_vu: '/documents/bimgroup/mau-thong-tin-phi-dich-vu.xlsx',
    },
    logo: '/assets/images/bimgroup/logo.png',
    background: '/assets/images/bimgroup/background.png',
    notification: '/assets/images/bimgroup/notification.png',
    theme: themeBimGroup,
    key: 'BIMGROUP',
  },
  LAVIDA: {
    document: {
      mau_thong_tin_can_ho: '/documents/lavida/mau-thong-tin-can-ho.xlsx',
      mau_thong_tin_cu_dan: '/documents/lavida/mau-thong-tin-cu-dan.xlsx',
      mau_thong_tin_doi_tac: '/documents/lavida/mau-thong-tin-doi-tac.xlsx',
      mau_thong_tin_nhan_vien: '/documents/lavida/mau-thong-tin-nhan-vien.xlsx',
      mau_thong_tin_the: '/documents/lavida/mau-thong-tin-the.xlsx',
      mau_thong_tin_trang_thiet_bi: '/documents/lavida/mau-thong-tin-trang-thiet-bi.xlsx',
      mau_thong_tin_vat_tu: '/documents/lavida/mau-thong-tin-vat-tu.xlsx',
      mau_thong_tin_phi_dich_vu: '/documents/lavida/mau-thong-tin-phi-dich-vu.xlsx',
    },
    logo: '/assets/images/lavida/logo.png',
    background: '/assets/images/lavida/background.png',
    notification: '/assets/images/lavida/notification.png',
    theme: themeLavida,
    key: 'LAVIDA',
  },
  VANPHUC: {
    document: {
      mau_thong_tin_can_ho: '/documents/vanphuc/mau-thong-tin-can-ho.xlsx',
      mau_thong_tin_cu_dan: '/documents/vanphuc/mau-thong-tin-cu-dan.xlsx',
      mau_thong_tin_doi_tac: '/documents/vanphuc/mau-thong-tin-doi-tac.xlsx',
      mau_thong_tin_nhan_vien: '/documents/vanphuc/mau-thong-tin-nhan-vien.xlsx',
      mau_thong_tin_the: '/documents/vanphuc/mau-thong-tin-the.xlsx',
      mau_thong_tin_trang_thiet_bi: '/documents/vanphuc/mau-thong-tin-trang-thiet-bi.xlsx',
      mau_thong_tin_vat_tu: '/documents/vanphuc/mau-thong-tin-vat-tu.xlsx',
      mau_thong_tin_phi_dich_vu: '/documents/vanphuc/mau-thong-tin-phi-dich-vu.xlsx',
    },
    logo: '/assets/images/vanphuc/logo.png',
    background: '/assets/images/vanphuc/background.png',
    notification: '/assets/images/vanphuc/notification.png',
    theme: themeVanPhuc,
    key: 'VANPHUC',
  },
};

const { WHITE_LABEL = 'CYHOME' } = process.env;

export const whiteLabelConfig = WHITE_LABEL_CONFIG[WHITE_LABEL] || WHITE_LABEL_CONFIG.CYHOME;
