export const theme = {
  defaultColor: '#fff',
  primaryColor: '#0530AD',
  primarySecondColor: '#0530AD',
  warningColor: '#0530AD',
  errorColor: '#0530AD',
  blackColor: '#000',
  linkColor: '#0530AD',
  backgroundHeader: '#fff',
  colorHeader: '#000',
  colorSidebar: '#fff',
  borderColor: '#e2e7ef',
  labelColor: '#7F8D9E',
  fontWeightNormal: '400',
  fontWeightMedium: '500',
  fontWeightBold: '600',
  paddingXss: '4px',
  paddingXs: '8px',
  paddingSm: '12px',
  padding: '16px',
  paddingLg: '24px',
  marginXss: '4px',
  marginXs: '8px',
  marginSm: '12px',
  margin: '16px',
  marginLg: '24px',
  paddingLayoutVertical: '28px',
  paddingLayoutHorizontal: '24px',
  borderRadius: '4px',
  fontSizeLargeIcon: '19px',
  fontSizeTitle: '24px',
  fontSizeLarge: '16px',
  fontSizeMedium: '14px',
  fontSizeSmall: '12px',
};

const useTheme = () => {
  return [theme];
};

export default useTheme;
