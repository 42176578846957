import { getAnalytics, logEvent } from 'firebase/analytics';
import { useLocation, useParams } from 'react-router-dom';
import { GA_MAPPING } from 'shared/constants';

const useGA = () => {
  const analytics = getAnalytics();
  const location = useLocation();
  const { idBuilding: buildingId } = useParams();

  const getGAKey = () => {
    const { pathname } = location;
    let screenName = '';
    if (pathname) {
      const gaMapping = Object.keys(GA_MAPPING);
      for (let index = 0; index < gaMapping.length; index += 1) {
        const match = pathname.match(new RegExp(gaMapping[index]));
        if (match) {
          screenName = GA_MAPPING[gaMapping[index]];
          break;
        }
      }
    }
    return screenName;
  };

  const trackingEvent = (params) => {
    const gaKey = getGAKey();
    if (gaKey) {
      logEvent(analytics, getGAKey(), {
        ...params,
        category: gaKey,
        buildingId,
      });
    }
  };

  return trackingEvent;
};

export default useGA;
