import { useMemo, useContext } from 'react';
import moment from 'moment';
// eslint-disable-next-line import/no-cycle
import { Context as GlobalContext } from 'globalContext';

const PERIOD_NOTICE_REMIND_DEBT = 2;

const useCurrentBuilding = () => {
  const { currentBuilding, setCurrentBuilding } = useContext(GlobalContext);

  const handleChangeCurrentBuilding = (building) => {
    setCurrentBuilding(building);
  };

  const isRemindDebt = useMemo(() => {
    const dateOfDebtInvoiceSending = currentBuilding?.dateOfDebtInvoiceSending ?? 0;
    if (dateOfDebtInvoiceSending) {
      const dateSendInvoiceRemindDebt = dateOfDebtInvoiceSending;
      const diff = dateSendInvoiceRemindDebt - moment().date();
      return diff <= PERIOD_NOTICE_REMIND_DEBT;
    }
    return false;
  }, [currentBuilding]);

  return { currentBuilding, handleChangeCurrentBuilding, isRemindDebt };
};

export default useCurrentBuilding;
