import moment from 'moment';

export default function convertPeriodTimeToMonths(period, isUTC = false) {
  let { from, to } = period;
  [from, to] = [
    Number(isUTC ? moment.utc(from).format('YYYYMM') : moment(from).format('YYYYMM')),
    Number(isUTC ? moment.utc(to).format('YYYYMM') : moment(to).format('YYYYMM')),
  ];
  const months = [];
  while (to >= from) {
    months.push(from);
    from = Number(moment(from, 'YYYYMM').add(1, 'month').format('YYYYMM'));
  }

  return months;
}
