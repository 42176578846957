import Oidc from 'oidc-client';

const config = {
  authority: process.env.OIDC_AUTHORITY,
  client_id: process.env.OIDC_CLIENT_ID,
  redirect_uri: process.env.OIDC_REDIRECT_URI,
  response_type: process.env.OIDC_RESPONSE_TYPE,
  scope: process.env.OIDC_SCOPE,
  post_logout_redirect_uri: process.env.OIDC_POST_LOGOUT_REDIRECT_URI,
  grant_type: process.env.OIDC_GRANT_TYPE,
};

const userManager = new Oidc.UserManager(config);

export default userManager;
