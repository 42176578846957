import { useEffect } from 'react';
import oidc from '../config/oidc';

const useOidc = () => {
  const login = () => {
    oidc.signinRedirect();
  };

  const logout = () => {
    oidc.signoutRedirect();
  };

  useEffect(async () => {
    const user = await oidc.getUser();
    if (!user || !user.access_token) {
      login();
    } else {
      window.location.assign('/');
    }
  }, []);

  return {
    login,
    logout,
  };
};

export default useOidc;
