import { SERVICE_PROMOTION_TYPE_PERCENT } from '../../constants/service';

export default function calculatePromotions(promotions = [], totalPayment) {
  let totalDiscount = 0;
  if (Array.isArray(promotions)) {
    promotions.forEach((item) => {
      switch (item.type) {
        case SERVICE_PROMOTION_TYPE_PERCENT: {
          totalDiscount += (totalPayment * item.price) / 100;
          break;
        }
        default:
          break;
      }
    });
  }
  return Math.round(totalDiscount);
}
