import { RESIDENT_STATUS_DISABLED } from 'shared/constants';
import {
  RESIDENT_FORM_GET_UNITS,
  RESIDENT_FORM_GET_ENTITY,
  RESIDENT_FORM_GET_COUNTRY_CODE,
  RESIDENT_DELETE,
  RESIDENT_FORM_REFRESH,
} from './residentForm.types';

export const initState = {
  units: [],
  info: undefined,
  countryCodes: [],
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case RESIDENT_FORM_REFRESH: {
      return {
        ...initState,
      };
    }
    case RESIDENT_FORM_GET_UNITS.SUCCESS: {
      return {
        ...state,
        units: action.payload.data,
      };
    }
    case RESIDENT_FORM_GET_ENTITY.SUCCESS: {
      return {
        ...state,
        info: action.payload.data
          ? {
              ...action.payload.data,
            }
          : undefined,
      };
    }
    case RESIDENT_FORM_GET_ENTITY.REFRESH: {
      return {
        ...state,
        info: undefined,
      };
    }
    case RESIDENT_FORM_GET_COUNTRY_CODE.SUCCESS: {
      return {
        ...state,
        countryCodes: action.payload.data,
      };
    }
    case RESIDENT_DELETE.SUCCESS: {
      return {
        ...state,
        info: state.info
          ? {
              ...state.info,
              status: RESIDENT_STATUS_DISABLED,
            }
          : state.info,
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
