import { language } from '../utils/language';

export const LANGUAGE_OPTIONS = [
  {
    label: language.vietnamese,
    key: 'vi',
  },
  {
    label: language.english,
    key: 'en',
  },
  // {
  //   label: language.japanese,
  //   key: 'JP',
  // },
];
