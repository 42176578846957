import { API_TYPE_CALL_API, METHOD, SERVER, Status, X_UNIT_GROUP_ID } from 'shared/constants';
import api from './api';

export const ICONS = `${SERVER}/base/icons`;
export const SERVICE = `${SERVER}/service`;
export const SERVICE_ADD = SERVICE;
export const SERVICE_UPDATE = (idService) => `${SERVICE}/${idService}`;
export const SERVICE_DELETE = (idService) => `${SERVICE}/${idService}`;
export const SERVICE_GETS = `${SERVICE}/list`;
export const SERVICE_IN_USE_GETS = `${SERVICE}/list-in-use`;
export const SERVICE_REGISTER_IN_USE_GETS = `${SERVICE}/register/list-in-use`;
export const SERVICE_UPDATE_STATUS = (idService) => `${SERVICE}/updateStatus/${idService}`;

export async function getServicesService({ filter, idBuilding }) {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: SERVICE_GETS,
      method: METHOD.GET,
      options: {
        params: {
          ...filter,
        },
        headers: {
          [X_UNIT_GROUP_ID]: idBuilding,
        },
      },
    },
  });
  return response;
}

export async function getServiceInUsesService({ idBuilding, filter }) {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: SERVICE_IN_USE_GETS,
      method: METHOD.GET,
      options: {
        params: {
          ...filter,
        },
        headers: {
          [X_UNIT_GROUP_ID]: idBuilding,
        },
      },
    },
  });
  return response;
}

export async function getServiceRegisterInUsesService({ idBuilding, filter }) {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: SERVICE_REGISTER_IN_USE_GETS,
      method: METHOD.GET,
      options: {
        params: {
          ...filter,
        },
        headers: {
          [X_UNIT_GROUP_ID]: idBuilding,
        },
      },
    },
  });
  return response;
}

export const getIcons = async () => {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: ICONS,
      method: METHOD.GET,
    },
  });
  return response;
};

export const addServiceService = async ({ idBuilding, service }) => {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: SERVICE_ADD,
      method: METHOD.POST,
      body: service,
      options: {
        headers: {
          [X_UNIT_GROUP_ID]: idBuilding,
        },
      },
    },
  });
  return response;
};

export const updateServiceService = async ({ idBuilding, idService, service }) => {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: SERVICE_UPDATE(idService),
      method: METHOD.PUT,
      body: service,
      options: {
        headers: {
          [X_UNIT_GROUP_ID]: idBuilding,
        },
      },
    },
  });
  return response;
};

export const deleteServiceService = async ({ idBuilding, idService }) => {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: SERVICE_DELETE(idService),
      method: METHOD.DELETE,
      options: {
        headers: {
          [X_UNIT_GROUP_ID]: idBuilding,
        },
      },
    },
  });
  return response;
};

const updateServiceStatusService = async ({ idService, idBuilding, status }) => {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: SERVICE_UPDATE_STATUS(idService),
      body: {
        status,
      },
      method: METHOD.PUT,
      options: {
        headers: {
          [X_UNIT_GROUP_ID]: idBuilding,
        },
      },
    },
  });
  return response;
};

export const inactiveServiceService = async ({ idBuilding, idService }) => {
  return updateServiceStatusService({ idService, idBuilding, status: Status.INACTIVE });
};

export const activeServiceService = async ({ idBuilding, idService }) => {
  return updateServiceStatusService({ idService, idBuilding, status: Status.ACTIVE });
};
