import { createAction } from 'shared/utils';

export const APARTMENT_DETAIL = {
  GET: createAction('APARTMENT_DETAIL_GET'),
  RESIDENT: {
    GETS: createAction('APARTMENT_DETAIL_RESIDENT_GETS'),
    ADD: createAction('APARTMENT_DETAIL_RESIDENT_ADD'),
    UPDATE: createAction('APARTMENT_DETAIL_RESIDENT_UPDATE'),
    REMOVE: createAction('APARTMENT_DETAIL_RESIDENT_REMOVE'),
  },
  DOCUMENT: {
    GETS: createAction('APARTMENT_DETAIL_DOCUMENT_GETS'),
    ADD: createAction('APARTMENT_DETAIL_DOCUMENT_ADD'),
    REMOVE: createAction('APARTMENT_DETAIL_DOCUMENT_REMOVE'),
  },
  SERVICE: {
    GETS: createAction('APARTMENT_DETAIL_SERVICE_GETS'),
  },
  TRANSACTION: {
    GETS: createAction('APARTMENT_DETAIL_TRANSACTION_GETS'),
  },
  REFRESH: 'REFRESH',
};
