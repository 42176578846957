import { language } from '../utils/language';

export const PAYMENT_ONLINE_STATUS_SUCCESSED = 'SUCCESSED';
export const PAYMENT_ONLINE_STATUS_IN_PROCESS = 'IN_PROCESS';
export const PAYMENT_ONLINE_STATUS_CANCELLED = 'CANCELLED';

export const PAYMENT_ONLINE_STATUS_ARRAY = [
  { id: PAYMENT_ONLINE_STATUS_SUCCESSED, value: language.success },
  { id: PAYMENT_ONLINE_STATUS_IN_PROCESS, value: language.in_progress },
  { id: PAYMENT_ONLINE_STATUS_CANCELLED, value: language.canceled },
];

export const PAYMENT_ONLINE_STATUS_MAPPING = {
  [PAYMENT_ONLINE_STATUS_SUCCESSED]: language.success,
  [PAYMENT_ONLINE_STATUS_IN_PROCESS]: language.in_progress,
  [PAYMENT_ONLINE_STATUS_CANCELLED]: language.canceled,
};

export const PAYMENT_ONLINE_METHOD_VNPAY_GW = 'VNPAY_GW';
export const PAYMENT_ONLINE_METHOD_VNPAY_QR = 'VNPAY_QR';
export const PAYMENT_ONLINE_METHOD_MOMO_IN_APP = 'MOMO_IN_APP';
export const PAYMENT_ONLINE_METHOD_CYHOME_MB_APP = 'CYHOME_MB_APP';

export const PAYMENT_ONLINE_METHOD_ARRAY = [
  { id: PAYMENT_ONLINE_METHOD_MOMO_IN_APP, value: language.momo_in_app },
  { id: PAYMENT_ONLINE_METHOD_CYHOME_MB_APP, value: language.cyhome_mb_app },
  { id: PAYMENT_ONLINE_METHOD_VNPAY_GW, value: language.vnpay_gw },
  { id: PAYMENT_ONLINE_METHOD_VNPAY_QR, value: language.vnpay_qr },
];

export const PAYMENT_ONLINE_METHOD_MAPPING = {
  [PAYMENT_ONLINE_METHOD_VNPAY_GW]: language.vnpay_gw,
  [PAYMENT_ONLINE_METHOD_VNPAY_QR]: language.vnpay_qr,
  [PAYMENT_ONLINE_METHOD_MOMO_IN_APP]: language.momo_in_app,
  [PAYMENT_ONLINE_METHOD_CYHOME_MB_APP]: language.cyhome_mb_app,
};
