import {
  RESIDENT_FORM_GET_ENTITY,
  RESIDENT_FORM_ADD,
  RESIDENT_FORM_GET_UNITS,
  RESIDENT_FORM_UPDATE,
  RESIDENT_FORM_GET_COUNTRY_CODE,
  RESIDENT_FORM_REFRESH,
} from './residentForm.types';

export const addResidentAction = ({ idBuilding, resident, refreshState }) => ({
  type: RESIDENT_FORM_ADD.REQUEST,
  payload: {
    idBuilding,
    resident,
    refreshState,
  },
});

export const updateResidentAction = ({ idBuilding, idResident, resident, refreshState }) => ({
  type: RESIDENT_FORM_UPDATE.REQUEST,
  payload: {
    idBuilding,
    idResident,
    resident,
    refreshState,
  },
});

export const refreshResidentFormPage = () => ({
  type: RESIDENT_FORM_REFRESH,
});

export const searchApartmentInFormResident = ({ idBuilding, filter, refreshState }) => ({
  type: RESIDENT_FORM_GET_UNITS.REQUEST,
  payload: {
    idBuilding,
    filter,
    refreshState,
  },
});

export const getResident = ({ idBuilding, idResident, refreshState }) => ({
  type: RESIDENT_FORM_GET_ENTITY.REQUEST,
  payload: {
    idBuilding,
    idResident,
    refreshState,
  },
});

export const getCountryCodeAction = ({ refreshState }) => ({
  type: RESIDENT_FORM_GET_COUNTRY_CODE.REQUEST,
  payload: {
    refreshState,
  },
});
