import { API_TYPE_CALL_API, API_TYPE_DOWNLOAD_FILE, METHOD, SERVER, X_UNIT_GROUP_ID } from 'shared/constants';
import api from './api';

const REQUEST = `${SERVER}/request`;
const REQUEST_GETS = `${REQUEST}/list`;
const REQUEST_EXPORT = `${REQUEST}/export-data`;
const REQUEST_GET = (requestId) => `${REQUEST}/${requestId}`;
const REQUEST_ADD = REQUEST;
const REQUEST_UPDATE = (requestId) => `${REQUEST}/${requestId}`;
const REQUEST_UPDATE_PROGRESS = (requestId, status) => `${REQUEST}/${requestId}/change-progress/${status}`;
const REQUEST_DUPLICATE = `${REQUEST}/duplicate`;
const REQUEST_TOTAL_REQUEST_EXPIRED_GETS = `${REQUEST}/count-badges`;
const REQUEST_ACTIVITY_LOG = (idRequest) => `${REQUEST}/${idRequest}/activities`;
const REQUEST_TIMELINE = (idRequest) => `${REQUEST}/${idRequest}/timeline`;

export async function getRequestsService({ idBuilding, filter, page, limit }) {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: REQUEST_GETS,
      method: METHOD.GET,
      options: {
        headers: {
          [X_UNIT_GROUP_ID]: idBuilding,
        },
        params: {
          ...filter,
          page,
          limit,
        },
      },
    },
  });
  return response;
}

export async function getRequestDetailService(idBuilding, idRequest) {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: REQUEST_GET(idRequest),
      method: METHOD.GET,
      options: {
        headers: {
          [X_UNIT_GROUP_ID]: idBuilding,
        },
      },
    },
  });
  return response;
}

export async function addRequestsService(idBuilding, request) {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: REQUEST_ADD,
      method: METHOD.POST,
      body: {
        ...request,
      },
      options: {
        headers: {
          [X_UNIT_GROUP_ID]: idBuilding,
        },
      },
    },
  });
  return response;
}

export async function updateRequestService(idBuilding, idRequest, request) {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: REQUEST_UPDATE(idRequest),
      method: METHOD.PUT,
      body: {
        ...request,
      },
      options: {
        headers: {
          [X_UNIT_GROUP_ID]: idBuilding,
        },
      },
    },
  });
  return response;
}

export async function changeProgressRequestService({ idBuilding, idRequest, status, note }) {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: REQUEST_UPDATE_PROGRESS(idRequest, status),
      method: METHOD.PUT,
      body: {
        note,
      },
      options: {
        headers: {
          [X_UNIT_GROUP_ID]: idBuilding,
        },
      },
    },
  });
  return response;
}

export async function duplicateRequestService(idBuilding, request) {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: REQUEST_DUPLICATE,
      method: METHOD.POST,
      body: {
        ...request,
      },
      options: {
        headers: {
          [X_UNIT_GROUP_ID]: idBuilding,
        },
      },
    },
  });
  return response;
}

export async function exportDataRequestService(idBuilding, filter) {
  const response = await api({
    type: API_TYPE_DOWNLOAD_FILE,
    payload: {
      apiUrl: REQUEST_EXPORT,
      method: METHOD.GET,
      options: {
        headers: {
          [X_UNIT_GROUP_ID]: idBuilding,
        },
        params: {
          ...filter,
        },
      },
    },
  });
  return response;
}

export async function getTotalRequestExpiredService() {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: REQUEST_TOTAL_REQUEST_EXPIRED_GETS,
      method: METHOD.GET,
    },
  });
  return response;
}

export async function getActivitiesLogRequestService(idBuilding, idRequest) {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: REQUEST_ACTIVITY_LOG(idRequest),
      method: METHOD.GET,
      options: {
        headers: {
          [X_UNIT_GROUP_ID]: idBuilding,
        },
      },
    },
  });
  return response;
}

export async function getTimelineService(idBuilding, idRequest) {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: REQUEST_TIMELINE(idRequest),
      method: METHOD.GET,
      options: {
        headers: {
          [X_UNIT_GROUP_ID]: idBuilding,
        },
      },
    },
  });
  return response;
}
