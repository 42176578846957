import { LOGIN, LOGOUT, PROFILE_UPDATE } from './application.types';

export const initState = {
  user: undefined,
};

export default (state = initState, action) => {
  switch (action.type) {
    case LOGIN.SUCCESS: {
      return {
        ...state,
        user: {
          ...action.payload.data.user,
          accessToken: action.payload.data.token,
        },
      };
    }
    case LOGOUT.SUCCESS: {
      return {
        ...initState,
      };
    }
    case PROFILE_UPDATE.SUCCESS: {
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload.data,
        },
      };
    }
    default:
      return state;
  }
};
