import threeDigits from './threeDigits';

function formatNumberWithPrecision(value = '') {
  let convertValue = value.toString();
  if (convertValue) {
    if (convertValue.indexOf('.0000') !== -1) {
      convertValue = Math.trunc(convertValue);
    }
    return threeDigits(convertValue);
  }
  return convertValue;
}

export default formatNumberWithPrecision;
