import {
  CONFIG_FLOOR_ADD,
  CONFIG_FLOOR_DELETE,
  CONFIG_FLOOR_GETS,
  CONFIG_FLOOR_PAGE_REFRESH,
  CONFIG_FLOOR_UPDATE,
  CONFIG_FLOOR_SUBDIVISION_GETS,
  CONFIG_FLOOR_BLOCK_GETS,
} from './floor.types';

export const getSubdivisions = ({ idBuilding }) => ({
  type: CONFIG_FLOOR_SUBDIVISION_GETS.REQUEST,
  payload: {
    idBuilding,
  },
});

export const getBlocks = ({ idBuilding }) => ({
  type: CONFIG_FLOOR_BLOCK_GETS.REQUEST,
  payload: {
    idBuilding,
  },
});

export const getFloors = ({ idBuilding, filter, page, limit, refreshState }) => ({
  type: CONFIG_FLOOR_GETS.REQUEST,
  payload: {
    idBuilding,
    filter,
    page,
    limit,
    refreshState,
  },
});

export const addFloor = ({ idBuilding, floor, refreshState }) => ({
  type: CONFIG_FLOOR_ADD.REQUEST,
  payload: {
    idBuilding,
    floor,
    refreshState,
  },
});

export const updateFloor = ({ idBuilding, idFloor, floor, refreshState }) => ({
  type: CONFIG_FLOOR_UPDATE.REQUEST,
  payload: {
    idBuilding,
    idFloor,
    floor,
    refreshState,
  },
});

export const deleteFloor = ({ idBuilding, idFloor, refreshState }) => ({
  type: CONFIG_FLOOR_DELETE.REQUEST,
  payload: {
    idBuilding,
    idFloor,
    refreshState,
  },
});

export const refreshFloorPage = () => ({
  type: CONFIG_FLOOR_PAGE_REFRESH,
});
