const nonAccentVietnamese = (string) => {
  let resString = string;
  resString = resString.toLowerCase();
  resString = resString.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
  resString = resString.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
  resString = resString.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
  resString = resString.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
  resString = resString.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
  resString = resString.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
  resString = resString.replace(/đ/g, 'd');
  // Some system encode vietnamese combining accent as individual utf-8 characters
  resString = resString.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ''); // Huyền sắc hỏi ngã nặng
  resString = resString.replace(/\u02C6|\u0306|\u031B/g, ''); // Â, Ê, Ă, Ơ, Ư
  return resString;
};

export default nonAccentVietnamese;
