import FileSaver from 'file-saver';
import XLSX from 'xlsx';

const createSheet = (data) => {
  const { SSF } = XLSX;
  const { _table: table } = SSF;
  const ws = {};
  const range = { s: { c: 10000000, r: 10000000 }, e: { c: 0, r: 0 } };
  for (let R = 0; R !== data.length; R += 1) {
    for (let C = 0; C !== data[R].length; C += 1) {
      if (range.s.r > R) range.s.r = R;
      if (range.s.c > C) range.s.c = C;
      if (range.e.r < R) range.e.r = R;
      if (range.e.c < C) range.e.c = C;
      const cell = { v: data[R][C] };
      if (cell.v == null) return ws;
      const cellRef = XLSX.utils.encode_cell({ c: C, r: R });

      if (typeof cell.v === 'number') cell.t = 'n';
      else if (typeof cell.v === 'boolean') cell.t = 'b';
      else if (cell.v instanceof Date) {
        cell.t = 'n';
        // eslint-disable-next-line prefer-destructuring
        cell.z = table[14];
      } else cell.t = 's';

      ws[cellRef] = cell;
    }
  }
  if (range.s.c < 10000000) ws['!ref'] = XLSX.utils.encode_range(range);

  return ws;
};

const string2ArrayBuffer = (s) => {
  const buf = new ArrayBuffer(s.length);
  const view = new Uint8Array(buf);
  // eslint-disable-next-line no-bitwise
  for (let i = 0; i !== s.length; i += 1) view[i] = s.charCodeAt(i) & 0xff;
  return buf;
};

const exportExcelFile = (data, mime, name, extension) => {
  let file = data;
  if (XLSX && extension.substr(0, 4) === '.xls') {
    const wb = {
      SheetNames: [],
      Sheets: {},
    };
    const ws = createSheet(data);

    wb.SheetNames.push('sheet 1');
    wb.Sheets['sheet 1'] = ws;
    const wopts = {
      bookType: extension.substr(1, 3) + (extension.substr(4) || 'm'),
      bookSST: false,
      type: 'binary',
    };
    const wbout = XLSX.write(wb, wopts);

    file = string2ArrayBuffer(wbout);
  }
  FileSaver.saveAs(new Blob([file], { type: `${mime};UTF-8` }), `${name}.xlsx`, {
    autoBom: true,
  });
};

export default exportExcelFile;
