import styled from '@emotion/styled';

const Wrapper = styled.div`
  position: absolute;
  z-index: 3;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  background: rgba(0, 0, 0, 0.05);
  align-items: center;
  background: ${(props) => props.noBackground && 'transparent'};
`;

export default Wrapper;
