import {
  RESIDENT_DELETE,
  RESIDENT_EXPORT,
  RESIDENT_GETS,
  RESIDENT_CHANGE_STATUS,
  RESIDENT_IMPORT,
  RESIDENT_REFRESH,
} from './resident.types';

export const getResidentsAction = ({ idBuilding, page, limit, filter, refreshState }) => ({
  type: RESIDENT_GETS.REQUEST,
  payload: {
    idBuilding,
    page,
    limit,
    filter,
    refreshState,
  },
});

export const deleteResidentAction = ({ idBuilding, idResidents, isAll, filter, refreshState }) => ({
  type: RESIDENT_DELETE.REQUEST,
  payload: {
    isAll,
    idResidents,
    filter,
    idBuilding,
    refreshState,
  },
});

export const changeStatusResident = ({ idBuilding, idResident, status, refreshState }) => ({
  type: RESIDENT_CHANGE_STATUS.REQUEST,
  payload: {
    idResident,
    status,
    idBuilding,
    refreshState,
  },
});

export const importResidentAction = ({ idBuilding, file, refreshState }) => ({
  type: RESIDENT_IMPORT.REQUEST,
  payload: {
    file,
    idBuilding,
    refreshState,
  },
});

export const exportResidentAction = ({ idBuilding, filter, refreshState }) => ({
  type: RESIDENT_EXPORT.REQUEST,
  payload: {
    idBuilding,
    filter,
    refreshState,
  },
});

export const refreshExportResident = () => ({
  type: RESIDENT_EXPORT.REFRESH,
});

export const refreshResidentPage = () => ({
  type: RESIDENT_REFRESH,
});
