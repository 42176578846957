import { createAction } from 'shared/utils';

export const SERVICE = {
  GET: createAction('CONFIG_SERVICE_PAGE_GET'),
  GETS: createAction('CONFIG_SERVICE_PAGE_GETS'),
  ADD: createAction('CONFIG_SERVICE_PAGE_ADD'),
  UPDATE: createAction('CONFIG_SERVICE_PAGE_UPDATE'),
  CHANGE_STATUS: createAction('CONFIG_SERVICE_CHANGE_STATUS'),
  DELETE: createAction('CONFIG_SERVICE_PAGE_DELETE'),
  ICON_GETS: createAction('CONFIG_ICON_GETS'),
  CONTRACT_TEMPLATE_BY_FORMULA_GETS: createAction('CONFIG_SERVICE_CONTRACT_TEMPLATE_BY_FORMULA_GETS'),
  CONTRACT_TEMPLATE_BY_SURCHARGE_GETS: createAction('CONFIG_SERVICE_CONTRACT_TEMPLATE_BY_SURCHARGE_GETS'),
  CONTRACT_TEMPLATE_BY_PROMOTION_GETS: createAction('CONFIG_SERVICE_CONTRACT_TEMPLATE_BY_PROMOTION_GETS'),
  CONTRACT_TEMPLATE_BY_SERVICE_GETS: createAction('CONFIG_SERVICE_CONTRACT_TEMPLATE_BY_SERVICE_GETS'),
};
