import moment from 'moment';
import { isDate } from 'lodash';

const convertDateToString = (dateInput, type = 'dd/MM/yyyy') => {
  const getDate = (date) => {
    return date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
  };
  const getMonth = (date) => {
    return date.getMonth() < 9 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
  };
  const getYear = (date) => {
    let year = `${date.getFullYear()}`;
    const tmp = 4 - year.length;
    for (let i = 0; i < tmp; i += 1) {
      year = `0${year}`;
    }
    return year;
  };
  const getHours = (date) => {
    return date.getHours();
  };
  const getMinutes = (date) => {
    return date.getMinutes();
  };
  const getSeconds = (date) => {
    return date.getSeconds();
  };
  if (!dateInput) {
    return '';
  }
  const newDateInput = typeof dateInput === 'string' ? moment(dateInput).toDate() : dateInput;
  if (isDate(newDateInput)) {
    switch (type) {
      case 'dd/MM/yyyy':
        if (newDateInput) {
          return `${getDate(newDateInput)}/${getMonth(newDateInput)}/${getYear(newDateInput)}`;
        }
        return newDateInput;

      case 'yyyy-MM-dd':
        if (newDateInput) {
          return `${getYear(newDateInput)}-${getMonth(newDateInput)}-${getDate(newDateInput)}`;
        }
        return newDateInput;

      case 'yyyy-MM':
        if (newDateInput) {
          return `${getYear(newDateInput)}-${getMonth(newDateInput)}`;
        }
        return newDateInput;

      case 'yyyy-MM-dd hh:mm:ss':
        if (newDateInput) {
          return `${getYear(newDateInput)}-${getMonth(newDateInput)}-${getDate(newDateInput)} ${getHours(newDateInput)}:${getMinutes(
            newDateInput
          )}:${getSeconds(newDateInput)}`;
        }
        return newDateInput;

      case 'dd/MM/yyyy hh:mm:ss':
        if (newDateInput) {
          return `${getDate(newDateInput)}/${getMonth(newDateInput)}/${getYear(newDateInput)} ${getHours(newDateInput)}:${getMinutes(
            newDateInput
          )}:${getSeconds(newDateInput)}`;
        }
        return newDateInput;

      case 'MM/YYYY':
        if (newDateInput) {
          return `${getMonth(newDateInput)}/${getYear(newDateInput)}`;
        }
        return newDateInput;

      default:
        return newDateInput;
    }
  } else {
    return newDateInput;
  }
};

export default convertDateToString;
