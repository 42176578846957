import { createAction } from 'shared/utils';

export const AMENITIES_ORDER_DETAIL = {
  APPROVE: createAction('AMENITIES_ORDER_DETAIL_APPROVE'),
  REJECT: createAction('AMENITIES_ORDER_DETAIL_REJECT'),
  CANCEL: createAction('AMENITIES_ORDER_DETAIL_CANCEL'),
  PAYMENT: createAction('AMENITIES_ORDER_DETAIL_PAYMENT'),
  REFUND: createAction('AMENITIES_ORDER_DETAIL_REFUND'),
  REFUND_DEPOSIT: createAction('AMENITIES_ORDER_DETAIL_REFUND_DEPOSIT'),
  GET: createAction('AMENITIES_ORDER_DETAIL_GET'),
  PREVIEW_RECEIPT: createAction('AMENITIES_ORDER_DETAIL_PREVIEW_RECEIPT'),
  PREVIEW_PAYSLIP: createAction('AMENITIES_ORDER_DETAIL_PREVIEW_PAYSLIP'),
};
