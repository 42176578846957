import { language } from '../utils/language';
import convertArrToObj from '../utils/convertArrToObj';

export const LOCK_DATA_STATUS_ACTIVE = 'ACTIVE';
export const LOCK_DATA_STATUS_DISABLED = 'DISABLED';

export const LOCK_DATA_STATUS_OPTIONS = [
  {
    id: LOCK_DATA_STATUS_ACTIVE,
    name: language.label.in_effect,
  },
  {
    id: LOCK_DATA_STATUS_DISABLED,
    name: language.label.inactive,
  },
];

export const LOCK_DATA_STATUS_BY_ID = convertArrToObj(LOCK_DATA_STATUS_OPTIONS, 'id');
