import { PHONE_CODE } from '../constants/phone';

const convertPhoneToNumber = (phone, prefixCode = PHONE_CODE) => {
  if (!phone) {
    return '';
  }
  if (/^0/.test(phone)) {
    return phone.substring(1);
  }
  return phone.substring(prefixCode?.length ? prefixCode?.length : PHONE_CODE?.length);
};

export default convertPhoneToNumber;
