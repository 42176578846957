function threeDigitsWithDecimal(number) {
  if (Number.isNaN(Number(number))) {
    return '';
  }
  const numberString = String(number);
  const numberArr = numberString.split('.');
  let integerPart = numberArr[0];
  const decimalPart = numberArr.length > 1 ? `.${numberArr[1]}` : '';
  const rgx = /(\d+)(\d{3})/;
  while (rgx.test(integerPart)) {
    integerPart = integerPart.replace(rgx, '$1,$2');
  }
  return integerPart + decimalPart;
}

export default threeDigitsWithDecimal;
