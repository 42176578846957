// application
import { API_TYPE_CALL_API, API_TYPE_CALL_API_WITH_FILE, API_TYPE_DOWNLOAD_FILE, METHOD, SERVER, X_UNIT_GROUP_ID } from 'shared/constants';
import api from './api';

export const UNIT_TYPE = `${SERVER}/unit-type`;
export const UNIT = `${SERVER}/unit`;
export const UNIT_GET_LIST = `${UNIT}/list`;
export const UNIT_ADD = UNIT;
export const UNIT_UPDATE = (idUnit) => `${UNIT}/${idUnit}`;
export const UNIT_DELETE = (idUnit) => `${UNIT}/${idUnit}`;
export const UNIT_DELETE_MULTI = `${UNIT}/delete-multi`;
export const UNIT_IMPORT = `${UNIT}/import-data`;
export const UNIT_EXPORT = `${UNIT}/export-data`;
export const UNIT_QR_CODE_GET = (id) => `${UNIT}/${id}/invite-resident`;
export const UNIT_QR_CODE_EXPORT = `${UNIT}/export-unit-code`;
export const UNIT_QR_CODE_REFRESH = `${UNIT}/refresh-code`;
export const UNIT_WALLET_GETS = (id) => `${UNIT}/${id}/wallet`;

export const getApartmentTypesService = async () => {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: UNIT_TYPE,
      method: METHOD.GET,
    },
  });
  return response;
};

export async function getApartmentsService({ idBuilding, page, limit, filter }) {
  const headers = {
    [X_UNIT_GROUP_ID]: idBuilding,
  };
  const params = {
    ...filter,
    page,
    limit,
  };
  const response = api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: UNIT_GET_LIST,
      method: METHOD.GET,
      options: {
        params,
        headers,
      },
    },
  });
  return response;
}

export async function importApartmentService({ idBuilding, file }) {
  const response = await api({
    type: API_TYPE_CALL_API_WITH_FILE,
    payload: {
      apiUrl: UNIT_IMPORT,
      method: METHOD.POST,
      file,
      options: {
        headers: {
          [X_UNIT_GROUP_ID]: idBuilding,
        },
      },
    },
  });
  return response;
}

export async function exportApartmentService({ idBuilding, filter }) {
  const response = await api({
    type: API_TYPE_DOWNLOAD_FILE,
    payload: {
      apiUrl: UNIT_EXPORT,
      method: METHOD.GET,
      options: {
        headers: {
          [X_UNIT_GROUP_ID]: idBuilding,
        },
        params: {
          ...filter,
        },
      },
    },
  });
  return response;
}

export async function getQRCodeApartmentService({ idApartment, idBuilding }) {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: UNIT_QR_CODE_GET(idApartment),
      method: METHOD.GET,
      options: {
        headers: {
          [X_UNIT_GROUP_ID]: idBuilding,
        },
      },
    },
  });
  return response;
}

export async function exportQRCodeApartmentService({ idApartments, filter = {}, isAll = false, idBuilding }) {
  const response = await api({
    type: API_TYPE_DOWNLOAD_FILE,
    payload: {
      apiUrl: UNIT_QR_CODE_EXPORT,
      method: METHOD.POST,
      body: {
        list: idApartments,
        filterObject: filter,
        isAll,
      },
      options: {
        headers: {
          [X_UNIT_GROUP_ID]: idBuilding,
        },
      },
    },
  });
  return response;
}

export async function refreshQRCodeApartmentService({ idApartments, filter = {}, isAll = false, idBuilding }) {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: UNIT_QR_CODE_REFRESH,
      method: METHOD.POST,
      body: {
        list: idApartments,
        filterObject: filter,
        isAll,
      },
      options: {
        headers: {
          [X_UNIT_GROUP_ID]: idBuilding,
        },
      },
    },
  });
  return response;
}

export async function getApartmentByIdService({ idApartment, idBuilding }) {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: `${UNIT}/${idApartment}`,
      method: METHOD.GET,
      options: {
        headers: {
          [X_UNIT_GROUP_ID]: idBuilding,
        },
      },
    },
  });
  return response;
}

export async function addApartmentService({ idBuilding, apartment }) {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: UNIT_ADD,
      method: METHOD.POST,
      body: apartment,
      options: {
        headers: {
          [X_UNIT_GROUP_ID]: idBuilding,
        },
      },
    },
  });
  return response;
}

export async function updateApartmentService({ idBuilding, idApartment, apartment }) {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: UNIT_UPDATE(idApartment),
      method: METHOD.PUT,
      body: apartment,
      options: {
        headers: {
          [X_UNIT_GROUP_ID]: idBuilding,
        },
      },
    },
  });
  return response;
}

export async function deleteApartmentService({ idBuilding, idApartment }) {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: UNIT_DELETE(idApartment),
      method: METHOD.DELETE,
      options: {
        headers: {
          [X_UNIT_GROUP_ID]: idBuilding,
        },
      },
    },
  });
  return response;
}

export async function deleteMultiApartmentService({ idBuilding, isAll, filterObject, idApartments }) {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: UNIT_DELETE_MULTI,
      method: METHOD.POST,
      body: {
        array: idApartments,
        isAll,
        filterObject,
      },
      options: {
        headers: {
          [X_UNIT_GROUP_ID]: idBuilding,
        },
      },
    },
  });
  return response;
}

export async function getWalletApartmentService({ idUnit, idBuilding }) {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: UNIT_WALLET_GETS(idUnit),
      method: METHOD.GET,
      options: {
        headers: {
          [X_UNIT_GROUP_ID]: idBuilding,
        },
      },
    },
  });
  return response;
}
