import {
  API_TYPE_CALL_API,
  API_TYPE_CALL_API_PARSER,
  API_TYPE_DOWNLOAD_FILE,
  METHOD,
  SERVER,
  SERVER_CHAT,
  X_UNIT_GROUP_ID,
  X_UNIT_ID,
} from 'shared/constants';
import api from './api';

const HELP_DESK_EXPORT = `${SERVER}/pms/helpdesk/export`;
const HELP_DESK_GETS = `${SERVER}/pms/helpdesk`;
const HELP_DESK_APPROVE = (idHelpDesk) => `${SERVER}/pms/helpdesk/${idHelpDesk}/approve`;
const HELP_DESK_COMPLETE = (idHelpDesk) => `${SERVER}/pms/helpdesk/${idHelpDesk}/complete`;
const HELP_DESK_LABEL = (idHelpDesk) => `${SERVER}/pms/helpdesk/${idHelpDesk}/label`;
const ROOM_MEMBER_GETS = (unitGroupId) => `${SERVER}/unit-group/${unitGroupId}/room-members`;
const HELP_DESK_COUNT_BADGE = `${SERVER}/pms/helpdesk/count-badges`;
const ROOM_READ_STATUS_GETS = `${SERVER_CHAT}/rooms/read-status`;
const MESSAGES_GETS = (roomId) => `${SERVER_CHAT}/rooms/${roomId}/messages`;
const MESSAGES_SEND = (roomId) => `${SERVER_CHAT}/rooms/${roomId}/messages`;
const MESSAGES_READ_ALL = (roomId) => `${SERVER_CHAT}/rooms/${roomId}/messages/readall`;

const HELP_DESK_TYPE_GETS = `${SERVER}/helpdesk-type`;
const HELP_DESK_TYPE_ADD = `${SERVER}/helpdesk-type`;
const HELP_DESK_TYPE_REMOVE = (idHelpDeskType) => `${SERVER}/helpdesk-type/${idHelpDeskType}`;

export async function exportHelpDeskService(idBuilding, filter) {
  const response = await api({
    type: API_TYPE_DOWNLOAD_FILE,
    payload: {
      apiUrl: HELP_DESK_EXPORT,
      method: METHOD.GET,
      options: {
        params: { ...filter },
        headers: { [X_UNIT_GROUP_ID]: idBuilding },
      },
    },
  });
  return response;
}

export async function getListHelpDeskService({ idBuilding, filter, page, limit }) {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: HELP_DESK_GETS,
      method: METHOD.GET,
      options: {
        params: {
          ...filter,
          page,
          limit,
        },
        headers: { [X_UNIT_GROUP_ID]: idBuilding },
      },
    },
  });
  return response;
}

export async function getBadgeOfListHelpDeskService(idBuilding, idRooms) {
  const response = await api({
    type: API_TYPE_CALL_API_PARSER,
    payload: {
      apiUrl: ROOM_READ_STATUS_GETS,
      method: METHOD.GET,
      options: {
        params: {
          roomIds: idRooms,
        },
        headers: { [X_UNIT_GROUP_ID]: idBuilding },
      },
    },
  });
  return response;
}

export async function approveHelpDeskService(idBuilding, idHelpDesk, dataRequest) {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: HELP_DESK_APPROVE(idHelpDesk),
      method: METHOD.PUT,
      body: dataRequest,
      options: {
        headers: { [X_UNIT_GROUP_ID]: idBuilding },
      },
    },
  });
  return response;
}

export async function updateLabelHelpDeskService(idBuilding, idHelpDesk, dataRequest) {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: HELP_DESK_LABEL(idHelpDesk),
      method: METHOD.PUT,
      body: dataRequest,
      options: {
        headers: { [X_UNIT_GROUP_ID]: idBuilding },
      },
    },
  });
  return response;
}

export async function completeHelpDeskService(idBuilding, idHelpDesk) {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: HELP_DESK_COMPLETE(idHelpDesk),
      method: METHOD.PUT,
      options: {
        headers: { [X_UNIT_GROUP_ID]: idBuilding },
      },
    },
  });
  return response;
}

export async function getRoomMemberService(idBuilding, idUnit) {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: ROOM_MEMBER_GETS(idBuilding),
      method: METHOD.GET,
      options: {
        headers: {
          [X_UNIT_GROUP_ID]: idBuilding,
          [X_UNIT_ID]: idUnit,
        },
      },
    },
  });
  return response;
}

export async function getMessagesService({ idBuilding, idRoom, offset, limit }) {
  const response = await api({
    type: API_TYPE_CALL_API_PARSER,
    payload: {
      apiUrl: MESSAGES_GETS(idRoom),
      method: METHOD.GET,
      options: {
        params: {
          offset,
          limit,
        },
        headers: {
          [X_UNIT_GROUP_ID]: idBuilding,
        },
      },
    },
  });
  return response;
}

export async function sendMessagesService({ idBuilding, idRoom, content, images, type, deviceToken }) {
  const headers = {
    [X_UNIT_GROUP_ID]: idBuilding,
  };

  const body = {
    content,
    images,
    type,
    deviceToken,
  };

  const response = await api({
    type: API_TYPE_CALL_API_PARSER,
    payload: {
      apiUrl: MESSAGES_SEND(idRoom),
      method: METHOD.POST,
      body,
      options: {
        headers,
      },
    },
  });
  return response;
}

export async function readAllMessageService(idBuilding, idRoom) {
  const headers = {
    [X_UNIT_GROUP_ID]: idBuilding,
  };

  const response = await api({
    type: API_TYPE_CALL_API_PARSER,
    payload: {
      apiUrl: MESSAGES_READ_ALL(idRoom),
      method: METHOD.PUT,
      options: {
        headers,
      },
    },
  });
  return response;
}

export async function countHelpDeskBadgeService(idBuilding) {
  const headers = {
    [X_UNIT_GROUP_ID]: idBuilding,
  };

  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: HELP_DESK_COUNT_BADGE,
      method: METHOD.GET,
      options: {
        headers,
      },
    },
  });
  return response;
}

export async function getListHelpDeskTypeService(idBuilding) {
  const headers = {
    [X_UNIT_GROUP_ID]: idBuilding,
  };

  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: HELP_DESK_TYPE_GETS,
      method: METHOD.GET,
      options: {
        headers,
      },
    },
  });
  return response;
}

export async function addHelpDeskTypeService(idBuilding, helpDeskType) {
  const headers = {
    [X_UNIT_GROUP_ID]: idBuilding,
  };

  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: HELP_DESK_TYPE_ADD,
      method: METHOD.POST,
      body: {
        ...helpDeskType,
      },
      options: {
        headers,
      },
    },
  });
  return response;
}

export async function removeHelpDeskTypeService(idBuilding, idHelpDeskType) {
  const headers = {
    [X_UNIT_GROUP_ID]: idBuilding,
  };

  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: HELP_DESK_TYPE_REMOVE(idHelpDeskType),
      method: METHOD.DELETE,
      options: {
        headers,
      },
    },
  });
  return response;
}
