import { language } from '../utils/language';
import convertArrToObj from '../utils/convertArrToObj';

export const MAINTENANCE = 'MAINTENANCE';
export const TESTING = 'TESTING';

export const CONTRACTOR_TYPE = [
  {
    id: MAINTENANCE,
    name: language.maintenance,
  },
  {
    id: TESTING,
    name: language.testing,
  },
];

export const CONTRACTOR_TYPE_BY_ID = convertArrToObj(CONTRACTOR_TYPE, 'id');
export const CONTRACTOR_TYPE_BY_NAME = convertArrToObj(CONTRACTOR_TYPE, 'name');
