/* eslint-disable import/no-cycle */
import { useContext, useMemo } from 'react';
import { Context as GlobalContext } from 'globalContext';
import { useParams } from 'react-router-dom';
import { LIST_ACCOUNT_CREATE_BUILDING, LIST_ACCOUNT_CS } from 'shared/constants';

const isProduction = process.env.ENV === 'PRODUCTION';

const useCurrentUser = () => {
  const { idBuilding } = useParams();
  const { currentUser, setCurrentUser } = useContext(GlobalContext);

  const handleChangeCurrentUser = (user) => {
    setCurrentUser(user);
  };

  const currentEmployee = useMemo(() => {
    return currentUser?.employees?.find((employee) => employee.unitGroupId === +idBuilding);
  }, [currentUser]);

  const isUserCs = useMemo(() => {
    return isProduction && LIST_ACCOUNT_CS.includes(currentUser?.email);
  }, [currentUser]);

  const isUserHavePermissionCreateBuilding = useMemo(() => {
    return LIST_ACCOUNT_CREATE_BUILDING.includes(currentUser?.email);
  }, [currentUser]);

  return { currentUser, handleChangeCurrentUser, currentEmployee, isUserCs, isUserHavePermissionCreateBuilding };
};

export default useCurrentUser;
