import { AMENITIES } from './amenities.types';

export const getsAmenities = ({ filterObj, page, limit, idBuilding }) => ({
  type: AMENITIES.GETS.REQUEST,
  payload: {
    filterObj,
    page,
    limit,
    idBuilding,
  },
});

export const getAmenities = ({ idAmenities, idBuilding }) => ({
  type: AMENITIES.GET.REQUEST,
  payload: {
    idAmenities,
    idBuilding,
  },
});

export const getPriorityInAppSelected = ({ idBuilding }) => ({
  type: AMENITIES.GET_PRIORITY_IN_APP_SELECTED.REQUEST,
  payload: {
    idBuilding,
  },
});

export const refreshGetAmenities = () => ({
  type: AMENITIES.GET.REFRESH,
});

export const addAmenities = ({ amenities }) => {
  return {
    type: AMENITIES.ADD.REQUEST,
    payload: {
      ...amenities,
    },
  };
};

export const refreshAddAmenities = () => ({
  type: AMENITIES.ADD.REFRESH,
});

export const updateAmenities = ({ amenities }) => ({
  type: AMENITIES.UPDATE.REQUEST,
  payload: {
    ...amenities,
  },
});

export const refreshUpdateAmenities = () => ({
  type: AMENITIES.UPDATE.REFRESH,
});
