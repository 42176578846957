import get from 'lodash/get';
import Cookie from './cookie';
import { COOKIE_TAG } from '../constants/cookie';

const en = require('../constants/language/en.json');
const vn = require('../constants/language/vn.json');
const jp = require('../constants/language/jp.json');

const enOffice = require('../constants/languageOffice/en.json');
const vnOffice = require('../constants/languageOffice/vn.json');
const jpOffice = require('../constants/languageOffice/jp.json');

const getLanguage = () => {
  const cache = Cookie.get(COOKIE_TAG);
  switch (cache.language) {
    case 'en':
      return { ...en, ...enOffice };
    case 'vi':
      return { ...vn, ...vnOffice };
    case 'jp':
      return { ...jp, ...jpOffice };
    default:
      return { ...vn, ...vnOffice };
  }
};

export const getCacheLanguage = () => {
  const cache = Cookie.get(COOKIE_TAG);
  return cache?.language ?? 'vi';
};

export const language = getLanguage();

export const setLanguage = (languageTmp) => {
  const cache = Cookie.get(COOKIE_TAG);
  Cookie.save(COOKIE_TAG, {
    ...cache,
    language: languageTmp,
  });
};

export const languageBindArgs = (keyLanguage, data) => {
  const tmp = String(get(language, keyLanguage, ''));
  Object.keys(data).forEach((key) => {
    const expression = `{${key}}`;
    const regex = new RegExp(expression, 'i');
    tmp.replace(regex, data[key]);
  });
  return tmp;
};
