import { language } from '../utils/language';
import convertArrToObj from '../utils/convertArrToObj';

export const DEAL_METHOD_CASH = 'CASH';
export const DEAL_METHOD_TRANSFER = 'TRANSFER';
export const DEAL_METHOD_ONLINE = 'ONLINE';
export const DEAL_METHOD_SPOS = 'SPOS';

export const DEAL_METHOD_OPTION = [
  {
    value: DEAL_METHOD_CASH,
    label: language.cash,
  },
  {
    value: DEAL_METHOD_TRANSFER,
    label: language.transfer,
  },
  {
    value: DEAL_METHOD_ONLINE,
    label: language.online,
  },
  {
    value: DEAL_METHOD_SPOS,
    label: language.vnpay_spos,
  },
];

export const DEAL_METHOD_OBJ = convertArrToObj(DEAL_METHOD_OPTION, 'value');

export const FUND_PAYMENT_MODE_ON = 'ON'; // Sẵn sàng có thể thanh toán
export const FUND_PAYMENT_MODE_VIEW = 'VIEW'; // Chỉ hiển thị dạng disabled
export const FUND_PAYMENT_MODE_OFF = 'OFF'; // Ẩn thanh toán

export const FUND_PAYMENT_MODES = [
  { value: FUND_PAYMENT_MODE_ON, label: language.on },
  { value: FUND_PAYMENT_MODE_VIEW, label: language.view },
  { value: FUND_PAYMENT_MODE_OFF, label: language.off },
];

export const DEAL_METHOD_BY_ID = convertArrToObj(DEAL_METHOD_OPTION);

export const ONLINE_ACCOUNT_OPTION = [
  // {
  //   id: 1,
  //   code: 'VNPAY_QR',
  //   name: language.vnpay_qr,
  //   fundName: 'Quét mã VNPAY-QR',
  //   domestic: true,
  // },
  {
    id: 2,
    code: 'MOMO_IN_APP',
    name: language.momo,
    fundName: 'Ví MoMo',
    domestic: true,
  },
  {
    id: 3,
    code: 'VNPAY_GW',
    name: language.vnpay_gw_domestic,
    fundName: 'Thanh toán nội địa qua cổng thanh toán VNPay',
    domestic: true,
  },
  {
    id: 4,
    code: 'VNPAY_GW_INTCARD',
    name: language.vnpay_gw_international,
    fundName: 'Thẻ thanh toán quốc tế',
    domestic: false,
  },
  {
    id: 5,
    code: 'CYHOME_MB_APP',
    name: language.vnpay_mb_app,
    fundName: 'Thanh toán qua ứng dụng ngân hàng',
    domestic: true,
  },
  {
    id: 6,
    code: 'SHOPEEPAY_IN_APP',
    name: language.shopee_app,
    fundName: 'Thanh toán qua ứng dụng shopee',
    domestic: true,
  },
  {
    id: 7,
    code: 'VNPAY_TOKEN',
    name: language.vnpay_token,
    fundName: 'Liên kết thẻ ngân hàng',
    domestic: true,
  },
  {
    id: 8,
    code: 'PAYOO',
    name: language.payoo,
    fundName: 'Thanh toán qua ứng dụng Payoo',
    domestic: true,
  },
];

export const SPOS_FUND_OPTION = [
  {
    id: 8,
    code: 'VNPAY_SPOS',
    name: language.vnpay_spos,
    fundName: 'VNPAY SPOS',
    domestic: true,
  },
];

export const ONLINE_ACCOUNT_OBJ = convertArrToObj([...ONLINE_ACCOUNT_OPTION, ...SPOS_FUND_OPTION], 'code');
