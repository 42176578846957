export const METHOD = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE',
  PATCH: 'PATCH',
};

export const SUCCESS = 'SUCCESS';

export const SUCCESS_CODE = [200, 201, 202, 204, 205, 206, 207, 208, 226];

export const ERROR_CODE = [
  400,
  401,
  402,
  403,
  404,
  405,
  406,
  407,
  408,
  409,
  410,
  411,
  412,
  413,
  414,
  415,
  416,
  417,
  418,
  421,
  422,
  423,
  424,
  425,
  426,
  428,
  429,
  431,
  451,
  500,
  501,
  502,
  503,
  504,
  505,
  506,
  507,
  508,
  510,
  511,
];

export const X_UNIT_GROUP_ID = 'x-unit-group-id';

export const X_DEPARTMENT_ID = 'x-department-id';

export const X_UNIT_ID = 'x-unit-id';

export const X_LANGUAGE = 'x-language';

export const TIME_DEBOUNCE_CALL_API = 500;
