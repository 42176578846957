const createAction = (key) => {
  return {
    REQUEST: `${key}:REQUEST`,
    SUCCESS: `${key}:SUCCESS`,
    FAIL: `${key}:FAIL`,
    REFRESH: `${key}:REFRESH`,
  };
};

export default createAction;
