import {
  getAmenitiesOrderService,
  paymentAmenitiesOrderService,
  refundAmenitiesOrderService,
  refundDepositAmenitiesOrderService,
} from 'services/amenities';
import { getReceiptByIdService } from 'services/receipt';
import { getPaySlipPreviewService } from 'services/paySlip';
import { put, call, takeLatest, all } from 'redux-saga/effects';
import { X_UNIT_GROUP_ID } from 'shared/constants';
import { AMENITIES_ORDER_DETAIL } from './amenities-detail.types';

function* getOrder(action) {
  try {
    const { orderId = '', buildingId } = action.payload;
    const headers = {
      [X_UNIT_GROUP_ID]: buildingId,
    };
    const result = yield call(getAmenitiesOrderService, {
      params: { id: orderId },
      headers,
    });
    yield put({
      payload: result,
      type: AMENITIES_ORDER_DETAIL.GET.SUCCESS,
    });
  } catch (error) {
    yield put({
      type: AMENITIES_ORDER_DETAIL.GET.FAIL,
      payload: error,
    });
  }
}

function* paymentOrder(action) {
  try {
    const { orderId, buildingId, params } = action.payload;
    const headers = {
      [X_UNIT_GROUP_ID]: buildingId,
    };
    const body = {
      id: orderId,
      ...params,
    };
    const result = yield call(paymentAmenitiesOrderService, {
      headers,
      body,
    });
    yield put({
      payload: result,
      type: AMENITIES_ORDER_DETAIL.PAYMENT.SUCCESS,
    });
  } catch (error) {
    yield put({
      type: AMENITIES_ORDER_DETAIL.PAYMENT.FAIL,
      payload: error,
    });
  }
}

function* refundOrder(action) {
  try {
    const { orderId, buildingId, params } = action.payload;
    const headers = {
      [X_UNIT_GROUP_ID]: buildingId,
    };
    const body = {
      id: orderId,
      ...params,
    };
    const result = yield call(refundAmenitiesOrderService, {
      headers,
      body,
    });
    yield put({
      payload: result,
      type: AMENITIES_ORDER_DETAIL.REFUND.SUCCESS,
    });
  } catch (error) {
    yield put({
      type: AMENITIES_ORDER_DETAIL.REFUND.FAIL,
      payload: error,
    });
  }
}

function* refundDepositOrder(action) {
  try {
    const { orderId, buildingId, params } = action.payload;
    const headers = {
      [X_UNIT_GROUP_ID]: buildingId,
    };
    const body = {
      id: orderId,
      ...params,
    };
    const result = yield call(refundDepositAmenitiesOrderService, {
      headers,
      body,
    });
    yield put({
      payload: result,
      type: AMENITIES_ORDER_DETAIL.REFUND_DEPOSIT.SUCCESS,
    });
  } catch (error) {
    yield put({
      type: AMENITIES_ORDER_DETAIL.REFUND_DEPOSIT.FAIL,
      payload: error,
    });
  }
}

function* previewReceipt(action) {
  try {
    const { idBuilding, idReceipt } = action.payload;

    const result = yield call(getReceiptByIdService, { idBuilding, idReceipt });
    yield put({
      payload: result,
      type: AMENITIES_ORDER_DETAIL.PREVIEW_RECEIPT.SUCCESS,
    });
  } catch (error) {
    yield put({
      type: AMENITIES_ORDER_DETAIL.PREVIEW_RECEIPT.FAIL,
      payload: error,
    });
  }
}

function* previewPaySlip(action) {
  try {
    const { unitGroupId, paySlipId } = action.payload;

    const result = yield call(getPaySlipPreviewService, unitGroupId, paySlipId);
    yield put({
      payload: result,
      type: AMENITIES_ORDER_DETAIL.PREVIEW_PAYSLIP.SUCCESS,
    });
  } catch (error) {
    yield put({
      type: AMENITIES_ORDER_DETAIL.PREVIEW_PAYSLIP.FAIL,
      payload: error,
    });
  }
}

export default function* watch() {
  yield all([
    takeLatest(AMENITIES_ORDER_DETAIL.GET.REQUEST, getOrder),
    takeLatest(AMENITIES_ORDER_DETAIL.PAYMENT.REQUEST, paymentOrder),
    takeLatest(AMENITIES_ORDER_DETAIL.REFUND.REQUEST, refundOrder),
    takeLatest(AMENITIES_ORDER_DETAIL.REFUND_DEPOSIT.REQUEST, refundDepositOrder),
    takeLatest(AMENITIES_ORDER_DETAIL.PREVIEW_RECEIPT.REQUEST, previewReceipt),
    takeLatest(AMENITIES_ORDER_DETAIL.PREVIEW_PAYSLIP.REQUEST, previewPaySlip),
  ]);
}
