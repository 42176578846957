import { API_TYPE_CALL_API, METHOD, POSITION_LEVEL, SERVER, X_UNIT_GROUP_ID } from 'shared/constants';
import api from './api';

const SUBDIVISION = `${SERVER}/${POSITION_LEVEL}/subdivision`;
const SUBDIVISION_GETS = SUBDIVISION;
const SUBDIVISION_ADD = SUBDIVISION;
const SUBDIVISION_UPDATE = (idSubdivision) => `${SUBDIVISION}/${idSubdivision}`;
const SUBDIVISION_DELETE = (idSubdivision) => `${SUBDIVISION}/${idSubdivision}`;

export const getSubdivisionsService = async ({ idBuilding, filter }) => {
  const headers = {
    [X_UNIT_GROUP_ID]: idBuilding,
  };
  const params = {
    ...filter,
    unitGroupId: idBuilding,
  };
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: SUBDIVISION_GETS,
      method: METHOD.GET,
      options: {
        params,
        headers,
      },
    },
  });
  return response;
};

export const addSubdivisionService = async ({ idBuilding, subdivision }) => {
  const headers = {
    [X_UNIT_GROUP_ID]: idBuilding,
  };
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: SUBDIVISION_ADD,
      method: METHOD.POST,
      body: subdivision,
      options: {
        headers,
      },
    },
  });
  return response;
};

export const updateSubdivisionService = async ({ idBuilding, idSubdivision, subdivision }) => {
  const headers = {
    [X_UNIT_GROUP_ID]: idBuilding,
  };
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: SUBDIVISION_UPDATE(idSubdivision),
      method: METHOD.PUT,
      body: subdivision,
      options: {
        headers,
      },
    },
  });
  return response;
};

export const deleteSubdivisionService = async ({ idBuilding, idSubdivision }) => {
  const headers = {
    [X_UNIT_GROUP_ID]: idBuilding,
  };
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: SUBDIVISION_DELETE(idSubdivision),
      method: METHOD.DELETE,
      options: {
        headers,
      },
    },
  });
  return response;
};
