import { forEach, isObject } from 'lodash';
import PAGINATION_LIMIT from 'shared/constants/pagination';

export const getTotalPage = (totalRecord = 0, size = PAGINATION_LIMIT) => {
  return Math.ceil(totalRecord / size) || 1;
};

export const getTotalRecord = (headers) => {
  const count = headers?.get('Content-Range')?.split('/')?.[1] ?? 0;
  return parseInt(count, 10);
};

export const checkResponseError = (response) => {
  if (!isObject(response) || (isObject(response) && response && response.error)) {
    return (
      (response &&
        response.error && {
          code: response.error.statusCode, // code of status
          message: response.error.message,
          codeLanguage: response.error.code,
        }) || {
        code: 500,
        message: 'Something wrong',
        codeLanguage: 'SOMETHING_WRONG',
      }
    );
  }
  return false;
};

export const handleError = (error) => {
  return {
    code: error.code || 500,
    message: error.message || error.toString(),
    codeLanguage: error.codeLanguage || 'FAIL',
  };
};

export const handleResponse = ({ response }) => {
  const err = checkResponseError(response);
  if (err) throw err;
  return {
    code: response.code,
    data: response.data,
    totalRecord: getTotalRecord(response.headers),
  };
};

export const getFilter = (filterObject) => {
  const filter = { ...filterObject };
  forEach(filter, (value, key) => {
    if (!value) {
      delete filter[key];
    } else {
      filter[key] = {
        ilike: `%25${value}%25`,
      };
    }
  });
  return filter;
};
