/* eslint-disable react/prop-types */
import React from 'react';
import styled from '@emotion/styled';
import isNil from 'lodash/isNil';
import Icon from 'components/Icon';

const LeafTitle = styled.span`
  border-left: 10px solid ${(props) => props.color};
  border-radius: 4px;
  background-color: #f5f5f5;
  margin: 4px;
  padding: 1px 0 1px 0;
  span {
    padding: 0 12px 0 8px;
  }
`;

const buildLabelTree = (array = []) =>
  array.map((node) => {
    const { id, title, color, childs } = node;
    // Check is label or folder (label has color but folder no)
    const isLeaf = !isNil(node.color);
    if (isLeaf) {
      return {
        key: id,
        value: id,
        isLeaf: true,
        selectable: true,
        title: (
          <LeafTitle color={color}>
            <span>{title}</span>
          </LeafTitle>
        ),
        switcherIcon: <Icon height="11px" width="11px" name="tag" />,
      };
    }
    return {
      key: id,
      value: id,
      isLeaf: false,
      selectable: false,
      title: <b>{title}</b>,
      switcherIcon: ({ expanded }) => <Icon name={expanded ? 'folder-open' : 'folder'} />,
      children: buildLabelTree(childs),
    };
  });

export default buildLabelTree;
