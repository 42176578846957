import { X_UNIT_GROUP_ID, METHOD, SERVER, API_TYPE_CALL_API } from 'shared/constants';
import api from './api';

const USER = `${SERVER}/user`;
let GET_PERMISSION = `${USER}/cipms/get-permissions`;
if (process.env.AUTH_METHOD === 'NORMAL') {
  GET_PERMISSION = `${USER}/get-permissions`;
}

export async function getPermissionService({ idBuilding }) {
  const headers = {
    [X_UNIT_GROUP_ID]: idBuilding,
  };
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: GET_PERMISSION,
      method: METHOD.GET,
      options: {
        headers,
      },
    },
  });
  return response;
}
