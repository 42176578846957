import { Typography } from 'antd';
import React, { useMemo, useState } from 'react';
import { language } from '../utils/language';

const usePaginationTable = (options) => {
  const {
    pageSize = 20,
    total,
    isShowTotal = true,
    showQuickJumper = true,
    showSizeChanger = false,
    size = '',
    callbackOnChange = () => {},
    simple,
  } = options;
  const [page, setPage] = useState(1);
  const tablePaginationConfig = useMemo(
    () => ({
      pageSize,
      defaultCurrent: 1,
      current: page,
      total,
      showQuickJumper,
      showSizeChanger,
      size,
      showTotal: () =>
        isShowTotal && (
          <Typography.Text strong>
            {total} {language.label.results}
          </Typography.Text>
        ),
      onChange: (newPage, newPageSize) => {
        callbackOnChange(newPage, newPageSize);
        setPage(newPage);
      },
      simple,
    }),
    [pageSize, page, total]
  );

  return [tablePaginationConfig, setPage];
};

export default usePaginationTable;
