import { RESIDENT_EXPORT, RESIDENT_GETS, RESIDENT_IMPORT, RESIDENT_REFRESH } from './resident.types';

export const initState = {
  residents: [],
  totalResident: [],
  residentExportData: undefined,
  residentImportData: undefined,
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case RESIDENT_REFRESH: {
      return {
        ...initState,
      };
    }
    case RESIDENT_GETS.SUCCESS:
      return {
        ...state,
        residents: action.payload.data,
        totalResident: action.payload.totalRecord,
      };
    case RESIDENT_EXPORT.SUCCESS:
      return {
        ...state,
        residentExportData: action.payload.data,
      };
    case RESIDENT_IMPORT.SUCCESS:
      return {
        ...state,
        residentImportData: action.payload.data,
      };
    case RESIDENT_EXPORT.REFRESH:
      return {
        ...state,
        residentExportData: undefined,
      };
    default: {
      return state;
    }
  }
};

export default reducer;
