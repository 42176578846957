import { API_TYPE_CALL_API, METHOD, X_UNIT_GROUP_ID, SERVER } from 'shared/constants';
import api from './api';

const RENTAL_CONTRACT = `${SERVER}/rental-contract`;
const RENTAL_CONTRACT_API_GETS = RENTAL_CONTRACT;
const RENTAL_CONTRACT_API_ADD = RENTAL_CONTRACT;
const RENTAL_CONTRACT_COUNT_BADGES = `${RENTAL_CONTRACT}/count-badges`;
const RENTAL_CONTRACT_API_UPDATE = (id) => `${RENTAL_CONTRACT}/${id}`;
const RENTAL_CONTRACT_API_TERMINATION = (id) => `${RENTAL_CONTRACT}/${id}/liquidate`;
const RENTAL_CONTRACT_API_LIQUIDATION = (id) => `${RENTAL_CONTRACT}/${id}/terminate`;
const RENTAL_CONTRACT_API_REMOVE = (id) => `${RENTAL_CONTRACT}/${id}`;
const RENTAL_CONTRACT_HISTORY_API_GETS = (id) => `${RENTAL_CONTRACT}/${id}/history`;

export const getRentalContractsService = async ({ filter, limit, page, idBuilding }) => {
  const headers = { [X_UNIT_GROUP_ID]: idBuilding };
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: RENTAL_CONTRACT_API_GETS,
      method: METHOD.GET,
      options: {
        params: {
          ...filter,
          page,
          limit,
        },
        headers,
      },
    },
  });
  return response;
};

export const addRentalContractService = async (rentalContractData, idBuilding) => {
  const headers = { [X_UNIT_GROUP_ID]: idBuilding };
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: RENTAL_CONTRACT_API_ADD,
      method: METHOD.POST,
      body: {
        ...rentalContractData,
      },
      options: {
        headers,
      },
    },
  });
  return response;
};

export const updateRentalContractService = async (id, body, idBuilding) => {
  const headers = { [X_UNIT_GROUP_ID]: idBuilding };
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: RENTAL_CONTRACT_API_UPDATE(id),
      method: METHOD.PUT,
      body,
      options: {
        headers,
      },
    },
  });
  return response;
};

export const terminationRentalContractService = async (idRentalContract, rentalContractData, idBuilding) => {
  const headers = { [X_UNIT_GROUP_ID]: idBuilding };
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: RENTAL_CONTRACT_API_TERMINATION(idRentalContract),
      method: METHOD.PUT,
      body: { ...rentalContractData },
      options: {
        headers,
      },
    },
  });
  return response;
};

export const removeRentalContractService = async (id, idBuilding) => {
  const headers = { [X_UNIT_GROUP_ID]: idBuilding };
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: RENTAL_CONTRACT_API_REMOVE(id),
      method: METHOD.DELETE,
      options: {
        headers,
      },
    },
  });
  return response;
};

export const countBadgesRentalContractService = async (idBuilding) => {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: RENTAL_CONTRACT_COUNT_BADGES,
      method: METHOD.GET,
      options: {
        headers: {
          [X_UNIT_GROUP_ID]: idBuilding,
        },
      },
    },
  });
  return response;
};

export async function getHistoryRentalContractService({ idBuilding, idContract }) {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: RENTAL_CONTRACT_HISTORY_API_GETS(idContract),
      method: METHOD.GET,
      options: {
        headers: {
          [X_UNIT_GROUP_ID]: idBuilding,
        },
      },
    },
  });
  return response;
}

export async function liquidationRentalContractService(idRental, idBuilding) {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: RENTAL_CONTRACT_API_LIQUIDATION(idRental),
      method: METHOD.PATCH,
      options: {
        headers: {
          [X_UNIT_GROUP_ID]: idBuilding,
        },
      },
    },
  });
  return response;
}
