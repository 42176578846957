const intersectPeriodDay = (firstPeriodDays = [], secondPeriodDays = []) => {
  if (!firstPeriodDays || !secondPeriodDays) {
    return false;
  }
  if (!firstPeriodDays.length || !secondPeriodDays.length) {
    return false;
  }
  for (let i = 0; i < secondPeriodDays.length; i += 1) {
    if (firstPeriodDays.includes(secondPeriodDays[i])) {
      return true;
    }
  }
  return false;
};

export default intersectPeriodDay;
