import { API_TYPE_CALL_API, API_TYPE_CALL_API_PARSER, METHOD, SERVER, SERVER_CHAT, X_UNIT_GROUP_ID, X_UNIT_ID } from 'shared/constants';
import api from './api';

const ROOM_CHAT_GETS = `${SERVER_CHAT}/rooms`;
const ROOM_CHAT_APARTMENT_CREATE = `${SERVER_CHAT}/rooms/create`;
const ROOM_MEMBER_GETS = (unitGroupId) => `${SERVER}/unit-group/${unitGroupId}/room-members`;
const MESSAGES_GETS = (roomId) => `${SERVER_CHAT}/rooms/${roomId}/messages`;
const MESSAGES_SEND = (roomId) => `${SERVER_CHAT}/rooms/${roomId}/messages`;
const MESSAGES_READ_BY_ID = (roomId, messageId) => `${SERVER_CHAT}/rooms/${roomId}/messages/${messageId}/read`;
const MESSAGES_READ_ALL = (roomId) => `${SERVER_CHAT}/rooms/${roomId}/messages/readall`;
const ROOM_CHAT_ACTIVE = (roomId) => `${SERVER_CHAT}/rooms/${roomId}/active`;
const ROOM_CHAT_INACTIVE = (roomId) => `${SERVER_CHAT}/rooms/${roomId}/inactive`;
const ROOM_CHAT_COUNT_BADGES = `${SERVER}/pms/communicate/chat/badges`;

export async function createRoomChatOfApartmentService(idBuilding, room) {
  const response = await api({
    type: API_TYPE_CALL_API_PARSER,
    payload: {
      apiUrl: ROOM_CHAT_APARTMENT_CREATE,
      method: METHOD.POST,
      body: {
        ...room,
      },
      options: {
        headers: { [X_UNIT_GROUP_ID]: idBuilding, [X_UNIT_ID]: room.unitId },
      },
    },
  });
  return response;
}

export async function getListRoomChatService({ idBuilding, filter, page, limit }) {
  const response = await api({
    type: API_TYPE_CALL_API_PARSER,
    payload: {
      apiUrl: ROOM_CHAT_GETS,
      method: METHOD.GET,
      options: {
        params: { ...filter, offset: page, limit },
        headers: { [X_UNIT_GROUP_ID]: idBuilding },
      },
    },
  });
  return response;
}

export async function activeRoomChatService(idBuilding, idRoom) {
  const response = await api({
    type: API_TYPE_CALL_API_PARSER,
    payload: {
      apiUrl: ROOM_CHAT_ACTIVE(idRoom),
      method: METHOD.PUT,
      options: {
        headers: { [X_UNIT_GROUP_ID]: idBuilding },
      },
    },
  });
  return response;
}

export async function inactiveRoomChatService(idBuilding, idRoom) {
  const response = await api({
    type: API_TYPE_CALL_API_PARSER,
    payload: {
      apiUrl: ROOM_CHAT_INACTIVE(idRoom),
      method: METHOD.PUT,
      options: {
        headers: { [X_UNIT_GROUP_ID]: idBuilding },
      },
    },
  });
  return response;
}

export async function getListMemberOfRoomService(idBuilding, idUnit, roomType) {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: ROOM_MEMBER_GETS(idBuilding),
      method: METHOD.GET,
      options: {
        params: { roomType },
        headers: { [X_UNIT_GROUP_ID]: idBuilding, [X_UNIT_ID]: idUnit },
      },
    },
  });
  return response;
}

export async function getListMessageOfRoomService({ idBuilding, idRoom, page, limit }) {
  const response = await api({
    type: API_TYPE_CALL_API_PARSER,
    payload: {
      apiUrl: MESSAGES_GETS(idRoom),
      method: METHOD.GET,
      options: {
        params: { offset: page, limit },
        headers: { [X_UNIT_GROUP_ID]: idBuilding },
      },
    },
  });
  return response;
}

export async function countChatBadgesService(idBuilding) {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: ROOM_CHAT_COUNT_BADGES,
      method: METHOD.GET,
      options: {
        headers: { [X_UNIT_GROUP_ID]: idBuilding },
      },
    },
  });
  return response;
}

export async function readAllMessageOfRoomService(idBuilding, idRoom) {
  const response = await api({
    type: API_TYPE_CALL_API_PARSER,
    payload: {
      apiUrl: MESSAGES_READ_ALL(idRoom),
      method: METHOD.PUT,
      options: {
        headers: { [X_UNIT_GROUP_ID]: idBuilding },
      },
    },
  });
  return response;
}

export async function readMessageByIdService(idBuilding, idRoom, idMessage) {
  const response = await api({
    type: API_TYPE_CALL_API_PARSER,
    payload: {
      apiUrl: MESSAGES_READ_BY_ID(idRoom, idMessage),
      method: METHOD.PUT,
      options: {
        headers: { [X_UNIT_GROUP_ID]: idBuilding },
      },
    },
  });
  return response;
}

export async function sendMessagesService(idBuilding, idRoom, message) {
  const response = await api({
    type: API_TYPE_CALL_API_PARSER,
    payload: {
      apiUrl: MESSAGES_SEND(idRoom),
      method: METHOD.POST,
      body: {
        ...message,
      },
      options: {
        headers: { [X_UNIT_GROUP_ID]: idBuilding },
      },
    },
  });
  return response;
}
