import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

const useResetPage = (resetFunc = () => {}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      dispatch(resetFunc());
    };
  }, []);
};

export default useResetPage;
