const getPageByTotalAndLimit = (totalItems = 0, limit = 0, currentPage = 1) => {
  if (currentPage === 1 || totalItems <= 0 || limit === 0) return 1;
  const totalPage = Number(Math.ceil(Number(totalItems) / Number(limit)));
  if (totalPage <= currentPage) {
    return totalPage;
  }
  return currentPage;
};

export default getPageByTotalAndLimit;
