import { language } from '../utils/language';
import convertArrToObj from '../utils/convertArrToObj';

export const BILL_CREATE_TYPE_ONE_MONTH = 'ONE_MONTH';
export const BILL_CREATE_TYPE_MORE_ONE_MONTH = 'FROM_MONTH_TO_MONTH';
export const BILL_CREATE_TYPE_PART_OF_MONTH = 'PART_OF_MONTH';
export const BILL_CREATE_TYPE_CUSTOM_HALF_OF_MONTH = 'CUSTOM_HALF_OF_MONTH';
export const BILL_CREATE_TYPE_ONE_QUARTER = 'ONE_QUARTER';

export const BILL_CREATE_TYPES = [
  { id: BILL_CREATE_TYPE_ONE_MONTH, name: language.one_month },
  { id: BILL_CREATE_TYPE_ONE_QUARTER, name: language.quarter },
  { id: BILL_CREATE_TYPE_MORE_ONE_MONTH, name: language.from_month_to_month },
  { id: BILL_CREATE_TYPE_PART_OF_MONTH, name: language.from_date_to_date },
  { id: BILL_CREATE_TYPE_CUSTOM_HALF_OF_MONTH, name: language.half_of_month },
];

export const BILL_TYPE_FULL_MONTH = 'FULL_MONTH';
export const BILL_TYPE_PART_OF_MONTH = 'PART_OF_MONTH';
export const BILL_TYPE_CUSTOM_HALF_OF_MONTH = 'CUSTOM_HALF_OF_MONTH';
export const BILL_TYPE_QUARTER = 'QUARTER';

export const BILL_TYPE = [
  { id: 'FULL_MONTH', name: language.label.bill_full_month },
  { id: 'PART_OF_MONTH', name: language.label.bill_part_of_month },
  { id: 'CUSTOM_HALF_OF_MONTH', name: language.bill_half_of_month },
  { id: 'QUARTER', name: language.bill_quarter },
];

export const BILL_TYPE_BY_ID = convertArrToObj(BILL_TYPE, 'id');

export const BILL_ACTIVE = 'ACTIVE'; // Bill active
export const BILL_DISABLED = 'DISABLED'; // Bill has been deleted
export const BILL_DRAFT = 'DRAFT'; // Bill draff
export const BILL_CANCELLED = 'CANCELLED'; // Bill has been cancelled

export const BILL_PAID = 'PAID'; // Bill has been paid
export const BILL_UNPAID = 'UNPAID'; // Bill unpaid
export const BILL_IN_PROCESS = 'IN_PROCESS'; // Bill is in processing
export const BILL_PARTIAL_PAID = 'PARTIAL_PAID'; // Bill has been partial paid
export const EMPTY = 'EMPTY'; // Bill is adjust down

export const BILL_INCURRED_CREATED_FOR_INVALID = 'BILL_INCURRED_CREATED_FOR_INVALID';
export const EXISTS_ADJUST_BILL_HAVE_NOT_CANCELLED_YET = 'EXISTS_ADJUST_BILL_HAVE_NOT_CANCELLED_YET';
export const ORIGIN_BILL_CAN_NOT_ADJUST = 'ORIGIN_BILL_CAN_NOT_ADJUST';
export const ADJUST_BILL_CAN_NOT_UPDATE = 'ADJUST_BILL_CAN_NOT_UPDATE';
export const BILL_CREATED_BEFORE_LOCK_DATE = 'BILL_CREATED_BEFORE_LOCK_DATE';
export const DEBT_MONTH_TYPE = 'DEBT_MONTH';
export const INCURRED_MONTH_TYPE = 'INCURRED_MONTH';
export const FEE_NOTIFICATION_MONTH_TYPE = 'FEE_NOTIFICATION_MONTH';

export const BILL_STATE_OPTIONS = [
  { id: BILL_ACTIVE, name: language.released, color: 'success' },
  { id: BILL_DRAFT, name: language.not_released, color: 'processing' },
  { id: BILL_CANCELLED, name: language.cancelled, color: 'default' },
];
export const BILL_STATE_OBJ = convertArrToObj(BILL_STATE_OPTIONS, 'id');

export const BILL_PAYMENT_STATUS_RECEIVABLES = 'RECEIVABLES';
export const BILL_PAYMENT_STATUS_PAID = 'PAID';
export const BILL_PAYMENT_STATUS_REMAINING_RECEIVABLES = 'REMAINING_RECEIVABLES';
export const BILL_PAYMENT_STATUS_OPTIONS = [
  { id: BILL_PAYMENT_STATUS_RECEIVABLES, name: language.receivables },
  { id: BILL_PAYMENT_STATUS_PAID, name: language.paid },
  { id: BILL_PAYMENT_STATUS_REMAINING_RECEIVABLES, name: language.remaining_receivables },
];

export const BILL_PAYMENT_STATE_OPTIONS = [
  { id: BILL_UNPAID, name: language.label.unpaid },
  { id: BILL_PAID, name: language.label.paid },
  { id: BILL_PARTIAL_PAID, name: language.label.partial_paid },
  { id: EMPTY, name: language.label.empty },
];

export const BILL_PAYMENT_STATE_OBJ = convertArrToObj(BILL_PAYMENT_STATE_OPTIONS, 'id');

export const BILL_TYPE_ADJUST_UP = 'ADJUST_UP';
export const BILL_TYPE_ADJUST_DOWN = 'ADJUST_DOWN';

export const ADJUST_TYPE = [
  { id: BILL_TYPE_ADJUST_UP, name: language.label.adjust_up },
  { id: BILL_TYPE_ADJUST_DOWN, name: language.label.adjust_down },
];

export const INVOICE_BILL_TYPE_BILL_FILTER = [
  { id: BILL_TYPE_ADJUST_UP, name: language.label.voucher_adjust_up },
  { id: BILL_TYPE_ADJUST_DOWN, name: language.label.voucher_adjust_down },
  { id: BILL_TYPE_FULL_MONTH, name: language.label.bill_full_month },
  { id: BILL_TYPE_PART_OF_MONTH, name: language.label.bill_part_of_month },
  { id: BILL_TYPE_CUSTOM_HALF_OF_MONTH, name: language.bill_half_of_month },
  { id: BILL_TYPE_QUARTER, name: language.bill_quarter },
];

export const INVOICE_BILL_ADJUST = [BILL_TYPE_ADJUST_UP, BILL_TYPE_ADJUST_DOWN];

export const INVOICE_TYPE_CREATED_FOR = [
  { id: DEBT_MONTH_TYPE, name: language.label.debt_month1 },
  { id: FEE_NOTIFICATION_MONTH_TYPE, name: language.label.fee_notification_month },
];

export const BILL_ACTION_CREATE_TYPE_AUTO = 'AUTO';
export const BILL_ACTION_CREATE_TYPE_MANUAL = 'MANUAL';

export const BILL_ACTION_CREATE_TYPES = [
  { id: BILL_ACTION_CREATE_TYPE_AUTO, name: language.auto },
  { id: BILL_ACTION_CREATE_TYPE_MANUAL, name: language.manual },
];

export const BILL_ACTION_CREATE_TYPE_OBJ = convertArrToObj(BILL_ACTION_CREATE_TYPES, 'id');

export const BILL_LIST_DEFAULT_FILTER = {
  createdForType: FEE_NOTIFICATION_MONTH_TYPE,
  time: [],
  billType: [],
  state: BILL_ACTIVE,
  paymentState: BILL_PAYMENT_STATUS_REMAINING_RECEIVABLES,
  code: '',
  unitId: undefined,
  unitType: '',
  serviceIds: [],
  createType: [BILL_ACTION_CREATE_TYPE_AUTO, BILL_ACTION_CREATE_TYPE_MANUAL],
  customerIdentifier: '',
  debtDateFirstTime: [],
  debtDateSecondTime: [],
  debtDateThirdTime: [],
  reportAt: [],
};

export const BUILDING_USE_BILL_MANUAL = [301];

export const QUARTER_TYPE = [
  {
    id: 3,
    name: language.full_quarter,
  },
  {
    id: 2.5,
    name: language.two_and_a_half_month,
  },
  {
    id: 2,
    name: language.two_month,
  },
  {
    id: 1.5,
    name: language.one_and_a_half_month,
  },
  {
    id: 1,
    name: language.one_month1,
  },
  {
    id: 0.5,
    name: language.half_month,
  },
];

export const QUARTER_TYPE_OBJ = convertArrToObj(QUARTER_TYPE, 'id');

export const REPORT_TIME_TYPE_MONTH = 'MONTH';
export const REPORT_TIME_TYPE_DAY = 'DAY';

export const REPORT_TIME_TYPE = [
  { id: REPORT_TIME_TYPE_DAY, name: language.fixed_date_accounting },
  { id: REPORT_TIME_TYPE_MONTH, name: language.accounting_by_month },
];
