const formatCurrency = (value) => {
  let valueTmp = value;
  let isNegative = false;
  if (Number.isNaN(Number(value))) {
    valueTmp = 0;
  }
  if (valueTmp < 0) {
    valueTmp *= -1;
    isNegative = true;
  }
  const formatter = new Intl.NumberFormat('vi-VN', {
    style: 'currency',
    currency: 'VND',
  });
  return isNegative ? `(${formatter.format(valueTmp)})` : formatter.format(valueTmp);
};

export default formatCurrency;
