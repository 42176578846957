export default function bytesToSize(bytes) {
  if (!bytes && bytes !== 0) {
    return '';
  }
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return '0 Byte';
  // eslint-disable-next-line radix
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  // eslint-disable-next-line no-restricted-properties
  return `${Math.round(bytes / Math.pow(1024, i), 2)} ${sizes[i]}`;
}
