import { APARTMENT_DETAIL } from './apartment-detail.types';

export const initState = {
  info: undefined,
  residents: [],
  documents: [],
  services: [],
  transactions: [],
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case APARTMENT_DETAIL.REFRESH: {
      return {
        ...initState,
      };
    }
    case APARTMENT_DETAIL.GET.SUCCESS: {
      return {
        ...state,
        info: action.payload.data
          ? {
              ...action.payload.data,
            }
          : undefined,
      };
    }
    case APARTMENT_DETAIL.RESIDENT.GETS.SUCCESS: {
      return {
        ...state,
        residents: action.payload.data,
      };
    }
    case APARTMENT_DETAIL.DOCUMENT.GETS.SUCCESS: {
      return {
        ...state,
        documents: action.payload.data,
      };
    }
    case APARTMENT_DETAIL.SERVICE.GETS.SUCCESS: {
      return {
        ...state,
        services: action.payload.data,
      };
    }
    case APARTMENT_DETAIL.TRANSACTION.GETS.SUCCESS: {
      return {
        ...state,
        transactions: action.payload.data,
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
