/** @jsx jsx */
import { jsx } from '@emotion/react';
import PropTypes from 'prop-types';
import Tooltip from 'components/Tooltip';

function Icon(props) {
  const { title, rootClassName, className, style, css, name, onClick, disabled } = props;
  return (
    <Tooltip title={title}>
      <span
        css={{
          display: 'inline-block',
          color: 'inherit',
          fontStyle: 'normal',
          lineHeight: 1,
          textAlign: 'center',
          textTransform: 'none',
          verticalAlign: '-.125em',
          textRendering: 'optimizeLegibility',
          WebkitFontSmoothing: 'antialiased',
          cursor: 'pointer',
        }}
        className={`${rootClassName} custom-icon`}
      >
        <span
          onKeyPress={onClick}
          onClick={onClick}
          role="button"
          tabIndex="0"
          className={`${name} ${disabled ? 'disabled' : ''} ${className}`}
          style={style}
          css={css}
        />
      </span>
    </Tooltip>
  );
}

Icon.propTypes = {
  title: PropTypes.string,
  name: PropTypes.string,
  rootClassName: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.any),
  css: PropTypes.objectOf(PropTypes.any),
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

Icon.defaultProps = {
  title: '',
  rootClassName: '',
  className: '',
  style: {},
  css: {},
  onClick: () => {},
  name: '',
  disabled: false,
};

export default Icon;
