import styled from '@emotion/styled';

const Spinner = styled.div`
  border: 2px solid #ccc;
  border-radius: 60%;
  border-top-color: #3487cb;
  border-bottom-color: #3487cb;
  -webkit-animation: round 1.5s linear infinite;
  -o-animation: round 1.5s linear infinite;
  animation: round 1.5s linear infinite;
  width: ${(props) => props.width};
  height: ${(props) => props.height};

  @keyframes round {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

export default Spinner;
