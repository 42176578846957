import { all, call, put, takeLatest } from 'redux-saga/effects';
import { getApartmentByIdService } from 'services/apartment';
import { getResidentsService, removeResidentService } from 'services/resident';
import { getDocumentsService, removeDocumentService, addDocumentService } from 'services/document';
import { APARTMENT_DETAIL } from './apartment-detail.types';

function* getApartment(action) {
  const { idBuilding, idApartment, refreshState } = action.payload;
  try {
    const response = yield call(getApartmentByIdService, {
      idBuilding,
      idApartment,
    });
    refreshState();
    yield put({
      type: APARTMENT_DETAIL.GET.SUCCESS,
      payload: response,
    });
  } catch (error) {
    refreshState();
    yield put({
      type: APARTMENT_DETAIL.GET.FAIL,
      payload: error,
    });
  }
}

function* getResidents(action) {
  const { idBuilding, idApartment, refreshState } = action.payload;
  const filter = {
    unitId: idApartment,
  };
  try {
    const response = yield call(getResidentsService, {
      idBuilding,
      filter,
    });
    refreshState();
    yield put({
      type: APARTMENT_DETAIL.RESIDENT.GETS.SUCCESS,
      payload: response,
    });
  } catch (error) {
    refreshState();
    yield put({
      type: APARTMENT_DETAIL.RESIDENT.GETS.FAIL,
      payload: error,
    });
  }
}

function* removeResident(action) {
  const { idBuilding, idResident, refreshState } = action.payload;
  try {
    const response = yield call(removeResidentService, {
      idBuilding,
      idResidents: [idResident],
      filter: {},
      isAll: false,
    });
    refreshState({
      code: response.code,
    });
    yield put({
      type: APARTMENT_DETAIL.RESIDENT.REMOVE.SUCCESS,
      payload: response,
    });
  } catch (error) {
    refreshState({
      code: error.code,
      codeLanguage: error.codeLanguage,
    });
    yield put({
      type: APARTMENT_DETAIL.RESIDENT.REMOVE.FAIL,
      payload: error,
    });
  }
}

function* getDocuments(action) {
  const { idBuilding, idApartment, refreshState } = action.payload;
  try {
    const response = yield call(
      getDocumentsService,
      {
        unitId: idApartment,
        type: 'UNIT_DOCUMENT',
      },
      idBuilding
    );
    refreshState();
    yield put({
      type: APARTMENT_DETAIL.DOCUMENT.GETS.SUCCESS,
      payload: response,
    });
  } catch (error) {
    refreshState();
    yield put({
      type: APARTMENT_DETAIL.DOCUMENT.GETS.FAIL,
      payload: error,
    });
  }
}

function* addDocument(action) {
  const { idBuilding, document, refreshState } = action.payload;
  try {
    const response = yield call(addDocumentService, document, idBuilding);
    refreshState({
      code: response.code,
    });
    yield put({
      type: APARTMENT_DETAIL.DOCUMENT.ADD.SUCCESS,
      payload: response,
    });
  } catch (error) {
    refreshState({
      code: error.code,
      codeLanguage: error.codeLanguage,
    });
    yield put({
      type: APARTMENT_DETAIL.DOCUMENT.ADD.FAIL,
      payload: error,
    });
  }
}

function* removeDocument(action) {
  const { idBuilding, idDocument, refreshState } = action.payload;
  try {
    const response = yield call(removeDocumentService, idDocument, idBuilding);
    refreshState({
      code: response.code,
    });
    yield put({
      type: APARTMENT_DETAIL.DOCUMENT.REMOVE.SUCCESS,
      payload: response,
    });
  } catch (error) {
    refreshState({
      code: error.code,
      codeLanguage: error.codeLanguage,
    });
    yield put({
      type: APARTMENT_DETAIL.DOCUMENT.REMOVE.SUCCESS,
      payload: error,
    });
  }
}

export default function* watch() {
  yield all([
    takeLatest(APARTMENT_DETAIL.GET.REQUEST, getApartment),
    takeLatest(APARTMENT_DETAIL.RESIDENT.GETS.REQUEST, getResidents),
    takeLatest(APARTMENT_DETAIL.DOCUMENT.GETS.REQUEST, getDocuments),
    takeLatest(APARTMENT_DETAIL.RESIDENT.REMOVE.REQUEST, removeResident),
    takeLatest(APARTMENT_DETAIL.DOCUMENT.REMOVE.REQUEST, removeDocument),
    takeLatest(APARTMENT_DETAIL.DOCUMENT.ADD.REQUEST, addDocument),
  ]);
}
