import { language } from '../utils/language';

export const CONFIG_BUILDING_MAIN_MENUS_BUILDING = 'CONFIG_BUILDING_MAIN_MENUS_BUILDING';
export const CONFIG_BUILDING_MAIN_MENUS_SUBDIVISION = 'CONFIG_BUILDING_MAIN_MENUS_SUBDIVISION';
export const CONFIG_BUILDING_MAIN_MENUS_BLOCK = 'CONFIG_BUILDING_MAIN_MENUS_BLOCK';
export const CONFIG_BUILDING_MAIN_MENUS_FLOOR = 'CONFIG_BUILDING_MAIN_MENUS_FLOOR';
export const CONFIG_BUILDING_MAIN_MENUS_APARTMENT = 'CONFIG_BUILDING_MAIN_MENUS_APARTMENT';
export const CONFIG_BUILDING_MAIN_MENUS_RENTAL_AREA = 'CONFIG_BUILDING_MAIN_MENUS_RENTAL_AREA';
export const CONFIG_BUILDING_MAIN_MENUS_RENTAL_LOCATION = 'CONFIG_BUILDING_MAIN_MENUS_RENTAL_LOCATION';

export const BUILDING_TYPE_AREA = 'AREA';
export const BUILDING_TYPE_BLOCK = 'BLOCK';
export const BUILDING_TYPE_CO_WORKING_SPACE = 'CO_WORKING_SPACE';
export const BUILDING_TYPE_BUILDING = 'BUILDING';

export const DEFAULT_CONFIG_BUILDING_SUBDIVISION_FILTER = {
  name: undefined,
};

export const DEFAULT_CONFIG_BUILDING_BLOCK_FILTER = {
  name: '',
  subdivisionId: '',
};

export const DEFAULT_CONFIG_BUILDING_FLOOR_FILTER = {
  name: '',
  subdivisionId: '',
  blockId: '',
};

export const DEFAULT_CONFIG_BUILDING_APARTMENT_FILTER = {
  name: '',
  subdivisionId: '',
  blockId: '',
  floorId: '',
  apartmentTypeId: '',
  buildStatus: '',
  dateOfTakingApartment: [],
};

export const CONFIG_BUILDING_MAIN_MENUS = [
  {
    label: language.building,
    value: CONFIG_BUILDING_MAIN_MENUS_BUILDING,
  },
  {
    label: language.block,
    value: CONFIG_BUILDING_MAIN_MENUS_BLOCK,
  },
  {
    label: language.floor,
    value: CONFIG_BUILDING_MAIN_MENUS_FLOOR,
  },
  {
    label: language.location,
    value: CONFIG_BUILDING_MAIN_MENUS_RENTAL_LOCATION,
  },
];

export const CONFIG_TEMPLATE_MAIN_MENUS_INVOICE = 'INVOICE';
export const CONFIG_TEMPLATE_MAIN_MENUS_RECEIPT = 'RECEIPT';
export const CONFIG_TEMPLATE_MAIN_MENUS_PAYSLIP = 'PAYSLIP';
export const CONFIG_TEMPLATE_MAIN_MENUS = [
  {
    label: language.fee_notifications,
    value: CONFIG_TEMPLATE_MAIN_MENUS_INVOICE,
  },
  {
    label: language.receipt,
    value: CONFIG_TEMPLATE_MAIN_MENUS_RECEIPT,
  },
  {
    label: language.payslip,
    value: CONFIG_TEMPLATE_MAIN_MENUS_PAYSLIP,
  },
];
