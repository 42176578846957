import { useEffect } from 'react';
import notification from 'components/Notification';
import { language } from '../utils/language';
import { SUCCESS_CODE } from '../constants/http';
import RESPONSE_MAPPING from '../constants/responseMessageMapping';

const useNotificationResponse = (code, codeLanguage, successConfig, callbackSuccess = () => {}) => {
  useEffect(() => {
    if (code) {
      if (SUCCESS_CODE.includes(code) && !codeLanguage) {
        if (successConfig) {
          notification.success(successConfig);
        }
        callbackSuccess();
      } else {
        notification.error({
          message: language.label.error,
          description: RESPONSE_MAPPING[codeLanguage] || RESPONSE_MAPPING.FAIL,
        });
      }
    }
  }, [code]);
};

export default useNotificationResponse;
