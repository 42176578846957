import { language } from '../utils/language';

export const STAFF_STATUS = [
  { id: 'ACTIVE', name: language.status.staff_status_active },
  { id: 'INACTIVE', name: language.status.staff_status_inactive },
  { id: 'PENDING', name: language.status.staff_status_pending },
  { id: 'BANNED', name: language.status.staff_status_banned },
  { id: 'ASSIGNED', name: language.status.staff_status_assigned },
  { id: 'ABSENT', name: language.status.staff_status_absent },
];

export const STAFF_STATUS_BY_ID = {
  ACTIVE: { id: 'ACTIVE', name: language.status.staff_status_active },
  INACTIVE: { id: 'INACTIVE', name: language.status.staff_status_inactive },
  PENDING: { id: 'PENDING', name: language.status.staff_status_pending },
  BANNED: { id: 'BANNED', name: language.status.staff_status_banned },
  ASSIGNED: { id: 'ASSIGNED', name: language.status.staff_status_assigned },
  ABSENT: { id: 'ABSENT', name: language.status.staff_status_absent },
};
