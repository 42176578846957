import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  getIcons,
  getServicesService,
  addServiceService,
  updateServiceService,
  deleteServiceService,
  inactiveServiceService,
  activeServiceService,
} from 'services/service';
import {
  getContractTemplateService,
  getContractTemplateByFormulaService,
  getContractTemplateByPromotionService,
  getContractTemplateBySurchargeService,
} from 'services/contractTemplate';
import { SERVICE } from './service.types';
import { convertData } from './service.utils';
import { SERVICE_STATUS_ACTIVE } from './service.constants';

function* getsService(action) {
  const { unitGroupId } = action.payload;
  try {
    const result = yield call(
      getServicesService,
      {
        idBuilding: unitGroupId,
      },
      SERVICE.GETS
    );
    yield put({
      type: SERVICE.GETS.SUCCESS,
      payload: result,
    });
  } catch (error) {
    yield put({
      type: SERVICE.GETS.FAIL,
      payload: error,
    });
  }
}

function* getsIcon() {
  const result = yield call(getIcons);
  yield put({
    type: SERVICE.ICON_GETS.SUCCESS,
    payload: result,
  });
}

function* addService(action) {
  try {
    const { service, unitGroupId } = action.payload;

    const serviceConvert = convertData(service, unitGroupId);

    const result = yield call(addServiceService, { service: serviceConvert, idBuilding: unitGroupId });
    yield put({
      type: SERVICE.ADD.SUCCESS,
      payload: result,
    });
    yield put({
      type: SERVICE.ADD.REFRESH,
    });
  } catch (error) {
    yield put({
      type: SERVICE.ADD.FAIL,
      payload: error,
    });
  }
}

function* updateService(action) {
  try {
    const { service, unitGroupId } = action.payload;

    const serviceConvert = convertData(service, unitGroupId);

    const result = yield call(updateServiceService, {
      service: serviceConvert,
      idBuilding: unitGroupId,
      idService: service.id,
    });
    yield put({
      type: SERVICE.UPDATE.SUCCESS,
      payload: result,
    });
    yield put({
      type: SERVICE.UPDATE.REFRESH,
    });
  } catch (error) {
    yield put({
      type: SERVICE.UPDATE.FAIL,
      payload: error,
    });
  }
}

function* deleteService(action) {
  const { id, unitGroupId, refreshState } = action.payload;
  try {
    const result = yield call(deleteServiceService, {
      idService: id,
      idBuilding: unitGroupId,
    });
    yield put({
      type: SERVICE.DELETE.SUCCESS,
      payload: result,
    });
    yield put({
      type: SERVICE.DELETE.REFRESH,
    });
    refreshState({ code: result.code });
  } catch (error) {
    yield put({
      type: SERVICE.DELETE.FAIL,
      payload: error,
    });
    refreshState({ code: error.code, codeLanguage: error.codeLanguage });
  }
}

function* changeStatusService(action) {
  const { id, unitGroupId, type = SERVICE_STATUS_ACTIVE, refreshState } = action.payload;
  try {
    const changeStatusServiceApi = type === SERVICE_STATUS_ACTIVE ? activeServiceService : inactiveServiceService;

    const result = yield call(changeStatusServiceApi, {
      idService: id,
      idBuilding: unitGroupId,
    });
    yield put({
      type: SERVICE.CHANGE_STATUS.SUCCESS,
      payload: result,
    });
    yield put({
      type: SERVICE.CHANGE_STATUS.REFRESH,
    });
    refreshState({ code: result.code, codeLanguage: result.codeLanguage });
  } catch (error) {
    yield put({
      type: SERVICE.CHANGE_STATUS.FAIL,
      payload: error,
    });
    refreshState({ code: error.code, codeLanguage: error.codeLanguage });
  }
}

function* getContractTemplateByFormula(action) {
  try {
    const { id, unitGroupId, callback } = action.payload;

    const result = yield call(getContractTemplateByFormulaService, {
      filter: {
        formulaId: id,
      },
      idBuilding: unitGroupId,
    });
    if (result?.payload?.data?.length > 0) callback.onError();
    else callback.onFinish();

    yield put({
      type: SERVICE.CONTRACT_TEMPLATE_BY_FORMULA_GETS.SUCCESS,
      payload: result,
    });
  } catch (error) {
    yield put({
      type: SERVICE.CONTRACT_TEMPLATE_BY_FORMULA_GETS.FAIL,
      payload: error,
    });
  }
}

function* getContractTemplateBySurcharge(action) {
  try {
    const { id, unitGroupId, callback } = action.payload;

    const result = yield call(getContractTemplateBySurchargeService, {
      filter: {
        surchargeId: id,
      },
      idBuilding: unitGroupId,
    });

    if (result?.payload?.data?.length > 0) callback.onError();
    else callback.onFinish();

    yield put({
      type: SERVICE.CONTRACT_TEMPLATE_BY_SURCHARGE_GETS.SUCCESS,
      payload: result,
    });
  } catch (error) {
    yield put({
      type: SERVICE.CONTRACT_TEMPLATE_BY_SURCHARGE_GETS.FAIL,
      payload: error,
    });
  }
}

function* getContractTemplateByPromotion(action) {
  try {
    const { id, unitGroupId, callback } = action.payload;

    const result = yield call(getContractTemplateByPromotionService, {
      filter: {
        promotionId: id,
      },
      idBuilding: unitGroupId,
    });

    if (result?.payload?.data?.length > 0) callback.onError();
    else callback.onFinish();

    yield put({
      type: SERVICE.CONTRACT_TEMPLATE_BY_PROMOTION_GETS.SUCCESS,
      payload: result,
    });
  } catch (error) {
    yield put({
      type: SERVICE.CONTRACT_TEMPLATE_BY_PROMOTION_GETS.FAIL,
      payload: error,
    });
  }
}

function* getContractTemplateByService(action) {
  try {
    const { id, unitGroupId } = action.payload;

    const result = yield call(getContractTemplateService, {
      filter: {
        serviceId: id,
      },
      idBuilding: unitGroupId,
    });

    result.id = id;

    yield put({
      type: SERVICE.CONTRACT_TEMPLATE_BY_SERVICE_GETS.SUCCESS,
      payload: result,
    });
  } catch (error) {
    yield put({
      type: SERVICE.CONTRACT_TEMPLATE_BY_SERVICE_GETS.FAIL,
      payload: error,
    });
  }
}

export default function* watch() {
  yield all([
    takeLatest(SERVICE.GETS.REQUEST, getsService),
    takeLatest(SERVICE.ICON_GETS.REQUEST, getsIcon),
    takeLatest(SERVICE.ADD.REQUEST, addService),
    takeLatest(SERVICE.UPDATE.REQUEST, updateService),
    takeLatest(SERVICE.DELETE.REQUEST, deleteService),
    takeLatest(SERVICE.CHANGE_STATUS.REQUEST, changeStatusService),
    takeLatest(SERVICE.CONTRACT_TEMPLATE_BY_FORMULA_GETS.REQUEST, getContractTemplateByFormula),
    takeLatest(SERVICE.CONTRACT_TEMPLATE_BY_SURCHARGE_GETS.REQUEST, getContractTemplateBySurcharge),
    takeLatest(SERVICE.CONTRACT_TEMPLATE_BY_PROMOTION_GETS.REQUEST, getContractTemplateByPromotion),
    takeLatest(SERVICE.CONTRACT_TEMPLATE_BY_SERVICE_GETS.REQUEST, getContractTemplateByService),
  ]);
}
