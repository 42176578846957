import { language } from '../utils/language';
import convertArrToObj from '../utils/convertArrToObj';

export const APARTMENT_BUILD_STATUS_OPTIONS = [
  {
    id: 'COMPLETE',
    name: language.label.complete1,
  },
  {
    id: 'RAW',
    name: language.label.raw_building,
  },
  {
    id: 'HANDOVER_KEY',
    name: language.handover_key,
  },
  {
    id: 'FURNITURE_CONSTRUCTING',
    name: language.furniture_constructing,
  },
  {
    id: 'USAGE_BY_HOUSEHOLDER',
    name: language.usage_by_admin,
  },
  {
    id: 'USAGE_BY_RENTER',
    name: language.usage_by_renter,
  },
  {
    id: 'HANDOVER',
    name: language.handover,
  },
  {
    id: 'HAVE_NOT_YET_HANDOVER',
    name: language.have_not_yet_handover,
  },
];

export const APARTMENT_BUILD_STATUS_BY_ID = convertArrToObj(APARTMENT_BUILD_STATUS_OPTIONS, 'id');

export const APARTMENT_TYPE_OPTIONS = [
  {
    id: 'VILLA',
    name: language.label.villa,
  },
  {
    id: 'APARTMENT',
    name: language.label.apartment2,
  },
  {
    id: 'SERVICE',
    name: language.label.service_apartment,
  },
  {
    id: 'OWNER',
    name: language.label.owner1,
  },
  {
    id: 'RENT',
    name: language.label.rent,
  },
  {
    id: 'SHOP',
    name: language.label.shop,
  },
];

export const APARTMENT_TYPE_BY_ID = convertArrToObj(APARTMENT_TYPE_OPTIONS, 'id');

export const APARTMENT_STATUS_EMPTY = 'EMPTY';
export const APARTMENT_STATUS_IN_USE = 'IN_USE';
export const APARTMENT_STATUS_OPTIONS = [
  {
    id: APARTMENT_STATUS_EMPTY,
    name: language.label.empty,
  },
  {
    id: APARTMENT_STATUS_IN_USE,
    name: language.label.in_use,
  },
];

export const DEFAULT_APARTMENT_FILTER = {
  floorId: '',
  apartmentType: '',
  status: '',
  name: '',
  customerCode: '',
  owner: '',
  buildStatus: '',
  dateOfTakingApartment: [],
};
