const templateDataForImport = {
  // Device
  meterNumber: {
    vi: ['DH-0001', 'DH-0002', 'DH-0003'],
    en: ['DH-0001', 'DH-0002', 'DH-0003'],
  },

  contractTemplateName: {
    vi: ['Biểu phí 1', 'Biểu phí 2', 'Biểu phí 3'],
    en: ['contract template 1', 'contract template 2', 'contract template 3'],
  },

  status: {
    vi: ['Hoạt động', 'Ngừng hoạt động'],
    en: ['active', 'inactive'],
  },

  unitName: {
    vi: ['Đơn vị thuê 101', 'Đơn vị thuê 102', 'Đơn vị thuê 103'],
    en: ['Gorgeous', 'Loving', 'Polite'],
  },

  customerIdentifier: {
    vi: ['KH-0001', 'KH-0002', 'KH-0003'],
    en: ['CS-0001', 'CS-0002', 'CS-0003'],
  },

  // Adjustment
  totalPayment: {
    vi: [200000, 300000, 400000],
    en: [200000, 300000, 400000],
  },
  adjustmentCreatedFor: {
    vi: ['06/2024', '07/2024', '08/2024'],
    en: ['06/2024', '07/2024', '08/2024'],
  },
  incurredCreatedFor: {
    vi: ['07/2024', '07/2024', '07/2024'],
    en: ['10/2024', '11/2024', '12/2024'],
  },
  reportAt: {
    vi: ['07/07/2024', '07/05/2024', '18/06/2024'],
    en: ['16/07/2024', '17/06/2024', '18/05/2024'],
  },
  reason: {
    vi: ['Lý do A', 'Lý do B', 'Lý do C'],
    en: ['Reason A', 'Reason B', 'Reason C'],
  },

  servicePrice: {
    vi: [10000, 20000, 30000],
    en: [10000, 20000, 30000],
  },

  // Bill
  serviceName: {
    vi: ['Ăn chơi 247', 'Gửi xe', 'Clean home'],
    en: ['Play247', 'Parking', 'Clean Home'],
  },

  code: {
    vi: ['CODE-0001', 'CODE-0002', 'CODE-0003'],
    en: ['CODE-0001', 'CODE-0002', 'CODE-0003'],
  },

  createdFor: {
    vi: ['11/2024', '11/2024', '11/2024'],
    en: ['11/2024', '11/2024', '11/2024'],
  },

  dueDate: {
    vi: ['07/12/2024', '08/12/2024', '09/12/2024'],
    en: ['07/12/2024', '08/12/2024', '09/12/2024'],
  },

  total: {
    vi: ['10000', '20000', '30000'],
    en: ['10000', '20000', '30000'],
  },

  note: {
    vi: ['Cập nhật thông tin phí dịch vụ', 'Giá tiền gốc không còn phù hợp', 'Thay đổi gía tiền cho phù hợp hơn'],
    en: ['Update information service fee', 'The original price is no longer suitable', 'Change the price to be more suitable'],
  },

  // Visitor
  name: {
    vi: ['Hoàng', 'Sang', 'Quang'],
    en: ['Hoang', 'Sang', 'Quang'],
  },

  passPort: {
    vi: ['987763356', '987763357', '987763358'],
    en: ['987763356', '987763357', '987763358'],
  },

  phone: {
    vi: ['0982436587', '0982436588', '0982436589'],
    en: ['0982436587', '0982436588', '0982436589'],
  },

  email: {
    vi: ['angle@gmail.com', 'elite@gmail.com', 'john@gmail.com'],
    en: ['angle@gmail.com', 'elite@gmail.com', 'john@gmail.com'],
  },

  contactPersonName: {
    vi: ['Nguyễn Văn A', 'Nguyễn Văn B', 'Nguyễn Văn C'],
    en: ['A Van Nguyen', 'B Van Nguyen', 'C Van Nguyen'],
  },

  accessCard: {
    vi: ['A1234', 'A1235', 'A1236'],
    en: ['A1234', 'A1235', 'A1236'],
  },

  guestType: {
    vi: ['Khách thường', 'Khách quan trọng', 'Khách thường'],
    en: ['Guest normal', 'Guest Vip', 'Guest normal'],
  },

  expectedIncomingAt: {
    vi: ['06/12/2023 05:00', '07/12/2023 05:00', '08/12/2023 05:00'],
    en: ['06/12/2023 05:00', '07/12/2023 05:00', '08/12/2023 05:00'],
  },

  expectedLeavingAt: {
    vi: ['10/12/2023 05:00', '11/12/2023 05:00', '12/12/2023 05:00'],
    en: ['10/12/2023 05:00', '11/12/2023 05:00', '12/12/2023 05:00'],
  },

  description: {
    vi: ['Ghi chú 1', 'Ghi chú 2', 'Ghi chú 3'],
    en: ['Note1', 'Note2', 'Note3'],
  },
  employeeNote: {
    vi: ['Ghi chú 1', 'Ghi chú 2', 'Ghi chú 3'],
    en: ['Note1', 'Note2', 'Note3'],
  },
};

export default templateDataForImport;
