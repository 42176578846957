export const CH_INSTALLATION = 'ch_installation';

export const MSG_NOTICE = 'msg_notice';

export const NOTICE_TYPE = {
  MSG: 'MSG_NOTICE',
  PAYMENT: 'PAYMENT_NOTICE',
  UTILITY_BOOKING: 'UTILITY_BOOKING_NOTICE',
  ADD: 'ADD_NOTICE',
  REQUEST: 'REQUEST_NOTICE',
  HELP_DESK: 'HELPDESK_NOTICE',
  VISITOR: 'VISITOR_NOTICE',
  ELEVATOR_RESERVATION: 'ELEVATOR_RESERVATION_NOTICE',
  CONSTRUCTION_REGISTRATION: 'CONSTRUCTION_REGISTRATION_NOTICE',
  VEHICLE: 'VEHICLE_NOTICE',
  REGISTRATION_FORM: 'REGISTRATION_FORM_NOTICE',
};
