/** @jsx jsx */
import 'moment/locale/eu';
import 'moment/locale/vi';
import { hot } from 'react-hot-loader';
import { Switch, Route, useLocation } from 'react-router-dom';
import { jsx } from '@emotion/react';
import React, { Suspense, useEffect, useState } from 'react';
import Loading from 'components/Loading';
import { checkMaintenance } from 'services/base';
import PermissionDenied from 'components/PermissionDenied';
import { language } from 'shared/utils';
import { GA_MAPPING } from 'shared/constants';
import { useFcm, useGA } from 'shared/helpers';

const { AUTH_METHOD } = process.env;

const Print = React.lazy(() => import('components/Print'));

const SSOLogin = React.lazy(() => import('containers/SSOAuth/Login'));

const NormalLogin = React.lazy(() => import('containers/Auth/Login'));

const AuthRedirect = React.lazy(() => import('containers/SSOAuth/Redirect'));

const Register = React.lazy(() => import('containers/Auth/Register'));

const ForgotPassword = React.lazy(() => import('containers/Auth/ForgotPassword'));

const NewPassword = React.lazy(() => import('containers/Auth/NewPassword'));

const ApplicationComponent = React.lazy(() => import('containers/Application'));

function Routes() {
  const [loading, setLoading] = useState(true);
  const [maintenance, setMaintenance] = useState(false);
  const location = useLocation();
  const trackingEvent = useGA();
  const { requestPermission, handleCallback } = useFcm();

  useEffect(() => {
    (async () => {
      setLoading(true);

      const result = await checkMaintenance();

      if (result?.data?.status) setMaintenance(true);

      setLoading(false);
    })();
    (async () => {
      await requestPermission();
      await handleCallback();
    })();
  }, []);

  useEffect(() => {
    const { pathname } = location;
    if (pathname) {
      const gaMapping = Object.keys(GA_MAPPING);
      let title = '';
      let screenName = '';
      let buildingId = '';
      for (let index = 0; index < gaMapping.length; index += 1) {
        const match = pathname.match(new RegExp(gaMapping[index]));
        if (match) {
          title = GA_MAPPING[gaMapping[index]];
          screenName = GA_MAPPING[gaMapping[index]];
          buildingId = match?.[1];
          break;
        }
      }
      if (screenName) {
        trackingEvent({
          title,
          screenName,
          location: `${process.env.REACT_APP_URL}/${pathname}`,
          buildingId,
        });
      }
    }
  }, [location]);

  if (loading) return <Loading isLoading />;

  if (maintenance) {
    return <PermissionDenied src="/assets/images/maintenance.png" description={language.system_maintenance} />;
  }

  return (
    <Suspense fallback={<Loading isLoading />}>
      <Switch>
        <Route path="/print" component={Print} />
        <Route path="/login" component={AUTH_METHOD === 'SSO' ? SSOLogin : NormalLogin} />
        <Route path="/register" component={Register} />
        <Route path="/password/forgot" component={ForgotPassword} />
        <Route path="/password/set-new-password" component={NewPassword} />
        <Route path="/auth-redirect" component={AuthRedirect} />
        <Route path="/buildings/:idBuilding/*" component={ApplicationComponent} />
        <Route path="/" component={ApplicationComponent} />
      </Switch>
    </Suspense>
  );
}

export default hot(module)(Routes);
