// Library dispatched action types.
const WEBSOCKET_OPEN = 'WEBSOCKET:OPEN';
const WEBSOCKET_CLOSED = 'WEBSOCKET:CLOSED';
const WEBSOCKET_MESSAGE = 'WEBSOCKET:MESSAGE';

// User dispatched action types.
const WEBSOCKET_CONNECT = 'WEBSOCKET:CONNECT';
const WEBSOCKET_DISCONNECT = 'WEBSOCKET:DISCONNECT';
const WEBSOCKET_SEND = 'WEBSOCKET:SEND';

// Build a Redux action.
const buildAction = (typeName, event) => ({
  type: typeName,
  payload: {
    event,
    timestamp: new Date(),
  },
});

// Action creators.
const open = (event) => buildAction(WEBSOCKET_OPEN, event);
const closed = (event) => buildAction(WEBSOCKET_CLOSED, event);
const message = (event) => buildAction(WEBSOCKET_MESSAGE, event);

/**
 * Instantiate a WebSocket, with it's event listener functions wrapped in
 * a call to `dispatch`.
 */
const createWebSocket = (dispatch, url) => {
  // Instantiate the websocket.
  const ws = new WebSocket(url);

  ws.onopen = (event) => dispatch(open(event));
  ws.onclose = (event) => dispatch(closed(event));
  ws.onmessage = (event) => dispatch(message(event));

  return ws;
};

// Middleware function.
export default () => {
  // Hold a reference to the WebSocket instance in use.
  let webSocket;

  return (store) => (next) => (action) => {
    switch (action.type) {
      // User request to connect
      case WEBSOCKET_CONNECT:
        if (webSocket) {
          webSocket.close();
        }

        webSocket = createWebSocket(store.dispatch, action.payload.url);
        break;

      // User request to disconnect
      case WEBSOCKET_DISCONNECT:
        webSocket.close();
        break;

      // User request to send a message
      case WEBSOCKET_SEND:
        webSocket.send(JSON.stringify(action.payload));
        break;

      default:
        break;
    }

    return next(action);
  };
};
