import { combineReducers } from 'redux';
import { CONFIRM_CHANGE_STATUS_AMENITIES_ORDER } from './confirm-change-status-amenities-order.types';

export const initState = {
  approveAmenitiesOrders: {
    isLoading: false,
    code: undefined,
    codeLanguage: undefined,
  },
  rejectAmenitiesOrders: {
    isLoading: false,
    code: undefined,
    codeLanguage: undefined,
  },
  cancelAmenitiesOrders: {
    isLoading: false,
    code: undefined,
    codeLanguage: undefined,
  },
};

const reducer = combineReducers({
  approveAmenitiesOrders: (state = initState.approveAmenitiesOrders, action) => {
    switch (action.type) {
      case CONFIRM_CHANGE_STATUS_AMENITIES_ORDER.APPROVES.REQUEST: {
        return {
          isLoading: true,
          code: undefined,
          codeLanguage: undefined,
          data: undefined,
        };
      }
      case CONFIRM_CHANGE_STATUS_AMENITIES_ORDER.APPROVES.SUCCESS: {
        return {
          isLoading: false,
          code: action.payload.code,
          codeLanguage: action.payload.codeLanguage,
        };
      }
      case CONFIRM_CHANGE_STATUS_AMENITIES_ORDER.APPROVES.FAIL: {
        return {
          isLoading: false,
          code: action.payload.code,
          codeLanguage: action.payload.codeLanguage,
        };
      }
      case CONFIRM_CHANGE_STATUS_AMENITIES_ORDER.APPROVES.REFRESH: {
        return {
          ...initState.approveAmenitiesOrders,
        };
      }
      default:
        return state;
    }
  },
  rejectAmenitiesOrders: (state = initState.rejectAmenitiesOrders, action) => {
    switch (action.type) {
      case CONFIRM_CHANGE_STATUS_AMENITIES_ORDER.REJECTS.REQUEST: {
        return {
          isLoading: true,
          code: undefined,
          codeLanguage: undefined,
        };
      }
      case CONFIRM_CHANGE_STATUS_AMENITIES_ORDER.REJECTS.SUCCESS: {
        return {
          isLoading: false,
          code: action.payload.code,
          codeLanguage: action.payload.codeLanguage,
        };
      }
      case CONFIRM_CHANGE_STATUS_AMENITIES_ORDER.REJECTS.FAIL: {
        return {
          isLoading: false,
          code: action.payload.code,
          codeLanguage: action.payload.codeLanguage,
        };
      }
      case CONFIRM_CHANGE_STATUS_AMENITIES_ORDER.REFRESH: {
        return {
          ...initState.rejectAmenitiesOrders,
        };
      }
      default:
        return state;
    }
  },
  cancelAmenitiesOrders: (state = initState.cancelAmenitiesOrders, action) => {
    switch (action.type) {
      case CONFIRM_CHANGE_STATUS_AMENITIES_ORDER.CANCEL.REQUEST: {
        return {
          isLoading: true,
          code: undefined,
          codeLanguage: undefined,
        };
      }
      case CONFIRM_CHANGE_STATUS_AMENITIES_ORDER.CANCEL.SUCCESS: {
        return {
          isLoading: false,
          code: action.payload.code,
          codeLanguage: action.payload.codeLanguage,
        };
      }
      case CONFIRM_CHANGE_STATUS_AMENITIES_ORDER.CANCEL.FAIL: {
        return {
          isLoading: false,
          code: action.payload.code,
          codeLanguage: action.payload.codeLanguage,
        };
      }
      case CONFIRM_CHANGE_STATUS_AMENITIES_ORDER.REFRESH: {
        return {
          ...initState.cancelAmenitiesOrders,
        };
      }
      default:
        return state;
    }
  },
});

export default reducer;
