import { API_TYPE_CALL_API, X_UNIT_GROUP_ID, METHOD, SERVER_PAYMENT } from 'shared/constants';
import api from './api';

const GET_LIST_PAYMENT_METHOD_WEB = `${SERVER_PAYMENT}/payment-service/v1/payment-methods/web`;
const PAYMENT_SPOS = `${SERVER_PAYMENT}/payment-service/v1/payment-orders/unitgroup-service/vnpay-smart-spos`;

export const getListPaymentMethodWebService = async ({ idBuilding }) => {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: GET_LIST_PAYMENT_METHOD_WEB,
      method: METHOD.GET,
      options: {
        headers: {
          [X_UNIT_GROUP_ID]: idBuilding,
        },
      },
    },
  });
  return response;
};

export const createPaymentSposService = async ({ unitId, idBuilding, bills, payerId, paymentMethodId }) => {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: PAYMENT_SPOS,
      body: {
        cyhomeBillIds: bills,
        residentId: payerId,
        paymentMethodId,
        unitId,
      },
      method: METHOD.POST,
      options: {
        headers: {
          [X_UNIT_GROUP_ID]: idBuilding,
        },
      },
    },
  });
  return response;
};
