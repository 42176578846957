import { CONFIG_FLOOR_GETS, CONFIG_FLOOR_PAGE_REFRESH, CONFIG_FLOOR_SUBDIVISION_GETS, CONFIG_FLOOR_BLOCK_GETS } from './floor.types';

export const initState = {
  data: [],
  totalData: 0,
  subdivisions: [],
  blocks: [],
};

const floor = (state = initState, action) => {
  switch (action.type) {
    case CONFIG_FLOOR_PAGE_REFRESH: {
      return {
        ...initState,
      };
    }
    case CONFIG_FLOOR_GETS.SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        totalData: action.payload.totalRecord,
      };
    }
    case CONFIG_FLOOR_SUBDIVISION_GETS.SUCCESS: {
      return {
        ...state,
        subdivisions: action.payload.data,
      };
    }
    case CONFIG_FLOOR_BLOCK_GETS.SUCCESS: {
      return {
        ...state,
        blocks: action.payload.data,
      };
    }
    default: {
      return state;
    }
  }
};

export default floor;
