import { combineReducers } from 'redux';
import { SERVICE } from './service.types';
import { getAllServiceSystem } from './service.utils';

export const initState = {
  get: {
    isLoading: false,
    code: undefined,
    codeLanguage: undefined,
    data: undefined,
  },
  gets: {
    isLoading: false,
    code: undefined,
    codeLanguage: undefined,
    totalRecord: 0,
    data: [],
  },
  add: {
    isLoading: false,
    code: undefined,
    codeLanguage: undefined,
  },
  update: {
    isLoading: false,
    code: undefined,
    codeLanguage: undefined,
  },
  delete: {
    isLoading: false,
    code: undefined,
    codeLanguage: undefined,
  },
  changeStatus: {
    isLoading: false,
    code: undefined,
    codeLanguage: undefined,
  },
  icons: {
    isLoading: false,
    code: undefined,
    codeLanguage: undefined,
    data: [],
  },
  contractTemplateByFormula: {
    isLoading: false,
    code: undefined,
    codeLanguage: undefined,
    data: [],
  },
  contractTemplateBySurcharge: {
    isLoading: false,
    code: undefined,
    codeLanguage: undefined,
    data: [],
  },
  contractTemplateByPromotion: {
    isLoading: false,
    code: undefined,
    codeLanguage: undefined,
    data: [],
  },
  contractTemplateByService: {
    isLoading: false,
    code: undefined,
    codeLanguage: undefined,
    data: [],
    serviceId: 0,
  },
};

const reducer = combineReducers({
  get: (state = initState.get, action) => {
    switch (action.type) {
      case SERVICE.GET.REQUEST:
        return {
          ...state,
          code: undefined,
          codeLanguage: undefined,
          isLoading: true,
        };
      case SERVICE.GET.SUCCESS:
        return {
          ...state,
          code: action.payload.code,
          codeLanguage: action.payload.codeLanguage,
          isLoading: false,
          data: action.payload.data,
        };
      case SERVICE.GET.FAIL:
        return {
          ...state,
          code: action.payload.code,
          codeLanguage: action.payload.codeLanguage,
          isLoading: false,
          data: undefined,
        };
      case SERVICE.GET.REFRESH:
        return {
          ...initState.get,
        };
      default:
        return state;
    }
  },
  gets: (state = initState.gets, action) => {
    switch (action.type) {
      case SERVICE.GETS.REQUEST:
        return {
          ...state,
          code: undefined,
          codeLanguage: undefined,
          isLoading: true,
        };
      case SERVICE.GETS.SUCCESS:
        return {
          ...state,
          code: action.payload.code,
          codeLanguage: action.payload.codeLanguage,
          isLoading: false,
          data: action.payload.data ? getAllServiceSystem(action.payload.data) : [],
          totalRecord: action.payload.totalRecord ? getAllServiceSystem(action.payload.data).length : 0,
        };
      case SERVICE.GETS.FAIL:
        return {
          ...state,
          code: action.payload.code,
          codeLanguage: action.payload.codeLanguage,
          isLoading: false,
          data: [],
          totalRecord: 0,
        };
      case SERVICE.GETS.REFRESH:
        return {
          ...initState.gets,
        };
      default:
        return state;
    }
  },
  add: (state = initState.add, action) => {
    switch (action.type) {
      case SERVICE.ADD.REQUEST:
        return {
          ...state,
          code: undefined,
          codeLanguage: undefined,
          isLoading: true,
        };
      case SERVICE.ADD.SUCCESS:
        return {
          ...state,
          code: action.payload.code,
          codeLanguage: action.payload.codeLanguage,
          isLoading: false,
        };
      case SERVICE.ADD.FAIL:
        return {
          ...state,
          code: action.payload.code,
          codeLanguage: action.payload.codeLanguage,
          isLoading: false,
        };
      case SERVICE.ADD.REFRESH:
        return {
          ...initState.add,
        };
      default:
        return state;
    }
  },
  update: (state = initState.update, action) => {
    switch (action.type) {
      case SERVICE.UPDATE.REQUEST:
        return {
          ...state,
          code: undefined,
          codeLanguage: undefined,
          isLoading: true,
        };
      case SERVICE.UPDATE.SUCCESS:
        return {
          ...state,
          code: action.payload.code,
          codeLanguage: action.payload.codeLanguage,
          isLoading: false,
        };
      case SERVICE.UPDATE.FAIL:
        return {
          ...state,
          code: action.payload.code,
          codeLanguage: action.payload.codeLanguage,
          isLoading: false,
        };
      case SERVICE.UPDATE.REFRESH:
        return {
          ...initState.update,
        };
      default:
        return state;
    }
  },
  delete: (state = initState.delete, action) => {
    switch (action.type) {
      case SERVICE.DELETE.REQUEST:
        return {
          ...state,
          code: undefined,
          codeLanguage: undefined,
          isLoading: true,
        };
      case SERVICE.DELETE.SUCCESS:
        return {
          ...state,
          code: action.payload.code,
          codeLanguage: action.payload.codeLanguage,
          isLoading: false,
        };
      case SERVICE.DELETE.FAIL:
        return {
          ...state,
          code: action.payload.code,
          codeLanguage: action.payload.codeLanguage,
          isLoading: false,
        };
      case SERVICE.DELETE.REFRESH:
        return {
          ...initState.delete,
        };
      default:
        return state;
    }
  },
  changeStatus: (state = initState.changeStatus, action) => {
    switch (action.type) {
      case SERVICE.CHANGE_STATUS.REQUEST:
        return {
          ...state,
          code: undefined,
          codeLanguage: undefined,
          isLoading: true,
        };
      case SERVICE.CHANGE_STATUS.SUCCESS:
        return {
          ...state,
          code: action.payload.code,
          codeLanguage: action.payload.codeLanguage,
          isLoading: false,
        };
      case SERVICE.CHANGE_STATUS.FAIL:
        return {
          ...state,
          code: action.payload.code,
          codeLanguage: action.payload.codeLanguage,
          isLoading: false,
        };
      case SERVICE.CHANGE_STATUS.REFRESH:
        return {
          ...initState.changeStatus,
        };
      default:
        return state;
    }
  },
  icons: (state = initState.icons, action) => {
    switch (action.type) {
      case SERVICE.ICON_GETS.REQUEST:
        return {
          ...state,
          code: undefined,
          codeLanguage: undefined,
          isLoading: true,
        };
      case SERVICE.ICON_GETS.SUCCESS:
        return {
          ...state,
          code: action.payload.code,
          codeLanguage: action.payload.codeLanguage,
          isLoading: false,
          data: action.payload.data,
        };
      case SERVICE.ICON_GETS.FAIL:
        return {
          ...state,
          code: action.payload.code,
          codeLanguage: action.payload.codeLanguage,
          isLoading: false,
        };
      case SERVICE.ICON_GETS.REFRESH:
        return {
          ...initState.icons,
        };
      default:
        return state;
    }
  },
  contractTemplateByFormula: (state = initState.contractTemplateByFormula, action) => {
    switch (action.type) {
      case SERVICE.CONTRACT_TEMPLATE_BY_FORMULA_GETS.REQUEST:
        return {
          ...state,
          code: undefined,
          codeLanguage: undefined,
          isLoading: true,
        };
      case SERVICE.CONTRACT_TEMPLATE_BY_FORMULA_GETS.SUCCESS:
        return {
          ...state,
          code: action.payload.code,
          codeLanguage: action.payload.codeLanguage,
          isLoading: false,
          data: action.payload.data,
        };
      case SERVICE.CONTRACT_TEMPLATE_BY_FORMULA_GETS.FAIL:
        return {
          ...state,
          code: action.payload.code,
          codeLanguage: action.payload.codeLanguage,
          isLoading: false,
        };
      case SERVICE.CONTRACT_TEMPLATE_BY_FORMULA_GETS.REFRESH:
        return {
          ...initState.contractTemplateByFormula,
        };
      default:
        return state;
    }
  },
  contractTemplateBySurcharge: (state = initState.contractTemplateBySurcharge, action) => {
    switch (action.type) {
      case SERVICE.CONTRACT_TEMPLATE_BY_SURCHARGE_GETS.REQUEST:
        return {
          ...state,
          code: undefined,
          codeLanguage: undefined,
          isLoading: true,
        };
      case SERVICE.CONTRACT_TEMPLATE_BY_SURCHARGE_GETS.SUCCESS:
        return {
          ...state,
          code: action.payload.code,
          codeLanguage: action.payload.codeLanguage,
          isLoading: false,
          data: action.payload.data,
        };
      case SERVICE.CONTRACT_TEMPLATE_BY_SURCHARGE_GETS.FAIL:
        return {
          ...state,
          code: action.payload.code,
          codeLanguage: action.payload.codeLanguage,
          isLoading: false,
        };
      case SERVICE.CONTRACT_TEMPLATE_BY_SURCHARGE_GETS.REFRESH:
        return {
          ...initState.contractTemplateBySurcharge,
        };
      default:
        return state;
    }
  },
  contractTemplateByPromotion: (state = initState.contractTemplateByPromotion, action) => {
    switch (action.type) {
      case SERVICE.CONTRACT_TEMPLATE_BY_PROMOTION_GETS.REQUEST:
        return {
          ...state,
          code: undefined,
          codeLanguage: undefined,
          isLoading: true,
        };
      case SERVICE.CONTRACT_TEMPLATE_BY_PROMOTION_GETS.SUCCESS:
        return {
          ...state,
          code: action.payload.code,
          codeLanguage: action.payload.codeLanguage,
          isLoading: false,
          data: action.payload.data,
        };
      case SERVICE.CONTRACT_TEMPLATE_BY_PROMOTION_GETS.FAIL:
        return {
          ...state,
          code: action.payload.code,
          codeLanguage: action.payload.codeLanguage,
          isLoading: false,
        };
      case SERVICE.CONTRACT_TEMPLATE_BY_PROMOTION_GETS.REFRESH:
        return {
          ...initState.contractTemplateByPromotion,
        };
      default:
        return state;
    }
  },
  contractTemplateByService: (state = initState.contractTemplateByService, action) => {
    switch (action.type) {
      case SERVICE.CONTRACT_TEMPLATE_BY_SERVICE_GETS.REQUEST:
        return {
          ...state,
          code: undefined,
          codeLanguage: undefined,
          isLoading: true,
        };
      case SERVICE.CONTRACT_TEMPLATE_BY_SERVICE_GETS.SUCCESS:
        return {
          ...state,
          code: action.payload.code,
          codeLanguage: action.payload.codeLanguage,
          isLoading: false,
          data: action.payload.data,
          serviceId: action.payload.id,
        };
      case SERVICE.CONTRACT_TEMPLATE_BY_SERVICE_GETS.FAIL:
        return {
          ...state,
          code: action.payload.code,
          codeLanguage: action.payload.codeLanguage,
          isLoading: false,
        };
      case SERVICE.DELETE.REFRESH:
      case SERVICE.CONTRACT_TEMPLATE_BY_SERVICE_GETS.REFRESH:
        return {
          ...initState.contractTemplateByService,
        };
      default:
        return state;
    }
  },
});

export default reducer;
