import { language } from '../utils/language';

export const CASH = 'CASH';
export const TRANSFER = 'TRANSFER';
export const ONLINE = 'ONLINE';

export const DEAL_METHOD_OPTION = [
  {
    id: 'TRANSFER',
    name: language.label.transfer,
  },
  {
    id: 'CASH',
    name: language.label.cash,
  },
  {
    id: 'ONLINE',
    name: language.label.online,
  },
];
