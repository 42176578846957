import createAction from './createAction';
import convertArrToObj from './convertArrToObj';
import convertPeriodTimeToMonths from './convertPeriodTimeToMonths';
import combineOptionAll from './combineOptionAll';
import debounceFunc from './debounceFunc';
import mergeArr from './mergeArr';
import shouldForwardPropEmotion from './shouldForwardPropEmotion';
import getBase64 from './getBase64';
import threeDigits from './threeDigits';
import { checkResponseError, handleError, handleResponse, getTotalPage, getTotalRecord, getFilter } from './http';
import buildDetailBills from './buildDetailBills';
import isValidImageURL from './isValidImageURL';
import convertPhoneToNumber from './convertPhoneToNumber';
import intersectPeriodTime from './intersectPeriodTime';
import intersectPeriodDay from './intersectPeriodDay';
import normalizeLabel from './normalizeLabel';
import convertDateToString from './convertDateToString';
import isNil from './isNil';
import xssFilter from './xss-filter';
import threeDigitsWithDecimal from './threeDigitsWithDecimal';
import Cookie from './cookie';
import { languageBindArgs, getCacheLanguage, language, setLanguage } from './language';
import readFileExcel from './readFileExcel';
import generateDateTime from './generateDateTime';
import exportExcel from './exportExcel';
import downloadFileFromUrl from './downloadFileFromUrl';
import formatCurrency from './formatCurrency';
import formatNumberWithPrecision from './formatNumberWithPrecision';
import convertSizeImage from './convertSizeImage';
import nonAccentVietnamese from './nonAccentVietnamese';
import capitalizeFirstLetter from './capitalizeFirstLetter';
import isObject from './isObject';
import compareObject from './compareObject';
import compareArray from './compareArray';
import getPageByTotalAndLimit from './getPageByTotalAndLimit';
import generateTemplateData from './generateTemplateData';
import delay from './delay';
import convertNumberToDateTime from './convertNumberToDateTime';

export * from './validate';
export * from './calculateTotalPayment';
export {
  createAction,
  convertArrToObj,
  debounceFunc,
  mergeArr,
  getBase64,
  threeDigits,
  checkResponseError,
  handleError,
  handleResponse,
  getTotalPage,
  getTotalRecord,
  getFilter,
  shouldForwardPropEmotion,
  combineOptionAll,
  convertPeriodTimeToMonths,
  buildDetailBills,
  isValidImageURL,
  convertPhoneToNumber,
  intersectPeriodTime,
  intersectPeriodDay,
  normalizeLabel,
  convertDateToString,
  isNil,
  xssFilter,
  threeDigitsWithDecimal,
  Cookie,
  languageBindArgs,
  getCacheLanguage,
  language,
  setLanguage,
  readFileExcel,
  generateDateTime,
  exportExcel,
  downloadFileFromUrl,
  formatCurrency,
  formatNumberWithPrecision,
  convertSizeImage,
  nonAccentVietnamese,
  capitalizeFirstLetter,
  isObject,
  compareObject,
  compareArray,
  getPageByTotalAndLimit,
  generateTemplateData,
  delay,
  convertNumberToDateTime,
};
