import { API_TYPE_CALL_API, METHOD, X_UNIT_GROUP_ID, SERVER, API_TYPE_CALL_API_WITH_FILE } from 'shared/constants';
import api from './api';

const RENTAL_UNIT = `${SERVER}/rental-unit`;
const RENTAL_UNIT_API_GETS = RENTAL_UNIT;
const RENTAL_UNIT_API_GET = (id) => `${RENTAL_UNIT}/${id}`;

const RENTAL_UNIT_API_ADD = RENTAL_UNIT;
const RENTAL_UNIT_API_IMPORT = `${RENTAL_UNIT}/import`;
const RENTAL_UNIT_API_UPDATE = (id) => `${RENTAL_UNIT}/${id}`;
const RENTAL_UNIT_API_REMOVE = (id) => `${RENTAL_UNIT}/${id}`;

export const getRentalUnitsService = async ({ filter, limit, page, idBuilding }) => {
  const headers = { [X_UNIT_GROUP_ID]: idBuilding };
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: RENTAL_UNIT_API_GETS,
      method: METHOD.GET,
      options: {
        params: {
          ...filter,
          page,
          limit,
        },
        headers,
      },
    },
  });
  return response;
};

export const getRentalUnitService = async (id, idBuilding) => {
  const headers = { [X_UNIT_GROUP_ID]: idBuilding };
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: RENTAL_UNIT_API_GET(id),
      method: METHOD.GET,
      options: {
        headers,
      },
    },
  });
  return response;
};

export const addRentalUnitService = async (rentalUnitData, idBuilding) => {
  const headers = { [X_UNIT_GROUP_ID]: idBuilding };
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: RENTAL_UNIT_API_ADD,
      method: METHOD.POST,
      body: {
        ...rentalUnitData,
      },
      options: {
        headers,
      },
    },
  });
  return response;
};

export const updateRentalUnitService = async (id, body, idBuilding) => {
  const headers = { [X_UNIT_GROUP_ID]: idBuilding };
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: RENTAL_UNIT_API_UPDATE(id),
      method: METHOD.PUT,
      body,
      options: {
        headers,
      },
    },
  });
  return response;
};

export const removeRentalUnitService = async (id, idBuilding) => {
  const headers = { [X_UNIT_GROUP_ID]: idBuilding };
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: RENTAL_UNIT_API_REMOVE(id),
      method: METHOD.DELETE,
      options: {
        headers,
      },
    },
  });
  return response;
};

export const importRentalUnitService = async (idBuilding, file) => {
  const headers = {
    [X_UNIT_GROUP_ID]: idBuilding,
  };
  const response = api({
    type: API_TYPE_CALL_API_WITH_FILE,
    payload: {
      apiUrl: RENTAL_UNIT_API_IMPORT,
      file,
      method: METHOD.POST,
      options: {
        headers,
      },
    },
  });
  return response;
};
