import convertArrToObj from '../utils/convertArrToObj';
import { language } from '../utils/language';

export const PAYMENT_METHOD_OPTIONS = [
  {
    id: 'TRANSFER',
    name: language.label.transfer,
  },
  {
    id: 'CASH',
    name: language.label.cash,
  },
  {
    id: 'ONLINE',
    name: language.label.online,
  },
];

export const PAYMENT_METHOD_WITHOUT_ONLINE_OPTIONS = [
  {
    id: 'TRANSFER',
    name: language.label.transfer,
  },
  {
    id: 'CASH',
    name: language.label.cash,
  },
];

export const PAYMENT_METHOD_OPTION_OBJ = convertArrToObj(PAYMENT_METHOD_OPTIONS, 'id');

export const PAYMENT_METHOD_WITH_ONLINE_OPTIONS = [
  ...PAYMENT_METHOD_OPTIONS,
  {
    id: 'ONLINE',
    name: language.label.online,
  },
];

export const PAYMENT_METHOD_BY_ID = {
  TRANSFER: {
    id: 'TRANSFER',
    name: language.label.transfer,
  },
  CASH: {
    id: 'CASH',
    name: language.label.cash,
  },
  ONLINE: {
    id: 'ONLINE',
    name: language.label.online,
  },
};

export const PAY_USING_UNIT_ACCOUNT = 'PAY_USING_UNIT_ACCOUNT';
export const DONT_USE_APARTMENT_ACCOUNT = 'DONT_USE_APARTMENT_ACCOUNT';
export const APARTMENT_ACCOUNT_OPTIONS = [
  {
    id: DONT_USE_APARTMENT_ACCOUNT,
    name: language.label.do_not_use,
  },
  {
    id: PAY_USING_UNIT_ACCOUNT,
    name: language.label.in_use,
  },
];

export const TRANSFER = 'TRANSFER';
export const CASH = 'CASH';
export const ONLINE = 'ONLINE';
export const VNPAY_SPOS_CARD = 'VNPAY_SPOS_CARD';
export const VNPAY_SPOS_QRCODE = 'VNPAY_SPOS_QRCODE';
