import { filter, forEach, find } from 'lodash';
import notification from 'components/Notification';
import { language, xssFilter } from 'shared/utils';
import {
  SERVICE_STATUS_ACTIVE,
  SERVICE_STATUS_INACTIVE,
  // Type
  SERVICE_FORMULA_TYPE_NUMBER_USE,
  SERVICE_FORMULA_TYPE_PERCENT,
  SERVICE_FORMULA_TYPE_ACCUMULATED,
  PAYMENT_PERIOD_TYPE_MONTH,
} from './service.constants';

export const convertData = (params = {}, unitGroupId = 0) => {
  const result = {
    name: params?.name?.trim() ?? '',
    enName: params?.enName?.trim() ?? '',
    description: params?.description?.trim() ?? '',
    serviceType: params?.serviceType?.trim() ?? '',
    identifier: params?.identifier?.trim()?.toUpperCase() ?? '',
    status: params?.status ? SERVICE_STATUS_ACTIVE : SERVICE_STATUS_INACTIVE,
    measureUnit: params?.measureUnit?.trim() ?? '',
    files: 0,
    icon: params?.icon ?? '',
    unitGroupId: unitGroupId ?? 0,
    isAddFromDefaultService: params?.isAddFromDefaultService ? params?.isAddFromDefaultService : false,
    isSaveNumberUseInMonth: params?.isSaveNumberUseInMonth,
    feePeriodType: params?.feePeriodType ?? '',
    paymentPeriodType: params?.paymentPeriodType ?? PAYMENT_PERIOD_TYPE_MONTH,
    paymentExpiredDate: params?.paymentExpiredDate,
    paymentExpiredMonth: params?.paymentExpiredMonth,
    isUsageFactor: params.isUsageFactor,
    dueDate: params?.dueDate ?? null,
    dueDateType: params?.dueDateType ?? null,
    debtDate: params?.debtDate ?? null,
    debtDateType: params?.debtDateType ?? null,
    debtTime: {
      debtFirstTime: params?.debtFirstTime ?? null,
      debtSecondTime: params?.debtSecondTime ?? null,
      debtThirdTime: params?.debtThirdTime ?? null,
    },
  };

  if (params.id) {
    result.id = params.id;
  }

  const surcharges =
    params?.surcharges?.map((item, index) => ({
      id: item?.id ?? undefined,
      serviceId: params.id ?? undefined,
      index: index + 1,
      name: item?.name?.trim() ?? '',
      price: item.price ? Number(item.price) : 0,
      type: item.type ?? '',
      unitGroupId,
    })) || [];

  const promotions =
    params?.promotions?.map((item, index) => ({
      id: item?.id ?? undefined,
      serviceId: params.id ?? undefined,
      index: index + 1,
      name: item?.name?.trim() ?? '',
      price: item.price ? Number(item.price) : 0,
      type: item.type ?? '',
      unitGroupId,
    })) || [];

  const requiredInfo =
    params?.requiredInfo?.map((item, index) => ({
      index: index + 1,
      key: item?.key?.trim() ?? '',
      type: item.type ?? '',
      name: item?.name?.trim() ?? '',
    })) || [];

  const formulasNumberUseList = params?.formulas
    ? params?.formulas?.filter((formula) => formula.formulaType === SERVICE_FORMULA_TYPE_NUMBER_USE)
    : [];
  let formulasAccumulated = params?.formulas
    ? params?.formulas?.filter((formula) => formula.formulaType === SERVICE_FORMULA_TYPE_ACCUMULATED)
    : [];
  let formulasPercent = params?.formulas ? params?.formulas?.filter((formula) => formula.formulaType === SERVICE_FORMULA_TYPE_PERCENT) : [];
  let formulasNumberUse = [];

  formulasNumberUseList.forEach((item) => {
    formulasNumberUse = [...formulasNumberUse, ...item.formula];
  });

  formulasAccumulated = formulasAccumulated?.map((item, indexParent) => ({
    id: item?.id ?? undefined,
    serviceId: params.id ?? undefined,
    index: indexParent + 1,
    name: item?.name?.trim() ?? '',
    formulaType: item.formulaType ?? '',
    formula: item.formula
      ? item?.formula?.map((formulaItem, index) => {
          if (index === item?.formula.length - 1) {
            return {
              from: formulaItem.from ?? '',
              price: formulaItem.price ? Number(formulaItem.price) : 0,
            };
          }
          return {
            from: formulaItem.from ?? '',
            to: formulaItem.to ?? '',
            price: formulaItem.price ? Number(formulaItem.price) : 0,
          };
        })
      : [],
    unitGroupId,
  }));

  formulasPercent = formulasPercent?.map((item, indexParent) => ({
    id: item?.id ?? undefined,
    serviceId: params.id ?? undefined,
    index: indexParent + 1,
    name: item?.name?.trim() ?? '',
    formulaType: item.formulaType ?? '',
    formula: item.formula
      ? item?.formula?.map((formulaItem, index) => ({
          name: formulaItem.name ?? '',
          percent: formulaItem.percent,
          price: formulaItem.price ? Number(formulaItem.price) : 0,
        }))
      : [],
    unitGroupId,
  }));

  formulasNumberUse = formulasNumberUse?.map((item, index) => ({
    id: item.id ?? undefined,
    serviceId: params.id ?? undefined,
    index: index + 1,
    name: item?.name?.trim() ?? '',
    formulaType: SERVICE_FORMULA_TYPE_NUMBER_USE,
    parkingType: item.parkingType === '' ? undefined : item.parkingType,
    formula: {
      price: item.price ? Number(item.price) : 0,
    },
    unitGroupId,
  }));

  // MAPPING
  result.surcharges = surcharges;
  result.promotions = promotions;
  result.requiredInfo = requiredInfo;
  result.formulas = [...formulasNumberUse, ...formulasAccumulated, ...formulasPercent];

  return result;
};

export const convertDataToForm = (params = {}) => {
  const result = {
    name: params.name ?? '',
    enName: params.enName ?? '',
    dueDate: params?.dueDate ?? null,
    description: params.description ?? '',
    serviceType: params?.serviceType?.toUpperCase() ?? '',
    identifier: params.identifier ?? '',
    status: params.status === SERVICE_STATUS_ACTIVE,
    measureUnit: params.measureUnit ?? '',
    icon: params.icon ?? '',
    isSaveNumberUseInMonth: params.isSaveNumberUseInMonth,
    isUsageFactor: params.isUsageFactor,
    feePeriodType: params.feePeriodType ?? null,
    paymentPeriodType: params.paymentPeriodType ?? PAYMENT_PERIOD_TYPE_MONTH,
    paymentExpired: {
      date: params?.paymentExpiredDate,
      month: params?.paymentExpiredMonth,
    },
    dueDateType: params.dueDateType ?? null,
    debtDateType: params.debtDateType ?? null,
    debtDate: params?.debtDate ?? null,
    debtFirstTime: params?.debtTime?.debtFirstTime ?? null,
    debtSecondTime: params?.debtTime?.debtSecondTime ?? null,
    debtThirdTime: params?.debtTime?.debtThirdTime ?? null,
  };

  const surcharges =
    params?.surcharges?.map((item, index) => ({
      id: item.id ?? undefined,
      name: item.name ?? '',
      price: item.price ? Number(item.price) : 0,
      type: item.type ?? '',
    })) || [];

  const promotions =
    params?.promotions?.map((item, index) => ({
      id: item.id ?? undefined,
      name: item.name ?? '',
      price: item.price ? Number(item.price) : 0,
      type: item.type ?? '',
    })) || [];

  const requiredInfo =
    params?.requiredInfo?.map((item, index) => ({
      key: item.key ?? '',
      type: item.type ?? '',
      name: item.name ?? '',
    })) || [];

  let formulasAccumulated = params?.formulas
    ? params?.formulas?.filter((formula) => formula.formulaType === SERVICE_FORMULA_TYPE_ACCUMULATED)
    : [];
  let formulasPercent = params?.formulas ? params?.formulas?.filter((formula) => formula.formulaType === SERVICE_FORMULA_TYPE_PERCENT) : [];
  let formulasNumberUse = params?.formulas
    ? params?.formulas?.filter((formula) => formula.formulaType === SERVICE_FORMULA_TYPE_NUMBER_USE)
    : [];

  formulasAccumulated = formulasAccumulated.map((item) => ({
    name: item.name || '',
    formulaType: SERVICE_FORMULA_TYPE_ACCUMULATED,
    id: item.id ?? undefined,
    formula: item.formula
      ? item?.formula?.map((formulaItem, index) => {
          if (index === item.formula.length - 1) {
            return {
              from: formulaItem.from ?? '',
              price: formulaItem.price ? Number(formulaItem.price) : 0,
            };
          }
          return {
            from: formulaItem.from ?? '',
            to: formulaItem.to ?? '',
            price: formulaItem.price ? Number(formulaItem.price) : 0,
          };
        })
      : [],
  }));

  formulasPercent = formulasPercent.map((item) => ({
    name: item.name ?? '',
    formulaType: SERVICE_FORMULA_TYPE_PERCENT,
    id: item.id ?? undefined,
    formula: item.formula
      ? item?.formula?.map((formulaItem, index) => ({
          name: formulaItem.name ?? '',
          percent: formulaItem.percent ? Number(formulaItem.percent) : 0,
          price: formulaItem.price ? Number(formulaItem.price) : 0,
        }))
      : [],
  }));

  formulasNumberUse =
    formulasNumberUse.length > 0
      ? [
          {
            name: '',
            formulaType: SERVICE_FORMULA_TYPE_NUMBER_USE,
            id: formulasNumberUse?.[0]?.id ?? undefined,
            formula: formulasNumberUse.map((item) => ({
              name: item.name ?? '',
              price: item?.formula?.price ? Number(item?.formula?.price) : 0,
              parkingType: item?.parkingType ?? undefined,
              id: item.id ?? undefined,
            })),
          },
        ]
      : [];

  const formulas = [...formulasAccumulated, ...formulasPercent, ...formulasNumberUse];

  // MAPPING
  result.surcharges = surcharges;
  result.promotions = promotions;
  result.requiredInfo = requiredInfo;
  result.formulas = formulas;

  return result;
};

export const getAllServiceSystem = (services) => {
  const serviceDefault = filter(services, (service) => !service.unitGroupId);
  const serviceUse = filter(services, (service) => service.unitGroupId);
  const result = [];
  // Thay thế dịch vụ khi sử dụng dịch vụ mặc định
  forEach(serviceDefault, (service) => {
    const serviceTmp = find(serviceUse, (item) => service.identifier === item.identifier);
    if (serviceTmp) {
      result.push(serviceTmp);
    } else {
      result.push(service);
    }
  });
  forEach(serviceUse, (service) => {
    const serviceTmp = find(result, (item) => item.identifier === service.identifier);
    if (!serviceTmp) result.push(service);
  });
  return result;
};

export const extendFuncForValidate = ({ remove, index, description = '' }) => {
  const onFinish = () => {
    remove(index);
  };

  const onError = () => {
    notification.error({
      message: language.label.error,
      description,
    });
  };

  return {
    onFinish,
    onError,
  };
};

export const filterXssForm = (form) => {
  const newService = {};
  newService.name = xssFilter(form.getFieldValue('name'));
  newService.identifier = xssFilter(form.getFieldValue('identifier'));
  newService.enName = xssFilter(form.getFieldValue('enName'));
  newService.measureUnit = xssFilter(form.getFieldValue('measureUnit'));
  newService.description = xssFilter(form.getFieldValue('description'));

  if (form.getFieldValue('surcharges')?.length > 0) {
    newService.surcharges = form.getFieldValue('surcharges')?.map((surcharge) => ({
      ...surcharge,
      name: xssFilter(surcharge.name),
    }));
  }
  if (form.getFieldValue('promotions')?.length > 0) {
    newService.promotions = form.getFieldValue('promotions')?.map((promotion) => ({
      ...promotion,
      name: xssFilter(promotion.name),
    }));
  }
  if (form.getFieldValue('requiredInfo')?.length > 0) {
    newService.requiredInfo = form.getFieldValue('requiredInfo')?.map((requiredInfoItem) => ({
      ...requiredInfoItem,
      name: xssFilter(requiredInfoItem.name),
      key: xssFilter(requiredInfoItem.key),
    }));
  }
  if (form.getFieldValue('formulas')?.length > 0) {
    newService.formulas = form.getFieldValue('formulas')?.map((formulasItem) => ({
      ...formulasItem,
      name: xssFilter(formulasItem.name),
    }));
  }

  return newService;
};
