import { API_TYPE_CALL_API, API_TYPE_DOWNLOAD_FILE, METHOD, SERVER, X_UNIT_GROUP_ID } from 'shared/constants';
import api from './api';

const AMENITIES = `${SERVER}/amenities`;
const AMENITIES_GROUP_GETS = `${AMENITIES}/group/list`;
const AMENITIES_GROUP_GET = (id) => `${AMENITIES}/group/${id}`;
const AMENITIES_GROUP_ADD = `${AMENITIES}/group`;
const AMENITIES_GROUP_UPDATE = (id) => `${AMENITIES}/group/${id}`;
const AMENITIES_GROUP_GET_PRIORITY_IN_APP_SELECTED = `${AMENITIES}/group/list-priority`;
const AMENITIES_GETS = `${AMENITIES}/list`;
const AMENITIES_GET = (id) => `${AMENITIES}/${id}`;
const AMENITIES_GET_PRIORITY_IN_APP_SELECTED = `${AMENITIES}/list-priority`;
const AMENITIES_ADD = AMENITIES;
const AMENITIES_UPDATE = (id) => `${AMENITIES}/${id}`;
const AMENITIES_ACTIVE = (id) => `${AMENITIES}/${id}/active`;
const AMENITIES_INACTIVE = (id) => `${AMENITIES}/${id}/inactive`;
const AMENITIES_ORDER_GETS = `${AMENITIES}/booking/list`;
const AMENITY_MERCHANT_ORDERS_GETS = `${AMENITIES}/pms/merchant/booking/list`;
const AMENITY_MERCHANT_ORDERS_EXPORT = `${AMENITIES}/pms/merchant/booking/export`;
const AMENITY_MERCHANT_ORDERS_GET = (id) => `${AMENITIES}/pms/merchant/booking/${id}`;
const AMENITIES_ORDER_EXPORT = `${AMENITIES}/booking/export`;
const AMENITIES_ORDER_APPROVE = `${AMENITIES}/booking/approve`;
const AMENITIES_ORDER_REJECT = `${AMENITIES}/booking/reject`;
const AMENITIES_ORDER_CANCEL = (id) => `${AMENITIES}/booking/${id}/cancel`;
const AMENITIES_ORDER_UPDATE = (id) => `${AMENITIES}/booking/${id}`;
const AMENITIES_ORDER_GET = (id) => `${AMENITIES}/booking/${id}`;
const AMENITIES_ORDER_PAYMENT = (id) => `${AMENITIES}/booking/${id}/pay-manual`;
const AMENITIES_ORDER_REFUND = (id) => `${AMENITIES}/booking/${id}/refund-manual`;
const AMENITIES_ORDER_REFUND_DEPOSITS = (id) => `${AMENITIES}/booking/${id}/refund-manual`;
const AMENITIES_COUNT_BADGES = `${AMENITIES}/count-badges`;
const AMENITIES_GET_ALL_AIR_CONDITIONERS = `${SERVER}/third-party-service/v1/benkon`;

const UTILITY = `${SERVER}/utility-service/pms/v1`;
const UTILITY_BOOKING = `${UTILITY}/utility-booking`;
const UTILITY_GROUP_ALLOW_GET = `${UTILITY}/utility-group/booking`;
const UTILITY_AVAILABLE_SLOTS_GET = (id) => `${UTILITY}/utility-group/${id}/available-slots`;
const UTILITY_BOOKING_CHECK = `${UTILITY_BOOKING}/check`;
const UTILITY_BOOKING_GETS = `${UTILITY_BOOKING}/list`;
const UTILITY_BOOKING_REJECT = (id) => `${UTILITY}/utility-booking/${id}/reject`;

export async function getListAmenitiesGroupService({ idBuilding, filter, page, limit }) {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: AMENITIES_GROUP_GETS,
      method: METHOD.GET,
      options: {
        params: {
          ...filter,
          page,
          limit,
        },
        headers: {
          [X_UNIT_GROUP_ID]: idBuilding,
        },
      },
    },
  });
  return response;
}

export async function getAmenitiesGroupService(idAmenitiesGroup, idBuilding) {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: AMENITIES_GROUP_GET(idAmenitiesGroup),
      method: METHOD.GET,
      options: {
        headers: {
          [X_UNIT_GROUP_ID]: idBuilding,
        },
      },
    },
  });
  return response;
}

export async function addAmenitiesGroupService(amenitiesGroup, idBuilding) {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: AMENITIES_GROUP_ADD,
      method: METHOD.POST,
      body: {
        ...amenitiesGroup,
      },
      options: {
        headers: {
          [X_UNIT_GROUP_ID]: idBuilding,
        },
      },
    },
  });
  return response;
}

export async function updateAmenitiesGroupService(idAmenitiesGroup, amenitiesGroup, idBuilding) {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: AMENITIES_GROUP_UPDATE(idAmenitiesGroup),
      method: METHOD.PUT,
      body: {
        ...amenitiesGroup,
      },
      options: {
        headers: {
          [X_UNIT_GROUP_ID]: idBuilding,
        },
      },
    },
  });
  return response;
}

export async function getsPriorityInAppSelectedOfAmenitiesGroupService(idBuilding) {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: AMENITIES_GROUP_GET_PRIORITY_IN_APP_SELECTED,
      method: METHOD.GET,
      options: {
        headers: {
          [X_UNIT_GROUP_ID]: idBuilding,
        },
      },
    },
  });
  return response;
}

export async function getsAmenitiesService({ params, headers }) {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: AMENITIES_GETS,
      method: METHOD.GET,
      options: {
        params,
        headers,
      },
    },
  });
  return response;
}

export async function getAmenitiesService({ params, headers }) {
  const { id } = params;
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: AMENITIES_GET(id),
      method: METHOD.GET,
      options: {
        params,
        headers,
      },
    },
  });
  return response;
}

export async function getsPriorityInAppSelectedService({ params, headers }) {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: AMENITIES_GET_PRIORITY_IN_APP_SELECTED,
      method: METHOD.GET,
      options: {
        params,
        headers,
      },
    },
  });
  return response;
}

export async function addAmenitiesService(amenitiesData, idBuilding) {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: AMENITIES_ADD,
      method: METHOD.POST,
      body: {
        ...amenitiesData,
      },
      options: {
        headers: {
          [X_UNIT_GROUP_ID]: idBuilding,
        },
      },
    },
  });
  return response;
}

export async function updateAmenitiesService(idAmenities, amenitiesData, idBuilding) {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: AMENITIES_UPDATE(idAmenities),
      method: METHOD.PUT,
      body: {
        ...amenitiesData,
      },
      options: {
        headers: {
          [X_UNIT_GROUP_ID]: idBuilding,
        },
      },
    },
  });
  return response;
}

export async function activeAmenitiesService({ body, headers }) {
  const { id } = body;
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: AMENITIES_ACTIVE(id),
      method: METHOD.PUT,
      body,
      options: {
        headers,
      },
    },
  });
  return response;
}

export async function inactiveAmenitiesService({ body, headers }) {
  const { id } = body;
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: AMENITIES_INACTIVE(id),
      method: METHOD.PUT,
      body,
      options: {
        headers,
      },
    },
  });
  return response;
}

export async function getsAmenitiesOrderService({ params, headers }) {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: AMENITIES_ORDER_GETS,
      method: METHOD.GET,
      options: {
        params,
        headers,
      },
    },
  });
  return response;
}

export async function getsAmenityMerchantOrderService({ filter, limit, page, idBuilding }) {
  const headers = { [X_UNIT_GROUP_ID]: idBuilding };
  const params = { ...filter, limit, page };
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: AMENITY_MERCHANT_ORDERS_GETS,
      method: METHOD.GET,
      options: {
        params,
        headers,
      },
    },
  });
  return response;
}

export async function exportAmenityMerchantOrderService(filter, idBuilding) {
  const headers = { [X_UNIT_GROUP_ID]: idBuilding };
  const params = { ...filter };
  const response = await api({
    type: API_TYPE_DOWNLOAD_FILE,
    payload: {
      apiUrl: AMENITY_MERCHANT_ORDERS_EXPORT,
      method: METHOD.GET,
      options: {
        params,
        headers,
      },
    },
  });
  return response;
}

export async function getAmenityMerchantOrderService(id, idBuilding) {
  const headers = { [X_UNIT_GROUP_ID]: idBuilding };
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: AMENITY_MERCHANT_ORDERS_GET(id),
      method: METHOD.GET,
      options: { headers },
    },
  });
  return response;
}

export async function getAmenitiesOrderService({ params, headers }) {
  const { id } = params;
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: AMENITIES_ORDER_GET(id),
      method: METHOD.GET,
      options: {
        params,
        headers,
      },
    },
  });
  return response;
}

export async function exportAmenitiesOrderService({ params, headers }) {
  const response = await api({
    type: API_TYPE_DOWNLOAD_FILE,
    payload: {
      apiUrl: AMENITIES_ORDER_EXPORT,
      method: METHOD.GET,
      options: {
        params,
        headers,
      },
    },
  });
  return response;
}

export async function approveAmenitiesOrdersService({ body, headers }) {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: AMENITIES_ORDER_APPROVE,
      method: METHOD.POST,
      body,
      options: {
        headers,
      },
    },
  });
  return response;
}

export async function rejectAmenitiesOrdersService({ body, headers }) {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: AMENITIES_ORDER_REJECT,
      method: METHOD.POST,
      body,
      options: {
        headers,
      },
    },
  });
  return response;
}

export async function cancelAmenitiesOrdersService({ id, body, idBuilding }) {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: AMENITIES_ORDER_CANCEL(id),
      method: METHOD.PUT,
      body,
      options: {
        headers: {
          [X_UNIT_GROUP_ID]: idBuilding,
        },
      },
    },
  });
  return response;
}

export async function updateAmenitiesOrdersService({ id, body, idBuilding }) {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: AMENITIES_ORDER_UPDATE(id),
      method: METHOD.PUT,
      body,
      options: {
        headers: {
          [X_UNIT_GROUP_ID]: idBuilding,
        },
      },
    },
  });
  return response;
}

export async function paymentAmenitiesOrderService({ body, headers }) {
  const { id } = body;
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: AMENITIES_ORDER_PAYMENT(id),
      method: METHOD.POST,
      body,
      options: {
        headers,
      },
    },
  });
  return response;
}

export async function refundAmenitiesOrderService({ body, headers }) {
  const { id } = body;
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: AMENITIES_ORDER_REFUND(id),
      method: METHOD.POST,
      body,
      options: {
        headers,
      },
    },
  });
  return response;
}

export async function refundDepositAmenitiesOrderService({ body, headers }) {
  const { id } = body;
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: AMENITIES_ORDER_REFUND_DEPOSITS(id),
      method: METHOD.POST,
      body,
      options: {
        headers,
      },
    },
  });
  return response;
}

export const countBadgesAmenitiesBookingService = async (idBuilding) => {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: AMENITIES_COUNT_BADGES,
      method: METHOD.GET,
      options: {
        headers: {
          [X_UNIT_GROUP_ID]: idBuilding,
        },
      },
    },
  });
  return response;
};

export async function getAllAirConditioners(idBuilding) {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: AMENITIES_GET_ALL_AIR_CONDITIONERS,
      method: METHOD.GET,
      options: {
        headers: {
          [X_UNIT_GROUP_ID]: idBuilding,
        },
        params: {
          isDetail: false,
        },
      },
    },
  });
  return response;
}

export async function checkBookingService(utilityBookingData, idBuilding) {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: UTILITY_BOOKING_CHECK,
      method: METHOD.POST,
      body: {
        ...utilityBookingData,
      },
      options: {
        headers: {
          [X_UNIT_GROUP_ID]: idBuilding,
        },
      },
    },
  });
  return response;
}

export async function getUtilityGroupAllowBookingService(idBuilding) {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: UTILITY_GROUP_ALLOW_GET,
      method: METHOD.GET,
      options: {
        headers: {
          [X_UNIT_GROUP_ID]: idBuilding,
        },
      },
    },
  });
  return response;
}

export async function getListAvailableSlotsService({ startDateUsage, idBuilding, utilityGroupId }) {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: UTILITY_AVAILABLE_SLOTS_GET(utilityGroupId),
      method: METHOD.GET,
      options: {
        headers: {
          [X_UNIT_GROUP_ID]: idBuilding,
        },
        params: { startDateUsage },
      },
    },
  });
  return response;
}

export async function getsUtilitiesService({ params, headers }) {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: UTILITY_BOOKING_GETS,
      method: METHOD.GET,
      options: {
        params,
        headers,
      },
    },
  });
  return response;
}

export async function rejectAmenitiesOrderService({ id, idBuilding }) {
  const headers = { [X_UNIT_GROUP_ID]: idBuilding };
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: UTILITY_BOOKING_REJECT(id),
      method: METHOD.DELETE,
      options: {
        headers,
      },
    },
  });
  return response;
}

export async function createUtilityBookingService(utilityBookingData, idBuilding) {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: UTILITY_BOOKING,
      method: METHOD.POST,
      body: {
        ...utilityBookingData,
      },
      options: {
        headers: {
          [X_UNIT_GROUP_ID]: idBuilding,
        },
      },
    },
  });
  return response;
}
