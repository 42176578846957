import * as React from 'react';

const withFilterHeaderTable = (columns) => {
  return columns.map((column) => {
    const { dataIndex, filterComponent, key } = column;
    const columnObj = {
      ...column,
    };
    delete columnObj.filterComponent;
    return {
      title: filterComponent || <div />,
      key: key ? `filter${key.charAt(0).toLowerCase()}${key.substring(1, key.length - 1)}` : key,
      dataIndex: dataIndex ? `filter${dataIndex.charAt(0).toLowerCase()}${dataIndex.substring(1, dataIndex.length - 1)}` : dataIndex,
      fixed: column.fixed,
      children: [
        {
          ...columnObj,
        },
      ],
    };
  });
};

export default withFilterHeaderTable;
