export const SERVER = process.env.API_URL;
export const SERVER_BILL = process.env.API_BILL_SERVER_URL;
export const SERVER_SYNC_ACCOUNTING = process.env.API_BILL_URL;
export const SERVER_PAYMENT = process.env.API_PAYMENT_URL;
export const SERVER_CHAT = process.env.PARSE_SERVER_URL;
export const SERVER_NOTIFICATION = process.env.NOTIFICATION_SERVER_URL;

export const SERVER_MODULE_SYSTEM_ENGINEERING = process.env.API_MODULE_SYSTEM_ENGINEERING_URL;
export const SERVER_MODULE_SYSTEM_ENGINEERING_PREFIX = process.env.API_MODULE_SYSTEM_ENGINEERING_PREFIX;

export const API_TYPE_CALL_API = 'CALL_API';
export const API_TYPE_DOWNLOAD_FILE = 'DOWNLOAD_FILE';
export const API_TYPE_CALL_API_WITH_FILE = 'CALL_API_WITH_FILE';
export const API_TYPE_CALL_API_PARSER = 'CALL_API_PARSER';

export const POSITION_LEVEL = 'position-level/v1';
