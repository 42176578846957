/* eslint-disable import/no-cycle */
import { useContext } from 'react';
import { getPermissionService } from 'services/user';
import { Context as GlobalContext } from 'globalContext';
import { LIST_ACCOUNT_CS, MODULE_RESIDENT, NOT_NEED_PERMISSION } from 'shared/constants';

function hasAnyAuthority(authorities = [], hasAnyAuthorities = []) {
  if (authorities && authorities.length !== 0) {
    if (hasAnyAuthorities.length === 0) {
      return true;
    }
    return hasAnyAuthorities.some((auth) => [...authorities, NOT_NEED_PERMISSION].includes(auth));
  }
  return false;
}

const usePermission = () => {
  const { authorities, setAuthorities } = useContext(GlobalContext);

  async function getPermission(idBuilding, email) {
    const isUserCs = LIST_ACCOUNT_CS.includes(email);
    const permissions = await getPermissionService({
      idBuilding,
    });
    const newAuthorities = [];
    permissions?.data?.forEach((permission) => {
      if (isUserCs && permission?.name && permission?.name !== MODULE_RESIDENT.READ) {
        newAuthorities.push(permission?.name);
      }
      if (!isUserCs && permission?.name) {
        newAuthorities.push(permission?.name);
      }
    });
    setAuthorities(newAuthorities);
    return newAuthorities;
  }

  function checkViewPermission(hasAnyAuthorities) {
    return hasAnyAuthority(authorities, hasAnyAuthorities);
  }

  return { checkViewPermission, getPermission, authorities };
};

export default usePermission;
