import { CONFIG_SUBDIVISION_GETS, CONFIG_SUBDIVISION_PAGE_REFRESH } from './subdivision.types';

export const initState = {
  data: [],
  totalData: 0,
};

const subdivision = (state = initState, action) => {
  switch (action.type) {
    case CONFIG_SUBDIVISION_PAGE_REFRESH: {
      return {
        ...initState,
      };
    }
    case CONFIG_SUBDIVISION_GETS.SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        totalData: action.payload.totalRecord,
      };
    }
    default: {
      return state;
    }
  }
};

export default subdivision;
