import { SERVICE } from './service.types';

export const getsService = ({ unitGroupId }) => ({
  type: SERVICE.GETS.REQUEST,
  payload: {
    unitGroupId,
  },
});

export const getService = ({ id, unitGroupId }) => ({
  type: SERVICE.GET.REQUEST,
  payload: {
    id,
    unitGroupId,
  },
});

export const addService = ({ service, unitGroupId }) => ({
  type: SERVICE.ADD.REQUEST,
  payload: {
    service,
    unitGroupId,
  },
});

export const updateService = ({ service, unitGroupId }) => ({
  type: SERVICE.UPDATE.REQUEST,
  payload: {
    service,
    unitGroupId,
  },
});

export const deleteService = ({ id, unitGroupId, refreshState }) => ({
  type: SERVICE.DELETE.REQUEST,
  payload: {
    id,
    unitGroupId,
    refreshState,
  },
});

export const changeStatusService = ({ id, unitGroupId, type, refreshState }) => ({
  type: SERVICE.CHANGE_STATUS.REQUEST,
  payload: {
    id,
    unitGroupId,
    type,
    refreshState,
  },
});

export const getsIconService = () => ({
  type: SERVICE.ICON_GETS.REQUEST,
  payload: {},
});

export const getContractTemplateByFormula = ({ id, unitGroupId, callback }) => ({
  type: SERVICE.CONTRACT_TEMPLATE_BY_FORMULA_GETS.REQUEST,
  payload: {
    id,
    unitGroupId,
    callback,
  },
});

export const getContractTemplateBySurcharge = ({ id, unitGroupId, callback }) => ({
  type: SERVICE.CONTRACT_TEMPLATE_BY_SURCHARGE_GETS.REQUEST,
  payload: {
    id,
    unitGroupId,
    callback,
  },
});

export const getContractTemplateByPromotion = ({ id, unitGroupId, callback }) => ({
  type: SERVICE.CONTRACT_TEMPLATE_BY_PROMOTION_GETS.REQUEST,
  payload: {
    id,
    unitGroupId,
    callback,
  },
});

export const getContractTemplateByService = ({ id, unitGroupId }) => ({
  type: SERVICE.CONTRACT_TEMPLATE_BY_SERVICE_GETS.REQUEST,
  payload: {
    id,
    unitGroupId,
  },
});

export const refreshGetContractTemplateByService = () => ({
  type: SERVICE.CONTRACT_TEMPLATE_BY_SERVICE_GETS.REFRESH,
});
