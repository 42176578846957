import {
  CONFIG_APARTMENT_ADD,
  CONFIG_APARTMENT_DELETE,
  CONFIG_APARTMENT_GETS,
  CONFIG_APARTMENT_PAGE_REFRESH,
  CONFIG_APARTMENT_UPDATE,
  CONFIG_APARTMENT_SUBDIVISION_GETS,
  CONFIG_APARTMENT_BLOCK_GETS,
  CONFIG_APARTMENT_FLOOR_GETS,
  CONFIG_APARTMENT_APARTMENT_TYPE_GETS,
  CONFIG_APARTMENT_DELETE_MULTI,
  CONFIG_APARTMENT_EXPORT,
  CONFIG_APARTMENT_IMPORT,
} from './apartment.types';

export const getApartmentTypes = () => ({
  type: CONFIG_APARTMENT_APARTMENT_TYPE_GETS.REQUEST,
});

export const getSubdivisions = ({ idBuilding }) => ({
  type: CONFIG_APARTMENT_SUBDIVISION_GETS.REQUEST,
  payload: {
    idBuilding,
  },
});

export const getBlocks = ({ idBuilding }) => ({
  type: CONFIG_APARTMENT_BLOCK_GETS.REQUEST,
  payload: {
    idBuilding,
  },
});

export const getFloors = ({ idBuilding }) => ({
  type: CONFIG_APARTMENT_FLOOR_GETS.REQUEST,
  payload: {
    idBuilding,
  },
});

export const getApartments = ({ idBuilding, filter, page, limit, refreshState }) => ({
  type: CONFIG_APARTMENT_GETS.REQUEST,
  payload: {
    idBuilding,
    filter,
    page,
    limit,
    refreshState,
  },
});

export const addApartment = ({ idBuilding, apartment, refreshState }) => ({
  type: CONFIG_APARTMENT_ADD.REQUEST,
  payload: {
    idBuilding,
    apartment,
    refreshState,
  },
});

export const updateApartment = ({ idBuilding, idApartment, apartment, refreshState }) => ({
  type: CONFIG_APARTMENT_UPDATE.REQUEST,
  payload: {
    idBuilding,
    idApartment,
    apartment,
    refreshState,
  },
});

export const deleteApartment = ({ idBuilding, idApartment, refreshState }) => ({
  type: CONFIG_APARTMENT_DELETE.REQUEST,
  payload: {
    idBuilding,
    idApartment,
    refreshState,
  },
});

export const deleteMultiApartment = ({ idBuilding, isAll, filterObject, idApartments, refreshState }) => ({
  type: CONFIG_APARTMENT_DELETE_MULTI.REQUEST,
  payload: {
    idBuilding,
    isAll,
    filterObject,
    idApartments,
    refreshState,
  },
});

export const importApartments = ({ idBuilding, file, refreshState }) => ({
  type: CONFIG_APARTMENT_IMPORT.REQUEST,
  payload: {
    idBuilding,
    file,
    refreshState,
  },
});

export const exportApartments = ({ idBuilding, filter, refreshState }) => ({
  type: CONFIG_APARTMENT_EXPORT.REQUEST,
  payload: {
    idBuilding,
    filter,
    refreshState,
  },
});

export const refreshApartmentPage = () => ({
  type: CONFIG_APARTMENT_PAGE_REFRESH,
});
