import { useState } from 'react';

export default function useForm({ callbackAfterHideForm } = {}) {
  const [form, setForm] = useState({
    isShow: false,
    data: {},
  });

  function showForm(data) {
    setForm({
      isShow: true,
      data,
    });
  }

  function hideForm(isReloadData, data) {
    setForm({
      isShow: false,
      data: {},
    });
    if (callbackAfterHideForm) {
      callbackAfterHideForm(isReloadData, data);
    }
  }

  return [form, showForm, hideForm];
}
