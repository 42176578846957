import { SERVICE_SURCHARGE_TYPE_PERCENT, SERVICE_SURCHARGE_TYPE_PRICE_MULTI_AMOUNT } from '../../constants/service';

export default function calculateSurcharges(surcharges = [], totalPayment, totalNumberUse) {
  let totalSurcharge = 0;
  if (Array.isArray(surcharges)) {
    surcharges.forEach((item) => {
      switch (item.type) {
        case SERVICE_SURCHARGE_TYPE_PERCENT: {
          totalSurcharge += (totalPayment * item.price) / 100;
          break;
        }
        case SERVICE_SURCHARGE_TYPE_PRICE_MULTI_AMOUNT: {
          totalSurcharge += totalNumberUse * item.price;
          break;
        }
        default:
          break;
      }
    });
  }
  return Math.round(totalSurcharge);
}
