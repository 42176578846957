import { API_TYPE_CALL_API, METHOD, SERVER, X_UNIT_GROUP_ID } from 'shared/constants';
import api from './api';

export const CONTRACT_TEMPLATE = `${SERVER}/contract-template`;
export const CONTRACT_TEMPLATE_GET = `${CONTRACT_TEMPLATE}/list`;
export const CONTRACT_TEMPLATE_ADD = CONTRACT_TEMPLATE;
export const CONTRACT_TEMPLATE_UPDATE = (idContractTemplate) => `${CONTRACT_TEMPLATE}/${idContractTemplate}`;
export const CONTRACT_TEMPLATE_DELETE = (idContractTemplate) => `${CONTRACT_TEMPLATE}/${idContractTemplate}`;
export const CONTRACT_TEMPLATE_GET_BY_FORMULA_ID = `${CONTRACT_TEMPLATE}/list-by-formula-id`;
export const CONTRACT_TEMPLATE_GET_BY_SURCHARGE_ID = `${CONTRACT_TEMPLATE}/list-by-surcharge-id`;
export const CONTRACT_TEMPLATE_GET_BY_PROMOTION_ID = `${CONTRACT_TEMPLATE}/list-by-promotion-id`;

export const getContractTemplateService = async ({ filter, page, limit, idBuilding }) => {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: CONTRACT_TEMPLATE_GET,
      method: METHOD.GET,
      options: {
        headers: {
          [X_UNIT_GROUP_ID]: idBuilding,
        },
        params: {
          ...filter,
          page,
          limit,
        },
      },
    },
  });
  return response;
};

export const getContractTemplateByFormulaService = async ({ filter, page, limit, idBuilding }) => {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: CONTRACT_TEMPLATE_GET_BY_FORMULA_ID,
      method: METHOD.GET,
      options: {
        headers: {
          [X_UNIT_GROUP_ID]: idBuilding,
        },
        params: {
          ...filter,
          page,
          limit,
        },
      },
    },
  });
  return response;
};

export const getContractTemplateByPromotionService = async ({ filter, page, limit, idBuilding }) => {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: CONTRACT_TEMPLATE_GET_BY_PROMOTION_ID,
      method: METHOD.GET,
      options: {
        headers: {
          [X_UNIT_GROUP_ID]: idBuilding,
        },
        params: {
          ...filter,
          page,
          limit,
        },
      },
    },
  });
  return response;
};

export const getContractTemplateBySurchargeService = async ({ filter, page, limit, idBuilding }) => {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: CONTRACT_TEMPLATE_GET_BY_SURCHARGE_ID,
      method: METHOD.GET,
      options: {
        headers: {
          [X_UNIT_GROUP_ID]: idBuilding,
        },
        params: {
          ...filter,
          page,
          limit,
        },
      },
    },
  });
  return response;
};

export const addContractTemplateService = async ({ idBuilding, contractTemplate }) => {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: CONTRACT_TEMPLATE_ADD,
      method: METHOD.POST,
      body: contractTemplate,
      options: {
        headers: {
          [X_UNIT_GROUP_ID]: idBuilding,
        },
      },
    },
  });
  return response;
};

export const updateContractTemplateService = async ({ idBuilding, idContractTemplate, contractTemplate }) => {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: CONTRACT_TEMPLATE_UPDATE(idContractTemplate),
      method: METHOD.PUT,
      body: contractTemplate,
      options: {
        headers: {
          [X_UNIT_GROUP_ID]: idBuilding,
        },
      },
    },
  });
  return response;
};

export const deleteContractTemplateService = async ({ idBuilding, idContractTemplate }) => {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: CONTRACT_TEMPLATE_DELETE(idContractTemplate),
      method: METHOD.DELETE,
      options: {
        headers: {
          [X_UNIT_GROUP_ID]: idBuilding,
        },
      },
    },
  });
  return response;
};
