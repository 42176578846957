import { useContext } from 'react';
// eslint-disable-next-line import/no-cycle
import { Context as GlobalContext } from 'globalContext';
import { getApartmentTypesService } from 'services/apartment';
import { getCacheLanguage, capitalizeFirstLetter } from 'shared/utils';

const useApartmentType = () => {
  const { apartmentTypes, setApartmentTypes } = useContext(GlobalContext);

  const getApartmentTypes = async () => {
    const response = await getApartmentTypesService();
    const isEn = getCacheLanguage() === 'en';
    if (isEn) {
      const listApartmentType = response.data.map((apartmentTypeItem) => {
        return {
          ...apartmentTypeItem,
          name: capitalizeFirstLetter(apartmentTypeItem?.id?.replace(/_/g, ' ')),
        };
      });
      setApartmentTypes(listApartmentType);
    }
    if (!isEn) {
      setApartmentTypes(response.data);
    }
  };

  return { apartmentTypes, getApartmentTypes };
};

export default useApartmentType;
