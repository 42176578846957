import { API_TYPE_CALL_API, API_TYPE_DOWNLOAD_FILE, METHOD, SERVER, X_UNIT_GROUP_ID } from 'shared/constants';
import api from './api';

const PAY_SLIP = `${SERVER}/pay-slip`;
const PAY_SLIP_PREVIEW = (paySlipId) => `${PAY_SLIP}/${paySlipId}`;
const PAY_SLIP_GETS = `${PAY_SLIP}/list`;
const PAY_SLIP_CANCEL = (paySlipId) => `${PAY_SLIP}/${paySlipId}/cancel`;
const PAY_SLIP_EXPORT = `${PAY_SLIP}/export-data`;
const PAY_SLIP_SUM_TOTAL_PAYMENT = `${PAY_SLIP}/sum-total-payment`;
const PAY_SLIP_UPDATE = (paySlipId) => `${PAY_SLIP}/${paySlipId}`;
const PAY_SLIP_HISTORY_API_GETS = (id) => `${PAY_SLIP}/${id}/logs`;

export async function getPaySlipPreviewService(idBuilding, idPayslip) {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: PAY_SLIP_PREVIEW(idPayslip),
      method: METHOD.GET,
      options: {
        headers: {
          [X_UNIT_GROUP_ID]: idBuilding,
        },
        bodyType: 'html',
      },
    },
  });
  return response;
}

export async function getPaySlipService({ idBuilding, page, limit, filter }) {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: PAY_SLIP_GETS,
      method: METHOD.GET,
      options: {
        headers: {
          [X_UNIT_GROUP_ID]: idBuilding,
        },
        params: {
          ...filter,
          limit,
          page,
        },
      },
    },
  });
  return response;
}

export async function addPaySlipService(idBuilding, payslip) {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: PAY_SLIP,
      method: METHOD.POST,
      body: {
        ...payslip,
      },
      options: {
        headers: {
          [X_UNIT_GROUP_ID]: idBuilding,
        },
      },
    },
  });
  return response;
}

export async function updatePaySlipService({ idBuilding, labelId, idPayslip, reportAt, paidAt }) {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: PAY_SLIP_UPDATE(idPayslip),
      method: METHOD.PUT,
      body: {
        labelId,
        reportAt,
        paidAt,
      },
      options: {
        headers: {
          [X_UNIT_GROUP_ID]: idBuilding,
        },
      },
    },
  });
  return response;
}

export async function cancelPaySlipService(idBuilding, idPayslip, additionalInfo) {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: PAY_SLIP_CANCEL(idPayslip),
      method: METHOD.POST,
      body: {
        ...additionalInfo,
      },
      options: {
        headers: {
          [X_UNIT_GROUP_ID]: idBuilding,
        },
      },
    },
  });
  return response;
}

export async function exportPaySlipService(idBuilding, filter) {
  const response = await api({
    type: API_TYPE_DOWNLOAD_FILE,
    payload: {
      apiUrl: PAY_SLIP_EXPORT,
      method: METHOD.GET,
      options: {
        headers: {
          [X_UNIT_GROUP_ID]: idBuilding,
        },
        params: {
          ...filter,
        },
      },
    },
  });
  return response;
}

export async function getSumTotalPaymentService(idBuilding, filter) {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: PAY_SLIP_SUM_TOTAL_PAYMENT,
      method: METHOD.GET,
      options: {
        headers: {
          [X_UNIT_GROUP_ID]: idBuilding,
        },
        params: {
          ...filter,
        },
      },
    },
  });
  return response;
}

export async function getHistoryPayslipService({ idBuilding, idPayslip }) {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: PAY_SLIP_HISTORY_API_GETS(idPayslip),
      method: METHOD.GET,
      options: {
        headers: {
          [X_UNIT_GROUP_ID]: idBuilding,
        },
      },
    },
  });
  return response;
}
