import { useState } from 'react';
import { debounceFunc } from 'shared/utils';

const useFilter = (filterDefault) => {
  const [filterObject, setFilterObject] = useState(filterDefault);
  const onFilter = (newFilterObject) => {
    setFilterObject(newFilterObject);
  };
  const onFilterDelay = debounceFunc(onFilter);
  const onClearFilter = () => {
    setFilterObject(filterDefault);
  };
  const onInputChangeFilter = (name) => (e) => {
    onFilterDelay({
      ...filterObject,
      [name]: e.target.value,
    });
  };
  const onSelectChangeFilter = (name) => (value) => {
    onFilter({
      ...filterObject,
      [name]: value,
    });
  };
  return {
    filterObject,
    onFilter,
    onFilterDelay,
    onClearFilter,
    onInputChangeFilter,
    onSelectChangeFilter,
  };
};

export default useFilter;
