const compareArray = (array1, array2) => {
  if (array1.length !== array2.length) return false;
  const uniqueValues = new Set([...array1, ...array2]);
  // eslint-disable-next-line no-restricted-syntax
  for (const v of uniqueValues) {
    const aCount = array1.filter((e) => e === v).length;
    const bCount = array2.filter((e) => e === v).length;
    if (aCount !== bCount) return false;
  }
  return true;
};

export default compareArray;
