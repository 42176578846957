import { language } from '../utils/language';

export const BUILDING_TYPE = {
  BLOCK: language.building_block,
  BUILDING: language.label.building,
  CO_WORKING_SPACE: language.co_working_space,
  AREA: language.co_working_space,
};

export const BUILDING_TYPE_NOT_BLOCK = ['AREA', 'BUILDING'];

export const BUILDING_TEXT = 'BUILDING';
