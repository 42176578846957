const convertSizeImage = (url, width, height) => {
  if (!url) {
    return null;
  }
  const tmpArr = url.split('/');
  const size = `${width}x${height}`;
  tmpArr.splice(tmpArr.length - 1, 0, size);
  return tmpArr.join('/');
};

export default convertSizeImage;
