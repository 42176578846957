import { put, call, all, takeLatest } from 'redux-saga/effects';
import { addSubdivisionService, getSubdivisionsService, updateSubdivisionService, deleteSubdivisionService } from 'services/subdivision';
import { CONFIG_SUBDIVISION_ADD, CONFIG_SUBDIVISION_DELETE, CONFIG_SUBDIVISION_GETS, CONFIG_SUBDIVISION_UPDATE } from './subdivision.types';

function* getSubdivisions(action) {
  const { idBuilding, filter, refreshState } = action.payload;
  try {
    const response = yield call(getSubdivisionsService, {
      idBuilding,
      filter,
    });
    yield put({
      type: CONFIG_SUBDIVISION_GETS.SUCCESS,
      payload: response,
    });
    refreshState();
  } catch (error) {
    yield put({
      type: CONFIG_SUBDIVISION_GETS.FAIL,
      payload: error,
    });
    refreshState();
  }
}

function* addSubdivision(action) {
  const { idBuilding, subdivision, refreshState } = action.payload;
  try {
    const response = yield call(addSubdivisionService, {
      idBuilding,
      subdivision,
    });
    yield put({
      type: CONFIG_SUBDIVISION_ADD.SUCCESS,
      payload: response,
    });
    refreshState({
      code: response.code,
      codeLanguage: response.codeLanguage,
    });
  } catch (error) {
    yield put({
      type: CONFIG_SUBDIVISION_GETS.FAIL,
      payload: error,
    });
    refreshState({
      code: error.code,
      codeLanguage: error.codeLanguage,
    });
  }
}

function* updateSubdivision(action) {
  const { idBuilding, idSubdivision, subdivision, refreshState } = action.payload;
  try {
    const response = yield call(updateSubdivisionService, {
      idBuilding,
      idSubdivision,
      subdivision,
    });
    yield put({
      type: CONFIG_SUBDIVISION_UPDATE.SUCCESS,
      payload: response,
    });
    refreshState({
      code: response.code,
      codeLanguage: response.codeLanguage,
    });
  } catch (error) {
    yield put({
      type: CONFIG_SUBDIVISION_UPDATE.FAIL,
      payload: error,
    });
    refreshState({
      code: error.code,
      codeLanguage: error.codeLanguage,
    });
  }
}

function* deleteSubdivision(action) {
  const { idBuilding, idSubdivision, refreshState } = action.payload;
  try {
    const response = yield call(deleteSubdivisionService, {
      idBuilding,
      idSubdivision,
    });
    yield put({
      type: CONFIG_SUBDIVISION_DELETE.SUCCESS,
      payload: response,
    });
    refreshState({
      code: response.code,
      codeLanguage: response.codeLanguage,
    });
  } catch (error) {
    yield put({
      type: CONFIG_SUBDIVISION_DELETE.FAIL,
      payload: error,
    });
    refreshState({
      code: error.code,
      codeLanguage: error.codeLanguage,
    });
  }
}

export default function* watch() {
  yield all([
    takeLatest(CONFIG_SUBDIVISION_GETS.REQUEST, getSubdivisions),
    takeLatest(CONFIG_SUBDIVISION_ADD.REQUEST, addSubdivision),
    takeLatest(CONFIG_SUBDIVISION_UPDATE.REQUEST, updateSubdivision),
    takeLatest(CONFIG_SUBDIVISION_DELETE.REQUEST, deleteSubdivision),
  ]);
}
