import {
  CONFIG_APARTMENT_GETS,
  CONFIG_APARTMENT_PAGE_REFRESH,
  CONFIG_APARTMENT_SUBDIVISION_GETS,
  CONFIG_APARTMENT_BLOCK_GETS,
  CONFIG_APARTMENT_FLOOR_GETS,
  CONFIG_APARTMENT_APARTMENT_TYPE_GETS,
  CONFIG_APARTMENT_EXPORT,
  CONFIG_APARTMENT_IMPORT,
} from './apartment.types';

export const initState = {
  data: [],
  totalData: 0,
  subdivisions: [],
  blocks: [],
  floors: [],
  apartments: [],
  apartmentTypes: [],
  apartmentExportData: undefined,
  apartmentImportData: undefined,
};

const apartment = (state = initState, action) => {
  switch (action.type) {
    case CONFIG_APARTMENT_PAGE_REFRESH: {
      return {
        ...initState,
      };
    }
    case CONFIG_APARTMENT_GETS.SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        totalData: action.payload.totalRecord,
      };
    }
    case CONFIG_APARTMENT_SUBDIVISION_GETS.SUCCESS: {
      return {
        ...state,
        subdivisions: action.payload.data,
      };
    }
    case CONFIG_APARTMENT_BLOCK_GETS.SUCCESS: {
      return {
        ...state,
        blocks: action.payload.data,
      };
    }
    case CONFIG_APARTMENT_FLOOR_GETS.SUCCESS: {
      return {
        ...state,
        floors: action.payload.data,
      };
    }
    case CONFIG_APARTMENT_APARTMENT_TYPE_GETS.SUCCESS: {
      return {
        ...state,
        apartmentTypes: action.payload.data,
      };
    }
    case CONFIG_APARTMENT_EXPORT.SUCCESS: {
      return {
        ...state,
        apartmentExportData: action.payload.data,
      };
    }
    case CONFIG_APARTMENT_IMPORT.SUCCESS: {
      return {
        ...state,
        apartmentImportData: action.payload.data,
      };
    }
    default: {
      return state;
    }
  }
};

export default apartment;
