import { API_TYPE_CALL_API, METHOD, SERVER_NOTIFICATION, X_LANGUAGE } from 'shared/constants';
import { getCacheLanguage } from 'shared/utils';
import api from './api';

const FCM_SEND_DEVICE_TOKEN = `${SERVER_NOTIFICATION}/installations`;

export const sendDeviceTokenService = async (token, deviceType) => {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: FCM_SEND_DEVICE_TOKEN,
      method: METHOD.POST,
      body: {
        deviceToken: token,
        deviceType,
      },
      options: {
        headers: {
          [X_LANGUAGE]: getCacheLanguage(),
        },
      },
    },
  });
  return response;
};
