export default function downloadFileFromUrl(url, document, fileName) {
  if (!url) {
    throw Error('url is not null');
  }
  if (!document) {
    throw Error('document is not null');
  }
  if (!fileName) {
    throw Error('filename is not null');
  }
  const link = document.createElement('a');
  link.setAttribute('target', '_blank');
  link.setAttribute('href', url);
  link.setAttribute('download', fileName);
  link.style.visibility = 'hidden';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
