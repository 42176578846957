import { put, call, all, takeLatest } from 'redux-saga/effects';
import { getFloorsService } from 'services/floor';
import { getSubdivisionsService } from 'services/subdivision';
import { getBlocksService } from 'services/block';
import {
  getApartmentsService,
  addApartmentService,
  updateApartmentService,
  deleteApartmentService,
  getApartmentTypesService,
  deleteMultiApartmentService,
  exportApartmentService,
  importApartmentService,
} from 'services/apartment';
import {
  CONFIG_APARTMENT_ADD,
  CONFIG_APARTMENT_DELETE,
  CONFIG_APARTMENT_GETS,
  CONFIG_APARTMENT_UPDATE,
  CONFIG_APARTMENT_SUBDIVISION_GETS,
  CONFIG_APARTMENT_BLOCK_GETS,
  CONFIG_APARTMENT_FLOOR_GETS,
  CONFIG_APARTMENT_APARTMENT_TYPE_GETS,
  CONFIG_APARTMENT_DELETE_MULTI,
  CONFIG_APARTMENT_EXPORT,
  CONFIG_APARTMENT_IMPORT,
} from './apartment.types';

function* getApartmentTypes() {
  try {
    const response = yield call(getApartmentTypesService);
    yield put({
      type: CONFIG_APARTMENT_APARTMENT_TYPE_GETS.SUCCESS,
      payload: response,
    });
  } catch (error) {
    yield put({
      type: CONFIG_APARTMENT_APARTMENT_TYPE_GETS.FAIL,
      payload: error,
    });
  }
}

function* getSubdivisions(action) {
  const { idBuilding } = action.payload;
  try {
    const response = yield call(getSubdivisionsService, {
      idBuilding,
    });
    yield put({
      type: CONFIG_APARTMENT_SUBDIVISION_GETS.SUCCESS,
      payload: response,
    });
  } catch (error) {
    yield put({
      type: CONFIG_APARTMENT_SUBDIVISION_GETS.FAIL,
      payload: error,
    });
  }
}

function* getBlocks(action) {
  const { idBuilding } = action.payload;
  try {
    const response = yield call(getBlocksService, {
      idBuilding,
    });
    yield put({
      type: CONFIG_APARTMENT_BLOCK_GETS.SUCCESS,
      payload: response,
    });
  } catch (error) {
    yield put({
      type: CONFIG_APARTMENT_BLOCK_GETS.FAIL,
      payload: error,
    });
  }
}

function* getFloors(action) {
  const { idBuilding, filter } = action.payload;
  try {
    const response = yield call(getFloorsService, {
      idBuilding,
      filter,
    });
    yield put({
      type: CONFIG_APARTMENT_FLOOR_GETS.SUCCESS,
      payload: response,
    });
  } catch (error) {
    yield put({
      type: CONFIG_APARTMENT_FLOOR_GETS.FAIL,
      payload: error,
    });
  }
}

function* getApartments(action) {
  const { idBuilding, filter, page, limit, refreshState } = action.payload;
  try {
    const response = yield call(getApartmentsService, {
      idBuilding,
      filter,
      page,
      limit,
    });
    yield put({
      type: CONFIG_APARTMENT_GETS.SUCCESS,
      payload: response,
    });
    refreshState();
  } catch (error) {
    yield put({
      type: CONFIG_APARTMENT_GETS.FAIL,
      payload: error,
    });
    refreshState();
  }
}

function* addApartment(action) {
  const { idBuilding, apartment, refreshState } = action.payload;
  try {
    const response = yield call(addApartmentService, {
      idBuilding,
      apartment,
    });
    yield put({
      type: CONFIG_APARTMENT_ADD.SUCCESS,
      payload: response,
    });
    refreshState({
      code: response.code,
      codeLanguage: response.codeLanguage,
    });
  } catch (error) {
    yield put({
      type: CONFIG_APARTMENT_ADD.FAIL,
      payload: error,
    });
    refreshState({
      code: error.code,
      codeLanguage: error.codeLanguage,
    });
  }
}

function* updateApartment(action) {
  const { idBuilding, idApartment, apartment, refreshState } = action.payload;
  try {
    const response = yield call(updateApartmentService, {
      idBuilding,
      idApartment,
      apartment,
    });
    yield put({
      type: CONFIG_APARTMENT_UPDATE.SUCCESS,
      payload: response,
    });
    refreshState({
      code: response.code,
      codeLanguage: response.codeLanguage,
    });
  } catch (error) {
    yield put({
      type: CONFIG_APARTMENT_UPDATE.FAIL,
      payload: error,
    });
    refreshState({
      code: error.code,
      codeLanguage: error.codeLanguage,
    });
  }
}

function* deleteApartment(action) {
  const { idBuilding, idApartment, refreshState } = action.payload;
  try {
    const response = yield call(deleteApartmentService, {
      idBuilding,
      idApartment,
    });
    yield put({
      type: CONFIG_APARTMENT_DELETE.SUCCESS,
      payload: response,
    });
    refreshState({
      code: response.code,
      codeLanguage: response.codeLanguage,
    });
  } catch (error) {
    yield put({
      type: CONFIG_APARTMENT_DELETE.FAIL,
      payload: error,
    });
    refreshState({
      code: error.code,
      codeLanguage: error.codeLanguage,
    });
  }
}

function* deleteMultiApartment(action) {
  const { idBuilding, idApartments, isAll, filterObject, refreshState } = action.payload;
  try {
    const response = yield call(deleteMultiApartmentService, {
      idBuilding,
      idApartments,
      isAll,
      filterObject,
    });
    yield put({
      type: CONFIG_APARTMENT_DELETE_MULTI.SUCCESS,
      payload: response,
    });
    refreshState({
      code: response.code,
      codeLanguage: response.codeLanguage,
    });
  } catch (error) {
    yield put({
      type: CONFIG_APARTMENT_DELETE_MULTI.FAIL,
      payload: error,
    });
    refreshState({
      code: error.code,
      codeLanguage: error.codeLanguage,
    });
  }
}

function* importApartment(action) {
  const { idBuilding, file, refreshState } = action.payload;
  try {
    const response = yield call(importApartmentService, { idBuilding, file });
    refreshState({
      code: response.code,
      codeLanguage: response.codeLanguage,
    });
    yield put({
      type: CONFIG_APARTMENT_IMPORT.SUCCESS,
      payload: response,
    });
  } catch (error) {
    refreshState({
      code: error.code,
      codeLanguage: error.codeLanguage,
    });
    yield put({
      type: CONFIG_APARTMENT_IMPORT.FAIL,
      payload: error,
    });
  }
}

function* exportApartment(action) {
  const { idBuilding, filter, refreshState } = action.payload;
  try {
    const response = yield call(exportApartmentService, { idBuilding, filter });
    refreshState({
      code: response.code,
      codeLanguage: response.codeLanguage,
    });
    yield put({
      type: CONFIG_APARTMENT_EXPORT.SUCCESS,
      payload: response,
    });
  } catch (error) {
    refreshState({
      code: error.code,
      codeLanguage: error.codeLanguage,
    });
    yield put({
      type: CONFIG_APARTMENT_EXPORT.FAIL,
      payload: error,
    });
  }
}

export default function* watch() {
  yield all([
    takeLatest(CONFIG_APARTMENT_APARTMENT_TYPE_GETS.REQUEST, getApartmentTypes),
    takeLatest(CONFIG_APARTMENT_SUBDIVISION_GETS.REQUEST, getSubdivisions),
    takeLatest(CONFIG_APARTMENT_BLOCK_GETS.REQUEST, getBlocks),
    takeLatest(CONFIG_APARTMENT_FLOOR_GETS.REQUEST, getFloors),
    takeLatest(CONFIG_APARTMENT_GETS.REQUEST, getApartments),
    takeLatest(CONFIG_APARTMENT_ADD.REQUEST, addApartment),
    takeLatest(CONFIG_APARTMENT_UPDATE.REQUEST, updateApartment),
    takeLatest(CONFIG_APARTMENT_DELETE.REQUEST, deleteApartment),
    takeLatest(CONFIG_APARTMENT_DELETE_MULTI.REQUEST, deleteMultiApartment),
    takeLatest(CONFIG_APARTMENT_EXPORT.REQUEST, exportApartment),
    takeLatest(CONFIG_APARTMENT_IMPORT.REQUEST, importApartment),
  ]);
}
