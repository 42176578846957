/* eslint-disable import/no-cycle */
import { useContext } from 'react';
import { Context as GlobalContext } from 'globalContext';
import { getBuildingsService } from 'services/building';

const useBuilding = () => {
  const { buildings, setBuildings } = useContext(GlobalContext);

  const getBuildings = async () => {
    const buildingData = await getBuildingsService();
    if (Array.isArray(buildingData.data)) {
      setBuildings([...buildingData.data]);
    }
    return buildingData;
  };

  const readNotificationOfBuilding = (idBuilding, countBadges = 0) => {
    const listBuildings = [...buildings];
    const indexOfBuilding = listBuildings.findIndex((building) => building.id === idBuilding);
    listBuildings[indexOfBuilding].badge = countBadges;
    setBuildings(listBuildings);
  };

  return { buildings, getBuildings, setBuildings, readNotificationOfBuilding };
};

export default useBuilding;
