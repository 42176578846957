import oidc from 'shared/config/oidc';
import { COOKIE_TAG } from 'shared/constants';
import { Cookie } from 'shared/utils';

const { AUTH_METHOD } = process.env;

const getAccessToken = async () => {
  if (AUTH_METHOD === 'SSO') {
    const user = await oidc.getUser();
    return user?.access_token ?? undefined;
  }
  const cookie = Cookie.get(COOKIE_TAG);
  return cookie?.user?.accessToken ?? undefined;
};

export default getAccessToken;
