import { API_TYPE_CALL_API, SERVER, X_UNIT_GROUP_ID, METHOD } from 'shared/constants';
import api from './api';

const FUND = `${SERVER}/fund`;
const FUND_GETS = `${FUND}/list`;
const FUND_ADD = FUND;
const FUND_UPDATE = FUND;

export const getFundService = async ({ idBuilding }) => {
  const headers = { [X_UNIT_GROUP_ID]: idBuilding };

  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: FUND_GETS,
      method: METHOD.GET,
      options: { headers },
    },
  });
  return response;
};

export const addFundService = async ({ fund, idBuilding }) => {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: FUND_ADD,
      body: fund,
      method: METHOD.POST,
      options: { headers: { [X_UNIT_GROUP_ID]: idBuilding } },
    },
  });
  return response;
};

export const updateFundService = async ({ fund, idFund, idBuilding }) => {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: `${FUND_UPDATE}/${idFund}`,
      body: fund,
      method: METHOD.PUT,
      options: { headers: { [X_UNIT_GROUP_ID]: idBuilding } },
    },
  });
  return response;
};

export const deleteFundService = async ({ idFund, idBuilding }) => {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: `${FUND}/${idFund}`,
      method: METHOD.DELETE,
      options: { headers: { [X_UNIT_GROUP_ID]: idBuilding } },
    },
  });
  return response;
};
