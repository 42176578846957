import { put, call, all, takeLatest } from 'redux-saga/effects';
import { addBlockService, getBlocksService, updateBlockService, deleteBlockService } from 'services/block';
import { getSubdivisionsService } from 'services/subdivision';
import {
  CONFIG_BLOCK_ADD,
  CONFIG_BLOCK_DELETE,
  CONFIG_BLOCK_GETS,
  CONFIG_BLOCK_UPDATE,
  CONFIG_BLOCK_SUBDIVISION_GETS,
} from './block.types';

function* getSubdivisions(action) {
  const { idBuilding } = action.payload;
  try {
    const response = yield call(getSubdivisionsService, {
      idBuilding,
    });
    yield put({
      type: CONFIG_BLOCK_SUBDIVISION_GETS.SUCCESS,
      payload: response,
    });
  } catch (error) {
    yield put({
      type: CONFIG_BLOCK_SUBDIVISION_GETS.FAIL,
      payload: error,
    });
  }
}

function* getBlocks(action) {
  const { idBuilding, filter, refreshState } = action.payload;
  try {
    const response = yield call(getBlocksService, {
      idBuilding,
      filter,
    });
    yield put({
      type: CONFIG_BLOCK_GETS.SUCCESS,
      payload: response,
    });
    refreshState();
  } catch (error) {
    yield put({
      type: CONFIG_BLOCK_GETS.FAIL,
      payload: error,
    });
    refreshState();
  }
}

function* addBlock(action) {
  const { idBuilding, block, refreshState } = action.payload;
  try {
    const response = yield call(addBlockService, {
      idBuilding,
      block,
    });
    yield put({
      type: CONFIG_BLOCK_ADD.SUCCESS,
      payload: response,
    });
    refreshState({
      code: response.code,
      codeLanguage: response.codeLanguage,
    });
  } catch (error) {
    yield put({
      type: CONFIG_BLOCK_ADD.FAIL,
      payload: error,
    });
    refreshState({
      code: error.code,
      codeLanguage: error.codeLanguage,
    });
  }
}

function* updateBlock(action) {
  const { idBuilding, idBlock, block, refreshState } = action.payload;
  try {
    const response = yield call(updateBlockService, {
      idBuilding,
      idBlock,
      block,
    });
    yield put({
      type: CONFIG_BLOCK_UPDATE.SUCCESS,
      payload: response,
    });
    refreshState({
      code: response.code,
      codeLanguage: response.codeLanguage,
    });
  } catch (error) {
    yield put({
      type: CONFIG_BLOCK_UPDATE.FAIL,
      payload: error,
    });
    refreshState({
      code: error.code,
      codeLanguage: error.codeLanguage,
    });
  }
}

function* deleteBlock(action) {
  const { idBuilding, idBlock, refreshState } = action.payload;
  try {
    const response = yield call(deleteBlockService, {
      idBuilding,
      idBlock,
    });
    yield put({
      type: CONFIG_BLOCK_DELETE.SUCCESS,
      payload: response,
    });
    refreshState({
      code: response.code,
      codeLanguage: response.codeLanguage,
    });
  } catch (error) {
    yield put({
      type: CONFIG_BLOCK_DELETE.FAIL,
      payload: error,
    });
    refreshState({
      code: error.code,
      codeLanguage: error.codeLanguage,
    });
  }
}

export default function* watch() {
  yield all([
    takeLatest(CONFIG_BLOCK_GETS.REQUEST, getBlocks),
    takeLatest(CONFIG_BLOCK_SUBDIVISION_GETS.REQUEST, getSubdivisions),
    takeLatest(CONFIG_BLOCK_ADD.REQUEST, addBlock),
    takeLatest(CONFIG_BLOCK_UPDATE.REQUEST, updateBlock),
    takeLatest(CONFIG_BLOCK_DELETE.REQUEST, deleteBlock),
  ]);
}
