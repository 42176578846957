import moment from 'moment';

const generateDateTime = () => {
  function generateDate() {
    const date = [];
    for (let i = 1; i <= 31; i += 1) {
      date.push({
        label: i,
        value: i,
      });
    }
    return date;
  }

  function generateMonth() {
    const month = [];
    for (let i = 0; i < 12; i += 1) {
      month.push({
        label: i + 1,
        value: i,
      });
    }
    return month;
  }

  function generateYear() {
    const year = [];
    for (let i = moment().year() + 5; i >= moment().year() - 50; i -= 1) {
      year.push({
        label: i,
        value: i,
      });
    }
    return year;
  }

  function generateQuarter() {
    const quarter = [];
    for (let i = 1; i <= 4; i += 1) {
      quarter.push({
        label: i,
        value: i,
      });
    }
    return quarter;
  }

  return {
    DATE: generateDate(),
    MONTH: generateMonth(),
    YEAR: generateYear(),
    QUARTER: generateQuarter(),
  };
};

export default generateDateTime;
